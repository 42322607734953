/* globals bootbox, popupFormBuilder */

var sessionAddedPopupForm = (function (bootbox, popupFormBuilder) {

    var popupForm = popupFormBuilder();

    return {
        init: function (params) {

            var postSuccessCallback = params.postSuccessCallback || function () {return; },
                postOpenCallback = params.postOpenCallback || function() {return;},
                modal_selector = params.modal_selector || "#new-message-modal";

            return popupForm.init({
                openModalBtnSelector: params.openModalBtnSelector,
                postOpenCallback: postOpenCallback,
                modalSelector: modal_selector,
                templateSelector: "#add-session-template",
                url: params.url,
                validators: [
                    popupForm.buildNotEmptyValidator('quantity', '.quantity-is-empty'),
                    popupForm.buildNotZeroValidator('quantity', '.quantity-is-zero'),
                    popupForm.buildNotZeroValidator('status', '.status-is-empty'),
                ],
                postSuccessCallback: function (context) {
                    if($('#id_sessions_remaining').length){
                        oldValue = parseInt($('#id_sessions_remaining').val());
                        added = parseInt(context.formData.quantity);
                        $('#id_sessions_remaining').val(oldValue+added);
                    }
                    if($('#profile_remaining_sessions').length){
                        oldValue = parseInt($('#profile_remaining_sessions').text());
                        added = parseInt(context.formData.quantity);
                        $('#profile_remaining_sessions').text(oldValue+added);
                    }
                    bootbox.alert(
                        "Sessions added!",
                        function () {
                            postSuccessCallback(context);
                        }
                    );
                },
            });

        },
    };

}(bootbox, popupFormBuilder));

