var Payment = function(){
	var PAYMENT = {};

	PAYMENT.submitForm = function(form) {
		// This is the call that sends the data to Stripe and
		// validates the credit card
		Stripe.createToken(form, this.handleStripeResponse);
	};
   	PAYMENT.handleStripeResponse = function(status, response) {
		if (response.error){

			// Re-enable the submit button
			PAYMENT.$button.prop('disabled', false);

			$('.payment-errors').addClass('alert-danger').css('display','block').text(response.error.message);
		} else {
			var token = response.id;
			console.log(token)
			// Set the value of the hidden field with the token from the
			// response so we pass it to the server on submit
			PAYMENT.$form.find('#id_stripe_token').val(token);

			// replace all but the last 4 digits of cc# with x's
			//var cc=PAYMENT.$form.find('#id_card_number');
			//cc.val(cc.val().replace(/(( )*\d)(?=(( )*\d){4})/g, "$2x"));

			//Submit the form
			PAYMENT.$form.submit();
		}
	};

	PAYMENT.set_token = function(form) {
		Stripe.createToken(form, function(status, response){
			if (response.error){
				console.log('error', response.error.message);

				// Re-enable the submit button
				PAYMENT.$button.prop('disabled', false);

				$('.payment-errors').addClass('alert-danger').css('display','block').text(response.error.message);
			} else {
				var token = response.id;

				// Set the value of the hidden field with the token from the
				// response so we pass it to the server on submit
				PAYMENT.$form.find('#id_stripe_token').val(token);

				// replace all but the last 4 digits of cc# with x's
				//var cc=PAYMENT.$form.find('#id_card_number');
				//cc.val(cc.val().replace(/(( )*\d)(?=(( )*\d){4})/g, "$2x"));
			}
		});
	}

	PAYMENT.initialize = function(options) {
		// Get the form ID from the options
		PAYMENT.$form = $(options.formId);
		PAYMENT.$button = PAYMENT.$form.find('input[type=submit]');

		// Define the button action
		PAYMENT.$button.on('click', function(event) {
			event.preventDefault();

			// Disable the submit button to avoid multiple submissions
			$(this).prop('disabled', true);
			PAYMENT.submitForm(PAYMENT.$form);
		});

		//Initialize Jquery Payment on the credit card field (for formatting)
		//PAYMENT.$form.find('#id_card_number').payment('formatCardNumber');

	};
	return PAYMENT;
};