/*globals
    jQuery,
    window,
    google,
*/

var nextSessionMap = (function ($, window, google) {
    return {
        init: function () {
            var lat = $('.next-session').data('sessionlat'),
                lng = $('.next-session').data('sessionlong'),
                nextSession = $('.next-session').data('session');

            google.maps.event.addDomListener(window, 'load', function () {
                var myLatlng = new google.maps.LatLng(lat, lng),
                    mapOptions = {
                        zoom: 14,
                        center: myLatlng,
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    },
                    map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);
                var marker = new google.maps.Marker({
                    position: myLatlng,
                    map: map,
                    title: nextSession
                });
            });
        }
    };
}(jQuery, window, google));