var RedeemCoupon = (function() {
  return {
    init: function(args) {
      var args = args || {}
      var $redeemCouponForm = $(args.formSelector || "form#redeem-coupon")
      var url = args.url || $redeemCouponForm.attr('action')

      $redeemCouponForm.submit(function(e) {
        $.ajax({
          url: url,
          data: $redeemCouponForm.serialize(),
          method: 'POST',
          complete: function(jqXHR) {
            var response = jqXHR.responseJSON
            $redeemCouponForm.find('.alert')
              .addClass(response.success ? 'alert-info' : 'alert-warning')
              .removeClass('hide')
              .text(response.text)
            if (response.success && args.success) args.success(response.data.credits)
          }
        })
        e.preventDefault()
      })
    }
  }
})()