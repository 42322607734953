var ComingSoon = (function (){

    var city_source,
        neighborhoods_modal_was_clicked = false;

    function send_new_member_email(event){
        var member_email;

        neighborhood_name = null;
        if ( typeof(event.data) !== "undefined" ){
            neighborhood_name = event.data.neighborhood_name;
            member_email = $("#coming-soon-email-neighborhood");
        }else{
            member_email = $("#coming-soon-email");
        }

        data_json = {
            email: member_email.val(),
            source: city_source,
        }

        if (neighborhood_name !== null){
            data_json.neighborhood_requested = neighborhood_name;
            data_json.train_at_gym = $("#train_at_gym").prop("checked");
            data_json.train_at_home = $("#train_at_home").prop("checked");
        }

        $.post(
            window.URLS.newmember_email_only,
            data_json
        )
        .done(function(response){
            member_email.val("");
            member_email.attr("placeholder", "Email Address");
            $("#sign-up-hero").hide();
            logged_in_or_email = "";

            $("#thanks-modal-coming-soon").modal("show");
            if (neighborhood_name != null)
                $("#neighborhood-modal").modal("hide");
        })
        .fail(function(response){
            var errors = JSON.parse(response.responseText);
                email_error = errors.error.email.shift();

            member_email.val("");
            member_email.attr("placeholder", email_error);
        })
    }

    function show_neighborhoods_modal(evt){
        evt.preventDefault();
        neighborhoods_modal_was_clicked = true;

        var neighborhood_modal = $("#neighborhood-modal"),
            neighborhood = $(this).data("name"),
            submit_button = $("#signup_with_neighborhood");

        neighborhood_modal.modal("show");
        $("#neighborhood-name").text(neighborhood);

        neighborhood_modal.keypress(function( event ) {
            var email = $("#coming-soon-email").val();
            if ( event.which === 13 ) {
                submit_button.trigger("click");
            }
        });

        submit_button.click({
            neighborhood_name: neighborhood,
            neighborhood_modal: neighborhood_modal
            },
            send_new_member_email);

    }

    return {
        init: function (params){

            city_source = params.city_source;

            var background_image = params.background_image,
                singup_btn = $("#coming_soon_email_btn"),
                neighborhoods = $(".neighborhood");

             $("body").keypress(function( event ) {
                 var hero_image_email = $("#coming-soon-email");
                 if ( event.which === 13 && neighborhoods_modal_was_clicked === false) {
                    singup_btn.trigger("click");
                 }
            });

            $('.hero-coming-soon').css('background', 'url(' + background_image + ') no-repeat');

            singup_btn.on("click", send_new_member_email);
            neighborhoods.on("click", show_neighborhoods_modal);

        }
    }

}());