var quizWidget = function() {

    var user_logged_in_or_email,
        user_is_authenticated,
        progress_bar,
        progress_bar_container,
        total_steps,
        autocomplete_obj = {},
        px_to_add,
        $matches_loader = $("#matches-progress-loader");

    /*function paint_bar(steps) {
        progress_bar.width(px_to_add * steps);
        return;
    }*/

    /*function showTooltip() {
        var tooltip = $('input[name=your-location]');
        tooltip.tooltipster({
            theme: 'tooltipster-shadow',
            contentCloning: true,
            trigger: 'click',
            side: 'right',
        });
        tooltip.tooltipster('open');
    }*/

    //Radio Button js
    function toogle_trigger_questions(array_question_id, state) {

        var questions_len = array_question_id.length;

        for (var i = 0; i < questions_len; i++) {
            var question = $("#question-" + array_question_id[i]);

            if (state === "hide") {
                question.effect("highlight", { color: "#dfdfdf" }, 100, function() {
                    $(this).addClass("hidden");
                });
            } else {
                question.effect("highlight", { color: "#dfdfdf" }, 100, function() {
                    $(this).removeClass("hidden");
                });
            }
        }
    }

    /*function send_quiz_response() {
        var $form = $("#form-quiz"),
            submited = $form.attr("data-submited");

        if (submited !== "true") {
            $form.attr("data-submited", "true");
            $form.submit();
            $matches_loader.show();
        }
    };*/

    /*function deleteTooltip() {
        var tooltip = $('input[name=your-location]');
        try {
            tooltip.tooltipster('destroy');
        } catch (err) {
            console.log('destroying unitialized tooltip');
        }
    }*/

    return {

        init: function(params) {

            user_logged_in_or_email = params.user_logged_in_or_email;
            user_is_authenticated = params.user_is_authenticated,
                progress_bar = $("div.progress-bar"),
                progress_bar_container = progress_bar.parent(),
                user_email = params.user_email,
                user_first_name = params.user_first_name,
                user_last_name = params.user_last_name,
                email_cookies = params.email_cookies,
                total_steps = params.total_steps,
                steps = params.steps,
                px_to_add = (14.3 * progress_bar_container.width()) / 100,
                show_errors = params.show_errors;

            $matches_loader.hide();

            var markers = $("div.progress-markers ul li"),
                total_steps = parseInt(total_steps),
                main_questions = $("div.question").filter(function() {
                    var question_id = $(this).attr("id");
                    return $("li[data-question=" + question_id + "]").length;
                });

            QUIZ_SENT = false;

            /*questions_with_autocomplete = $("input[data-autocomplete='true']");
            _.each(questions_with_autocomplete, function(question, index) {
                var id_str = $(question).attr("id");
                initialize_auto_complete(id_str, index);
            })*/

            /*var input = $("input[data-autocomplete='true']")[0] // actual DOM elem
            var autocomplete = new google.maps.places.Autocomplete(input, options);

            google.maps.event.addListener(autocomplete, 'place_changed', function() {
                console.log('autocomplete');
                var place = autocomplete.getPlace() || {};
                if (!("geometry" in place)) {
                    showTooltip();
                }
            })*/

            //initialize_auto_complete("text-question-24");
            //initialize_auto_complete("text-question-25");

            if (user_logged_in_or_email) {
                $("input[name='email-address']").val(email_cookies);
            }

            if (user_is_authenticated) {
                $("input[name='email-address']").val(user_email);
                $("input[name='first-name']").val(user_first_name);
                $("input[name='last-name']").val(user_last_name);
            }

            $(".datepicker").on('focus', function() {
                $(this).blur();
            });

            $(".datepicker").datepicker({
                format: 'mm/dd/yyyy',
                autoclose: true,
                orientation: 'top auto',
                todayBtn: "linked",
                todayHighlight: true
            });

            // Hacking classnames
            $('#question-3').parent().removeClass('container-question');
            $('#question-10').parent().addClass('one-child');
            $('#question-15').parent().addClass('one-child').removeClass('two-child');

            /*if (total_steps > 0) {
                paint_bar(total_steps);
                markers.addClass("marker");
            }*/

            /*main_questions.hover(function() {

                if ($(this).hasClass("was_hovered")) {
                    return;
                }

                total_steps += 1;
                $(this).addClass("was_hovered");
                markers.not(".marker").first().addClass("marker");

                paint_bar(total_steps);

            });*/

            /*var visitedSteps = [];*/

            /*function updateProgressBar(title) {
                var title = $(this).find('.question-title').html();
                console.log('updateProgressBar', title);
                if ((visitedSteps.indexOf(title) < 0) && (steps.indexOf(title) >= 0)) {
                    visitedSteps.push(title);
                    var currentStageIndex = steps.indexOf(title);
                    var statusBar = document.getElementsByClassName("status-bar")[0];
                    var currentStatus = document.getElementsByClassName("current-status")[0];

                    setTimeout(function() {
                        currentStatus.style.width = (100 * currentStageIndex) / (steps.length - 1) + '%';
                        currentStatus.style.transition = 'width ' + (currentStageIndex * ProgressBar.singleStepAnimation) + 'ms linear';
                    }, 200);

                    statusBar.appendChild(currentStatus);

                    var animationDelay = 100;
                    for (var index = 0; index < $('li.section').length; index++) {
                        var li = $('li.section')[index];
                        if (currentStageIndex >= index) {
                            setTimeout(function(li, currentStageIndex, index) {
                                li.className += (currentStageIndex > index) ? ' visited' : ' visited current';
                            }, animationDelay, li, currentStageIndex, index);
                            animationDelay += ProgressBar.singleStepAnimation;
                        }
                    }
                }
            }*/

            /*var statusBar = document.getElementsByClassName("status-bar")[0];
            statusBar.style.width = '100%';*/

            /*$("#quiz div.question").hover(updateProgressBar)*/

            /*$("#quiz div.question").on('touchstart', updateProgressBar)*/

            $("a.next-question").click(function() {
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                    if (target.length) {
                        $('html,body').animate({
                            scrollTop: target.offset().top
                        }, 2500);
                    }

                }

            });

            // Anything else we need to know - Textarea remaining counter
            $("textarea[name=anything-else-we-need-to-know]")
                .attr('maxlength', 3000)
                .parent()
                .find('.textarea-remaining')
                .html('3000 <span style="color:grey">characters left.</span> ')

            $("textarea[name=anything-else-we-need-to-know]").keyup(function(e) {
                var maxlength = 3000;
                var actual = $(this).val().length;
                var remaining = maxlength - actual;

                if (remaining > maxlength) {
                    return false;
                }

                $(this)
                    .parent()
                    .find('.textarea-remaining')
                    .html(remaining + ' <span style="color:grey">characters left.</span> ')
            })

            var radios_checked = $("input[type=radio]:checked");

            radios_checked.each(function(index, element) {
                var radio_val = $(element).val();
                var questions_to_trigger = JSON.parse($(element).attr("data-questions-to-trigger"));
                toogle_trigger_questions(questions_to_trigger, "show")
                $(element).parent().addClass("option-checked");
                $("div[data-option-selected-container=" + radio_val + "]")
                    .addClass("option-was-selected")
                    .removeClass("hidden");
            });

            $(".option-can-check").hover(
                function() {
                    $(this).addClass("quiz-hover");
                },
                function() {
                    $(this).removeClass("quiz-hover");
                }
            );

            var checkBoxCounter = 0;
            var flag = false;

            $(".option-container-radio").click(function() {

                var hidden_radio = $(this).find("input[type=radio]");
                var data_question = $(this).attr("data-question");
                var radio_id = hidden_radio.attr("id");

                $("div[data-question=" + data_question + "]").each(function(index, element) {
                    $(element).find(".option-can-check").removeClass("option-checked");
                    $(element).find(".option-was-selected")
                        .addClass("hidden")
                        .removeClass("option-was-selected");
                });

                $(this).find(".option-can-check").addClass("option-checked");

                hidden_radio.prop("checked", true);
                $(this).find("div[data-option-selected-container=" + radio_id + "]")
                    .addClass("option-was-selected")
                    .removeClass("hidden");

                var qs_to_hidde = $("div[data-question=" + data_question + "]").not(this);

                qs_to_hidde.each(function(index, element) {
                    var questions_id = JSON.parse($(element).attr("data-questions-to-trigger"));
                    toogle_trigger_questions(questions_id, "hide");
                });

                var questions_to_trigger = JSON.parse($(this).attr("data-questions-to-trigger"));
                toogle_trigger_questions(questions_to_trigger, "show");
                $('input:checked').parent().addClass('option-selected');
                $('input:not(:checked)').parent().removeClass('option-selected');

                // handleQuizButton(checkBoxCounter, flag);
            });

            //End Radio Button js

            //Checkbox Button js

            var checkboxs_checked = $("input[type=checkbox]:checked");

            checkboxs_checked.each(function(index, element) {
                var checkbox_val = $(element).val();
                $(element).parent().addClass("option-checked");
                $("div[data-option-selected-container=" + checkbox_val + "]")
                    .addClass("option-was-selected")
                    .removeClass("hidden");

                if ($(element).attr("data-questions-to-trigger")) {
                    var questions_to_trigger = JSON.parse($(element).attr("data-questions-to-trigger"));
                    toogle_trigger_questions(questions_to_trigger, "show");
                }
            });

            $(".option-container-checkbox ").click(function() {
                var hidden_checkbox = $(this).find("input[type=checkbox]");
                var data_question = $(this).attr("data-question");
                var checkbox_id = hidden_checkbox.attr("id");

                if (hidden_checkbox.prop("checked")) {
                    hidden_checkbox.prop("checked", false);
                    $(this).find("div.option-can-check").removeClass("option-checked").removeClass("option-selected");
                    checkBoxCounter--;
                    $(this).find(".option-was-selected")
                        .addClass("hidden")
                        .removeClass("option-was-selected");

                    var questions_to_trigger = JSON.parse($(this).attr("data-questions-to-trigger"));
                    toogle_trigger_questions(questions_to_trigger, "hide");

                } else {
                    hidden_checkbox.prop("checked", true);
                    $(this).find("div.option-can-check").addClass("option-checked");
                    checkBoxCounter++;
                    $(this).find("div[data-option-selected-container=" + checkbox_id + "]")
                        .addClass("option-was-selected")
                        .removeClass("hidden");

                    var questions_to_trigger = JSON.parse($(this).attr("data-questions-to-trigger"));
                    toogle_trigger_questions(questions_to_trigger, "show");
                }

                // handleQuizButton(checkBoxCounter);
            });

            //End Checkbox Button js

            /*function show_spinner() {
                var opts = {
                    lines: 13 // The number of lines to draw
                        ,
                    length: 9 // The length of each line
                        ,
                    width: 14 // The line thickness
                        ,
                    radius: 42 // The radius of the inner circle
                        ,
                    scale: 0.30 // Scales overall size of the spinner
                        ,
                    corners: 1 // Corner roundness (0..1)
                        ,
                    color: '#000' // #rgb or #rrggbb or array of colors
                        ,
                    opacity: 0.25 // Opacity of the lines
                        ,
                    rotate: 0 // The rotation offset
                        ,
                    direction: 1 // 1: clockwise, -1: counterclockwise
                        ,
                    speed: 1 // Rounds per second
                        ,
                    trail: 60 // Afterglow percentage
                        ,
                    fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
                        ,
                    zIndex: 2e9 // The z-index (defaults to 2000000000)
                        ,
                    className: 'spinner' // The CSS class to assign to the spinner
                        ,
                    top: '70%' // Top position relative to parent
                        ,
                    left: '50%' // Left position relative to parent
                        ,
                    shadow: false // Whether to render a shadow
                        ,
                    hwaccel: false // Whether to use hardware acceleration
                        ,
                    position: 'relative' // Element positioning
                }
                var target = document.getElementsByClassName('spinner')[0];
                var spinner = new Spinner(opts).spin(target);
            }

            var submitted = 0;*/

            /*if (show_errors == 'False') {
                $('#question-34').addClass('hidden');
            }*/

            /*$("#quiz #submit-question").on('click', function(event) {

                event.preventDefault();

                var place = autocomplete.getPlace() || null;

                if (place !== null) {
                    responses_modal.modal("show");

                    if (show_errors == 'False') {
                        if (submitted == 0) {
                            $("#submit-question").addClass('hidden');
                            show_spinner();
                            $('.spinner-container').removeClass('hidden');
                            setTimeout(function() {
                                $('#question-34').removeClass('hidden');
                                $("#submit-question").removeClass('hidden');
                                $('.spinner-container').addClass('hidden');
                            }, 3000);
                            submitted += 1;
                        } else {
                            show_spinner();
                            submit_questions();
                        }
                    } else {
                        submit_questions();
                    }

                } else {
                    var error_modal = $("#primary-location-error");
                    error_modal.modal("show");
                }

            });*/

            /*function submit_questions() {
                console.log('submit questions');
                var question_responses = {};

                var radios_checked = $("input[type=radio]:checked");
                radios_checked.each(function(index, element) {
                    var e = $(element),
                        val = e.val();

                    question_text = e.attr("data-question-title");
                    response_text = $("div[data-option-id=" + val + "]").text();

                    question_responses[question_text] = response_text;

                });

                var checkboxs_checked = $("input[type=checkbox]:checked");
                checkboxs_checked.each(function(index, element) {
                    var val = $(element).val();
                    question_text = $(element).attr("data-question-title");

                    response = $("div[data-option-id=" + val + "]");

                    if (response.length) {
                        var text = response.text();
                    } else {
                        var text = $(this).parent().text();
                        console.log(text);
                    }

                    if (question_responses.hasOwnProperty(question_text)) {
                        question_responses[question_text] += "; " + text;
                    } else {
                        question_responses[question_text] = text;
                    }

                });

                var selects = $("select option:selected");
                selects.each(function(index, element) {

                    var e = $(element),
                        response = e.text(),
                        question = e.parent().attr("data-question-title");

                    question_responses[question] = response;
                });

                var text_areas = $("textarea");
                text_areas.each(function(index, element) {

                    var e = $(element),
                        response = e.val();

                    if (response) {
                        question = e.attr("data-question-title");
                        question_responses[question] = response;
                    }
                });

                var inputs_text = $("input[type=text]");
                inputs_text.each(function(index, element) {
                    var response = $(element).val();
                    if (response) {
                        question_text = $(element).attr("data-question-title");
                        question_responses[question_text] = response;
                    }
                });

                var table_body = $("#table-answers tbody"),
                    responses_modal = $("#responses-modal");
                responses_modal.find(".modal-header").css("background-color", "#464646");

                table_body.empty();

                for (key in question_responses) {
                    var row = "<tr><td>" + key + "</td><td>" + question_responses[key] + "</td></tr>";
                    table_body.append(row);
                }
                send_quiz_response();
            }*/

            /*$("#send-answers").on("click", function(evt) {
                $(this).unbind("click");
                send_quiz_response();
            });*/

            /*var first_error = $("ul.errorlist").first();

            if (first_error.length > 0) {
                window.qwe = first_error;
                $('html, body').animate({
                    scrollTop: first_error.offset().top
                }, 2000);
            }*/

            /*var progress_width = $(window).width() - 30;
            var bits_width = ($('.progress-markers li').width() + 0.7) * $('.progress-markers li').length;
            var count = $('.progress-markers li').length;
            var margin = (progress_width - bits_width) / count;

            $('.progress-markers li').css('margin-right', margin + 'px');
            $('.progress-markers li').last().css('margin-right', 0 + 'px');*/

            /*$(document).on("scroll", function(event) {

                var scrollTop = $(this).scrollTop();
                var progressBar = $('.progress-bar-container');

                if ($('.quiz-form').length > 0) {
                    if (scrollTop <= $('.quiz-form').offset().top) {
                        progressBar.css({
                            'position': 'relative',
                            'width': 'auto',
                        })
                    } else if (scrollTop >= progressBar.offset().top) {
                        progressBar.css({
                            'position': 'fixed',
                            'z-index': 2,
                            'top': 0,
                            'width': $(window).width() + 'px',
                        })
                    }
                }

            });*/

            /*$location = $('input[name=your-location]');

            $location.attr('data-tooltip-content', '#autofill_tooltip');

            $location.on('keydown', function(e) {
                var listVisible = $('.pac-container').is(':visible');
                if ((e.keyCode == 13) && (!listVisible)) {
                    google.maps.event.trigger(autocomplete, 'place_changed');
                } else {
                    deleteTooltip();
                }
            });*/
        }
    }
}();