var Service = (function() {
  var booking_data = {};
  var objectState = {
    services: null,
    locations: null,
    plans: null
  }
  if (JSON.parse(localStorage.getItem("objectState")) && JSON.parse(localStorage.getItem("objectState")).services) {
    objectState = JSON.parse(localStorage.getItem("objectState"));
  }
  if (JSON.parse(localStorage.getItem("booking_data"))) {
    booking_data = JSON.parse(localStorage.getItem("booking_data"));
  };

  function buildServiceBox(service) {
    // Build html for Service Box (render like a component)
    var serviceBoxDiv = $("<div></div>").attr("data-service-id", service.pk).addClass("service-box");
    const description = "<p>" + service.description + "</p>";
    const icon = $("<i></i>").addClass(service.icon);
    const title = "<h2>" + service.title + "</h2>";
    const sessionRate = "<p>" + service.session_rate + "</p>";
    $(serviceBoxDiv).append([description, icon, title, sessionRate]);
    return serviceBoxDiv;
  }

  function handleServiceBox(booking_data, time_slot_pk, trainer_slug, virtual_training_only, trainer_pk) {
    // Handle Service Box behaviors on click
    $(".service-box").click(function() {
      var id = $(this).data("service-id");
      booking_data["service_id"] = id;
      localStorage.setItem("booking_data", JSON.stringify(booking_data));
      var serviceBoxList = $(".service-boxes").find(".service-box");
      for (var j = 0; j < serviceBoxList.length; j++) {
        const element = serviceBoxList[j];
        if ($(element).data("service-id") === id) {
          $(element).css("border-color", "#5340ff");
        } else {
          $(element).css("border-color", "#e8e8e8");
        }
      }

      var baseUrl = '';

      if (virtual_training_only) {
        baseUrl = window.location.origin + "/fyt/book/booking_step1/plan/" + trainer_slug;
        if (time_slot_pk) window.location.href = baseUrl + "/?time_slot=" + time_slot_pk + "&service=" + id;
        else window.location.href = baseUrl + "/?service=" + id;
      } else {
          baseUrl = window.location.origin + "/fyt/book/booking_step1/locations/" + trainer_slug;
          if (time_slot_pk) window.location.href = baseUrl + "/?time_slot=" + time_slot_pk;
          else window.location.href = baseUrl;
      }
      
    })
  }

  function renderServiceBoxes(trainer_pk, time_slot_pk, service_boxes, booking_data, trainer_slug, virtual_training_only) {
    // Append every Service Box to main <div id="service_boxes">
    $.ajax({
      url: "/trainer/" + trainer_pk + "/services/offered/",
      dataType: "json",
      success: function(data) {
        objectState.services = data;
        localStorage.setItem("objectState", JSON.stringify(objectState));
        var services = data.training_services_offered;
        for (var i = 0; i < services.length; i++) {
          var service = services[i];
          $(service_boxes).append(buildServiceBox(service, i));
        }
        const currentServiceSelected = booking_data["service_id"];
        const currentTimeSlot = booking_data["time_slot_id"];
        if (currentTimeSlot===time_slot_pk && currentServiceSelected !== null) {
          $("[data-service-id="+currentServiceSelected+"]").css("border-color", "#5340ff");
        }
        handleServiceBox(booking_data, time_slot_pk, trainer_slug, virtual_training_only, trainer_pk);
      },
      error: function(error) {
        console.log(error);
      }
    });
  }

  return {
    init: function(trainer_pk, time_slot_pk, trainer_slug, virtual_training_only) {
      if ((booking_data["time_slot_id"] !== time_slot_pk || booking_data["trainer_pk"] !== trainer_pk) && !booking_data.reschedule_session) {
        booking_data = {};
        localStorage.setItem("booking_data", JSON.stringify(booking_data));
      } else if (booking_data["trainer_pk"] !== trainer_pk && booking_data.reschedule_session) {
        booking_data = {};
        localStorage.setItem("booking_data", JSON.stringify(booking_data));
      }
      var service_boxes = $(".service-boxes")[0];
      booking_data["time_slot_id"] = time_slot_pk;
      booking_data["trainer_pk"] = trainer_pk;
      renderServiceBoxes(trainer_pk, time_slot_pk, service_boxes, booking_data, trainer_slug, virtual_training_only);
    }
  }
})();