var MarketHomePage = (function(){

	var previewsTexts = document.getElementsByClassName("previews-text"),
		moreFYtsTexts = document.getElementsByClassName("trainer-home-page-review"),
		trainerPosition = $('.trainer-position');
	
	return {
		init: function(){
			
			// add "show more" button if preview's text has overflow
			for (var i = previewsTexts.length - 1; i >= 0; i--) {
				if (previewsTexts[i].offsetHeight < previewsTexts[i].scrollHeight 
				|| previewsTexts[i].offsetWidth < previewsTexts[i].scrollWidth) {
				    // your previewsTexts[i] have overflow
					$(previewsTexts[i]).parent().next().removeClass("hidden");
				} else {
				    // your previewsTexts[i] doesn't have overflow
				}
			};

			// add "show more" button if trainer's row has overflow
			for (var i = moreFYtsTexts.length - 1; i >= 0; i--) {
				if (moreFYtsTexts[i].offsetHeight < moreFYtsTexts[i].scrollHeight ||
			    moreFYtsTexts[i].offsetWidth < moreFYtsTexts[i].scrollWidth) {
				    // your moreFYtsTexts[i] have overflow
					$(moreFYtsTexts[i]).next().removeClass("hidden");
				} else {
				    // your moreFYtsTexts[i] doesn't have overflow
				}
			};

			for (var i = 0; i<= trainerPosition.length-1; i++) {
				var position = i + 1;
				trainerPosition[i].append('#'+ position + ' ');
			}

			$('.show-more-preview-text').on('click', function(){
				$(this).prev().children('.previews-text').css('overflow', 'visible');
				$(this).addClass('hidden');
			});
			$('.show-more-reviews').on('click', function(){
				$(this).prev().css('overflow', 'visible');
				$(this).addClass('hidden');
				$(this).next().removeClass('hidden');
			});
			$('.hide-reviews').on('click', function(){
				$(this).prev().prev().css('overflow', 'hidden');
				$(this).addClass('hidden');
				$(this).prev().removeClass('hidden');
			});

			// STARS RATING
			
			$('.readOnly').raty2({ 
		    	readOnly: true, 
		    	score: function() {
		   			return $(this).attr('data-score');
		  		}
		    });

		    $('.readOnly-callback').raty2({
		      readOnly: function() {
		        return 'true becomes readOnly' == 'true becomes readOnly';
		      }
		    });

			// END STARS RATING

			// CAROUSEL

			$('.carousel-control.left').click(function() {
		      $('#market-carousel').carousel('prev');
		    });

		    $('.carousel-control.right').click(function() {
		        $('#market-carousel').carousel('next');
		    });
		}
	} 
}());