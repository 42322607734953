var Yotpo = (function () {

    var get_static_prefix

    return {

        init: function (params) {

            get_static_prefix = params.get_static_prefix

            if ($(window).width() < 767) { 
                $("#theCarousel").removeClass('multi-item-carousel');
            } else {
                $("#theCarousel").addClass('multi-item-carousel');
            }

            $('.yotpo-review-avg').each(function(i, obj) {
                $(obj).children(".review-avg").raty({
                    score: function() {
                        return $(obj).children(".reviews_container").attr("data-score");
                    },
                    readOnly: true,
                    path: get_static_prefix + "lib/raty-2.7.0/images",
                    numberMax : 5,
                });
            });

            vec_img = []

            for (var i = 1 ; i <= 9 ; i++) {
                vec_img.push( get_static_prefix+"img/homepage/AVATARS_YOTPO/Reviews-Avatar-"+i+".gif" )
            };

            vec_img.sort(function() {
                return Math.random()*10 > 5 ? 1 : -1;
            });

            $('.yotpo_img img').each(function(i){
                $(this).attr('src',vec_img[i+1])        
            });

            $('.multi-item-carousel .item').each(function(){
                
                var next = $(this).next();
                
                if (!next.length) {
                    next = $(this).siblings(':first');
                }

                next.children(':first-child').clone().appendTo($(this));

                if (next.next().length>0) {
                    next.next().children(':first-child').clone().appendTo($(this));
                } else {
                    $(this).siblings(':first').children(':first-child').clone().appendTo($(this));
                }
                
            });
        }
    }; 

}());