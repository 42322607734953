/*globals
    jQuery,
    _,
*/

var popupFormBuilder = (function (_) {
  var spinnerOpts = {
    lines: 13, // The number of lines to draw
    length: 9, // The length of each line
    width: 14, // The line thickness
    radius: 42, // The radius of the inner circle
    scale: 0.3, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    color: "#000", // #rgb or #rrggbb or array of colors
    opacity: 0.25, // Opacity of the lines
    rotate: 0, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    speed: 1, // Rounds per second
    trail: 60, // Afterglow percentage
    fps: 20, // Frames per second when using setTimeout() as a fallback for CSS
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    className: "spinner", // The CSS class to assign to the spinner
    top: "15%", // Top position relative to parent
    left: "50%", // Left position relative to parent
    shadow: false, // Whether to render a shadow
    hwaccel: false, // Whether to use hardware acceleration
    position: "absolute", // Element positioning
  };

  return function () {
    var i,
      $openModalBtn,
      $modal,
      $form,
      url,
      validators,
      template,
      initialData,
      postOpenCallback,
      postSuccessCallback;

    function getCallbackContext() {
      var formData = {},
        array = $form.serializeArray();

      for (i = 0; i < array.length; i++) {
        formData[array[i].name] = array[i].value;
      }

      return {
        $openModalBtn: $openModalBtn,
        formData: formData,
        initialData: initialData,
      };
    }

    function submit(e) {
      e.preventDefault();

      var errorsCounter = 0;
      for (i = 0; i < validators.length; i++) {
        if (!validators[i]($form)) {
          errorsCounter++;
        }
      }
      if (errorsCounter > 0) {
        return;
      }

      var body = $("body")[0];
      var spinner = new Spinner(spinnerOpts).spin(body);
      var spinnerElement = spinner.el;

      $.ajax({
        dataType: "json",
        type: "POST",
        url: url,
        data: $form.serialize(),
        success: function () {
          spinnerElement.remove();
        },
        error: function (error) {
          spinnerElement.remove();
          // $modal.modal("hide");
          // $form.trigger("reset");
          if (
            error.status === 404 &&
            error["responseJSON"] &&
            error["responseJSON"]["message"] === "sanitizer"
          ) {
            bootbox.alert(
              "<br>We don't allow the sharing of contact info until after booking.<br><a href='https://findyourtrainer.com/faqs/general/message-contact-info-removed/'>Learn why</a><br>"
            );
          } else {
            bootbox.alert("<br>Internal Server Error<br>");
          }
        },
      }).done(function () {
        postSuccessCallback(getCallbackContext());
        $modal.modal("hide");
        $form.trigger("reset");
      });
    }

    function buildNotEmptyValidator(name, errorMsgSelector) {
      return function ($form) {
        if ($form.find("[name=" + name + "]").val() === "") {
          $form.find(errorMsgSelector).show();
          $form.find(errorMsgSelector).fadeOut(6000);
          return false;
        }
        return true;
      };
    }

    function buildNotZeroValidator(name, errorMsgSelector) {
      return function ($form) {
        if (
          $form.find("[name=" + name + "]").val() === "0" ||
          $form.find("[name=" + name + "]").val() === 0
        ) {
          $form.find(errorMsgSelector).show();
          $form.find(errorMsgSelector).fadeOut(6000);
          return false;
        }
        return true;
      };
    }

    function openModal() {
      $openModalBtn = $(this);
      initialData = $openModalBtn.data();
      $modal.find(".modal-content").html(template(initialData));
      $form = $modal.find("form");
      $form.find("[type=submit]").on("click", submit);
      $modal.modal("show");
      postOpenCallback(getCallbackContext());
    }

    return {
      init: function (params) {
        template = _.template($(params.templateSelector).html());
        $modal = $(params.modalSelector);
        url = params.url;
        validators = params.validators || [];
        postSuccessCallback =
          params.postSuccessCallback ||
          function () {
            return;
          };
        postOpenCallback =
          params.postOpenCallback ||
          function () {
            return;
          };

        $("body").on("click", params.openModalBtnSelector, openModal);
      },
      buildNotEmptyValidator: buildNotEmptyValidator,
      buildNotZeroValidator: buildNotZeroValidator,
    };
  };
})(_);
