/*globals
    window,
    debounce,
*/

var header = (function (window, debounce) {
    function fixHeight() {
        var width = window.innerWidth || window.clientWidth;
        var height = $(window).height();
        $('header.first .menu').css('height', (width < 768) ? height : 'auto');
    }

    function limitUsername() {
        if ((window.innerWidth > 970) && (window.innerWidth < 1090)) {
            $('#header-dropdown-menu').css('text-overflow', 'ellipsis');
            $('#header-dropdown-menu').css('overflow', 'hidden');
            $('#header-dropdown-menu').css('max-width', '170px');
        } else if (window.innerWidth >= 1090) {
            $("#header-dropdown-menu").css('max-width', 'none');
        }
    }

    return {
        init: function () {
            $(window).load(function () {
                fixHeight();
                limitUsername();
                $(window).resize(function () {
                    debounce(fixHeight, 250);
                    limitUsername();
                });
                $(".navbar-toggler").on('click', function () {
                    $(".navbar-toggler-icon").toggleClass('open')
                })
                $('html, body').on('touchmove', function (e) {
                    if ($(".navbar-collapse").hasClass('in')) {
                        //prevent native touch activity like scrolling
                      e.preventDefault();
                    }
                });
            });
        }
    };
}(window, debounce));

$(window).scroll(function () {
    if ($(this).scrollTop() > 0) {
        $("header").addClass("sticky");
    } else {
        $("header").removeClass("sticky");
    }
})