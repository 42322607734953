var FavoriteTrainer = (function($) {
    'use strict';

    var options = {
        selector: {
            btn: '.btn.favorite',
            placeholder: 'span.favorite-text'
        },
        url: {
            follow: undefined,
            unfollow: undefined,
            login_redirect: undefined
        }
    };

    return {
        init: function(opts) {
            options = $.extend(options, opts);

            $(document).on('click', options.selector.btn, function(evt) {
                evt.preventDefault();

                // trainer button
                var $target = $(evt.currentTarget);

                // flags
                var favorited = $target.hasClass('favorited');
                var logged = $target.hasClass('logged');

                var url = {
                    follow: options.url.follow || $target.data('follow-url'),
                    unfollow: options.url.unfollow || $target.data('unfollow-url'),
                    login_redirect: options.url.login_redirect || $target.data('login-redirect'),
                }

                var onSuccess = function(data) {
                    var $glyph = $target.children('.glyphicon');
                    $glyph.toggleClass('glyphicon-heart glyphicon-heart-empty');
                    
                    var $imgheart = $target.children('.heart-img');
                    $imgheart.attr('src', favorited ? '/static/img/Favorite-Heart-Pink.svg' : '/static/img/Favorite-Heart-Filled.svg')

                    $target.find(options.selector.placeholder).text(function(idx, placeholder) {
                        return placeholder === 'Favorite' ? 'Favorited' : 'Favorite'
                    });

                    // toggle 'favorited'
                    $target.toggleClass('favorited');
                }

                var onError = function(error) {
                    console.log(error);
                }

                if (logged) {
                    var url = favorited ? url.unfollow : url.follow;
                    $.getJSON(url, onSuccess).fail(onError);
                } else {
                    window.location.href = url.login_redirect;
                }
            });
        }
    }
})(jQuery)