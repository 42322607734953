var SearchMap = (function(GoogleMap) {
    'use strict';

    var params = {}

    var gmap = null,
        zipcodes = [],
        gyms = [],
        markerlabel = 0

    var dom = {
        preloader: '.preloader',
        map_canvas: '#map-canvas',
        open_calendar: '.open-calendar'
    }

    var init = function(params) {
        $.extend(this.params, params);

        zipcodes = params.zipcodes;
        gyms = params.gyms;

        // init map
        setupMap();

        // gym pins & markerlabs
        $.each(gyms, function(idx, gym) {
            gmap.addLocationMarker(gym, ++markerlabel);

            $('.location-marker[data-pk=' + gym.pk + ']').each(function() {
                if (markerlabel <= 10) {
                    $(this).text(markerlabel);
                }
            })
        })

        // fit bounds after adding markers
        //if (gyms.length > 0) {
        //    gmap.fitBounds();
        //}

        // if in home trainers, render zipcodes from Fusion Tables
        if (zipcodes.length > 0) {
            gmap.renderZipcodes(zipcodes);
        }

        // redo search
        $("#redo-search").click(redoSearch);

        return true;
    }

    var setupMap = function() {

        gmap = new GoogleMap({
            container: dom.map_canvas
        });

        gmap.init({
            lat: parseFloat(params.lat),
            lng: parseFloat(params.lng),
            mapOptions: {
                zoom: 11,
                /*
                minZoom: 11,
                maxZoom: 15
                */
            }
        });

    }

    var redoSearch = function() {
        var bounds = gmap.map.getBounds();

        window.location.href = URI().setSearch({
            lat: bounds.getCenter().lat().toFixed(4),
            lng: bounds.getCenter().lng().toFixed(4),
            e: bounds.getNorthEast().lng().toFixed(4),
            n: bounds.getNorthEast().lat().toFixed(4),
            w: bounds.getSouthWest().lng().toFixed(4),
            s: bounds.getSouthWest().lat().toFixed(4),
            bbox: true
        });
    }

    return {
        params: params,
        init: init
    }

})(GoogleMap);


var SearchFilters = (function() {
    'use strict';

    var filters = {}

    var $dom = {
        'container': $('.search-filters'),
        'filter': $('.search-filter'),
        'hide_btn': $('#hide-filters'),
        'multi_input': $('li.multi-input input.father'),
        'clear': $('#clear-filters')
    }

    var refresh = function() {
        $dom.filter.each(function(idx, filter) {
            var placeholder = $(filter).find('span.name')
            var items = []

            // color checked items
            $(filter).find("input:not(.father)").each(function(idx, input) {
                var label = $(input).parent("label");
                if ($(input).is(':checked')) {
                    items.push($.trim(label.text()));
                    label.css('color', '#333')
                } else {
                    label.css('color', 'inherit');
                }
            })

            // check father if any children
            $(filter).find('input.father').each(function() {
                var father = $(this).val();
                var $children = $('input[data-parent=' + father + ']');

                if ($children.filter(':checked').length > 0) {
                    $(this).prop('checked', true);
                    //items.push($(this).val());
                } else {
                    $(this).prop('checked', false);
                }
            })

            // parse placeholder
            if (items.length) {
                placeholder.css('color', '#333');
                placeholder.text(items.join(', '))
            } else {
                placeholder.text(filters[idx])
                placeholder.css('color', 'inherit');
            }
        })
    }

    //var mobile = function() {
    //    if (screen.width <= 480) {
            // Show/Hide filters btn
    //        $dom.hide_btn.show(); $dom.container.hide();

    //        $dom.hide_btn.click(function(e) {
    //            e.preventDefault();
    //            $dom.container.slideToggle();
    //            $(this).text(function(i, text) {
    //                return text === "Show Filters" ? "Hide Filters" : "Show Filters"
    //            })
    //        })
    //    }
    //}

    var events = function() {
        $dom.filter.click(function(e) {
            e.stopPropagation(); // avoid closing dropdown
            refresh() // refresh filters
        });

        $dom.multi_input.click(function() {
            var parent = $(this).val();
            if ($(this).is(':checked')) {
                $("input[data-parent=" + parent + "]").prop("checked", true);
            } else {
                $("input[data-parent=" + parent + "]").prop("checked", false);
            }
        });
    }

    var init = function(params) {
        $.extend($dom, params)

        $dom.filter.each(function(idx, filter) {
            var item = $(filter).find('span.name')
            filters[idx] = item.text()
        });

        // refresh filters
        refresh();

        // mobile logic
        //mobile();

        // events
        events();

    }

    return {
        init: init
    }
})();
