var Registration = (function (){

    var csrf = null;

    return {

        init: function(params){

            csrf = params.csrf;

            /*register.js*/
            var redirect = document.URL.split('?')
            if (redirect.length > 1){
                var ref_code = redirect[1].slice(0,6);
                if(ref_code !== 'next=/'){
                    $('#id_discount_code').val('');
                    var referralCode = document.URL.split('=')[1];
                    if(referralCode !== undefined){
                        $('#id_discount_code').val(referralCode);
                    } else {
                        $('#id_discount_code').val('');
                    }
                }
            }

            function get_url_param(name){
                try {
                    var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
                    return results[1] || null;
                }
                catch (ex) {
                    return null;
                }
            }


            function callback_after_fb_me(deferer, _FB_response, me_response, array_friends_fb_id){
                    var d = deferer;

                    var data = {};
                    data.csrfmiddlewaretoken = csrf;
                    data.user_id = _FB_response['authResponse']['userID'];
                    data.access_token =  _FB_response['authResponse']['accessToken'];
                    data.expires_in = _FB_response['authResponse']['expiresIn'];
                    data.signed_request = _FB_response['authResponse']['signedRequest'];
                    data.friends_fb_id = JSON.stringify(array_friends_fb_id);

                    if(me_response){
                        data.me = JSON.stringify(me_response);
                    }

                    d.notify(me_response);

                    $.ajax({
                        url: window.URLS.sign_up_in_facebook,
                        data: data,
                        type: "POST",
                        success: function(dataCheck) {

                            var url = get_url_param("next");

                            if (url === "/" || url === null){
                                var url = '/#account';
                            }else{
                                url = decodeURIComponent(url);
                            };

                            d.resolve(dataCheck, url);
                        },
                        error: function(dataCheck){
                            d.reject(dataCheck);
                        }
                    });
                }


            function checkPermissions(){
                var d_2 = $.Deferred();

                FB.api('/me/permissions', function(permissions){

                    var not_granted = [];

                    for(var i=0; i < permissions.data.length; i++){
                        var obj = permissions.data[i];
                        if ( (obj.status !== 'granted') && (obj.permission !== 'user_friends' ) ){
                            not_granted.push(obj.permission);
                        }
                    }

                    if ( not_granted.length > 0 ){
                        d_2.reject(not_granted);
                    }

                    d_2.resolve();

                });

                return d_2.promise();
            }


            function facebookAuthenticate(){
                 var d = $.Deferred();
                 FB.login(function(_FB_response) {

                    console.log(_FB_response);

                    if (_FB_response.status === 'connected') {


                        var check_user_perms = checkPermissions();

                        check_user_perms.done(function(){
                            var array_friends_fb_id = [];

                            FB.api('/me?fields=email,birthday,picture.type(large),first_name,last_name,gender,friends', function(me_response) {
                                console.log(me_response)

                                var list_friends = me_response.friends.data;

                                for (var i=0; i<list_friends.length; i++){
                                    var friend = list_friends[i];
                                    var friend_fb_id = friend.id;
                                    array_friends_fb_id.push(friend_fb_id);
                                }

                                callback_after_fb_me(d, _FB_response, me_response, array_friends_fb_id)

                            });
                        }).fail(function(not_granted){
                            d.reject(not_granted);
                        });

                    }else{
                        d.reject();
                    }
                }, {
                    scope: 'email, public_profile, user_friends, user_birthday',
                    auth_type: 'rerequest'
                });

                return d;
            };

            $(".register-button").click(function(){
                facebookAuthenticate().done(function(dataCheck, url){

                    var status = dataCheck.status;

                    if (status){

                        window.location = url;
                    };

                }).fail(function(not_granted){

                    var error_list = $("#fb-error-list");

                    error_list.empty();

                    if (typeof(not_granted) !== "undefined"){
                        var error = "<li>Your email address and birthday are required to sign up with Facebook.</li>"
                        error_list.append(error);
                    }

                    error_list.parent().removeClass("no-show");
                });
            });

        } // init end
    }

})();