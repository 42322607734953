var TrainerReviewLink = (function(){

	var form = $('#rw-link-form');
	
	var inputs = [{'name': 'title', 'class': '.title-is-empty'}, {'name': '_author_full_name', 'class': '.author-name-is-empty'}, {'name': 'content', 'class': '.body-is-empty'}, 
	{'name': 'stars', 'class': '.stars-required'}];
	
	var raty_options = {
        readOnly: false,
        hints: ['bad', 'poor', 'regular', 'good', 'great'],
        click: function (score) {
            $('[name=stars]').val(score);
        }
    };

	
	return {
		init: function(){

			// init with raty stars input
            
            $('#rw-link-stars-widget').raty(raty_options);

            function getFormObj() {
			    var formObj = {};
			    var inputs = form.serializeArray();
			    $.each(inputs, function (i, input) {
			    	formObj[input.name] = input.value;
			    });
			    return formObj;
			}

			form.submit(function( event ) {
				event.preventDefault();
				var errorsCounter = 0;
	            for (i = 0; i < inputs.length; i++) {
	                if (!validate(inputs[i]['name'], inputs[i]['class'])) {
	                    errorsCounter++;
	                }
	            }
	            if (errorsCounter > 0) {
	                return;
	            }

				$.ajax({
	                dataType: 'json',
	                type: 'POST',
	                url: '/reviews/create/',
	                data: form.serialize(),
	            }).done(function () {
	                form.trigger('reset');
	                $('#form-container').hide();
					$('#thanks-review').fadeIn(2000);
	            });
			});

        	function validate(name, errorMsgSelector){
        		if (form.find('[name=' + name + ']').val() === "") {
	            	form.find(errorMsgSelector).show();
	                form.find(errorMsgSelector).fadeOut(6000);
	                return false;
	            }
	            return true;
        	}
		}
	} 
}());