Days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

Date.prototype.formattime = function() {
    return ((this.getHours() + 11) % 12 + 1).toString() + ":" + (this.getMinutes() < 9 ? 0 : "") + this.getMinutes() + (this.getHours() < 12 ? 'am' : 'pm');
};
Date.prototype.setDay = function(day) {
    this.setDate(this.getDate() + day - this.getDay());
};
Date.prototype.setUTCDay = function(day) {
    this.setUTCDate(this.getUTCDate() + day - this.getUTCDay());
};

Date.prototype.addHours= function(h){
    this.setHours(this.getHours()+h);
    return this;
};
Date.prototype.addMinutes= function(minutes){
    this.setMinutes(this.getMinutes() + minutes);
    return this;
};
Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + days);
    return this;
};

Date.prototype.apiFormat = function(){
    var year = this.getFullYear();

    var zeropadded = function(str) {
        return ('0' + str).slice(-2)
    }

    var month = zeropadded(this.getMonth()+1)
    var day = zeropadded(this.getDate())

    return year + '/' + month + '/' + day;
};

// Keep these in sync with sql_calendar/utils.py
var AVAILABLE = 0,
    UNAVAILABLE = 1,
    INACCESSIBLE = 2,
    BOOKED_SESSION = 3,
    GYM_CANCELED_SESSION = 4,// If gym cancels, don't make it available again.
    MEMBER_CANCELED_SESSION = 5,
    COMPLETED_SESSION = 6,
    DELETED_TIMESLOT = 7,

    SESSION_BOOKED_WITH_MONEY = 8,
    SESSION_BOOKED_WITH_SESSION_CREDITS = 9,
    SESSION_BOOKED_WITH_SUBSCRIPTION = 10,

    SESSION_COMPLETED_BOOKED_WITH_MONEY = 11,
    SESSION_COMPLETED_BOOKED_WITH_SESSION_CREDITS = 12,
    SESSION_COMPLETED_BOOKED_WITH_SUBSCRIPTION = 13,

    CALENDAR_CONFLICT = 14,
    TRAVEL_TIME = 15,

    SESSION_BOOKED_CONSULTATION = 16,
    SESSION_COMPLETED_CONSULTATION = 17;

//Keep this in sync with Locationtypes Model
var HEALTH_CLUB = 1,
    PRIVATE_STUDIO_OR_BOUTIQUE = 2,
    MY_HOME_OR_BUILDING_GYM = 4;

PRIVATE_STUDIO = PRIVATE_STUDIO_OR_BOUTIQUE;
IN_HOME = MY_HOME_OR_BUILDING_GYM;

//These variables will be set when the Grid is initialized.
var DURATION_SESSION_SECONDS,
    TRAVEL_INTERVAL = 0,
    START_TIME_ON_HALF_HOUR;

//Services
var ONE_ON_ONE = "Individual training",
    TWO_ON_ONE = "Partner training (2 people)",
    SMALL_GROUP = "Small group training (up to 6 people)";

function isBooked(s){
    return (s == SESSION_BOOKED_WITH_MONEY ||
            s == SESSION_BOOKED_WITH_SESSION_CREDITS ||
            s == SESSION_BOOKED_WITH_SUBSCRIPTION ||
            s == SESSION_BOOKED_CONSULTATION)
}

function isCompleted(s){
    return (s == SESSION_COMPLETED_BOOKED_WITH_MONEY ||
            s == SESSION_COMPLETED_BOOKED_WITH_SESSION_CREDITS ||
            s == SESSION_COMPLETED_BOOKED_WITH_SUBSCRIPTION ||
            s == SESSION_COMPLETED_CONSULTATION)
}

function isConsultation(s) {
    return (s == SESSION_BOOKED_CONSULTATION ||
            s == SESSION_COMPLETED_CONSULTATION)
}

function convertToOldStates(s){
    "use strict";
    if (isBooked(s)){
        return BOOKED_SESSION;
    }
    if (isCompleted(s)) {
        return COMPLETED_SESSION;
    }
    return s;
}

function get_session_text(session){
    "use strict";
    var text = session.member;

    switch (session.service){
        case TWO_ON_ONE:
            text += " Partner";
            break;
        case SMALL_GROUP:
            text += " Group";
            break;
        default:
    }

    return text;

}

function get_service_class(session){
    "use strict";
    var service_class = "";

    if (session.service === TWO_ON_ONE || session.service === SMALL_GROUP){
        service_class = " groups";
    }

    return service_class;
}

function set_service_attr($slot, service_type){
    "use strict";

    var service = "";

    switch (service_type){
        case TWO_ON_ONE:
            service = "Partner";
            break;
        case SMALL_GROUP:
            service = "Group";
            break;
        default:
            break;
    }

    $slot.attr("service", service);
}

// Slightly different hour/minute adjustment, passing back components.
adj_hour_minute = function(hour, minute) {
    var adj_minute = minute;
    var adj_hour = hour - 12;
    var adj = "pm";
    if (adj_hour < 0) {
       adj = "am";
       if (hour == 0) {
           adj_hour = 12;
       }
       else {
           adj_hour = hour;
       }
    }
    else if (adj_hour == 0) {
        adj_hour = hour;
    }
    if (adj_minute == 0) {
        adj_minute = '00';
    } else if(adj_minute < 10) {
        adj_minute = '0' + adj_minute;
    }
    return [adj_hour, adj_minute, adj];
};

// time string manipulation
leadingZero = function(n){return n > 9 ? "" + n: "0" + n;};

// min_hour_date_month_year date stamp identifier.
generate_datestamp = function (the_date) {
    return leadingZero(the_date.getMinutes())+"_"
    +leadingZero(the_date.getHours())+"_"
    +leadingZero(the_date.getDate())+"_"
    +leadingZero(the_date.getMonth())+"_"
    +the_date.getFullYear()
};
generate_utc_datestamp = function (the_date) {
    return leadingZero(the_date.getUTCMinutes())+"_"
    +leadingZero(the_date.getUTCHours())+"_"
    +leadingZero(the_date.getUTCDate())+"_"
    +leadingZero(the_date.getUTCMonth())+"_"
    +the_date.getUTCFullYear()
};
datestamp_to_date = function (the_stamp) {
    var t=[];
    //stamp_minute_hour_date_month_year
    if (the_stamp.search("stamp_") >= 0) {
        t=the_stamp.replace("stamp_","").split("_").reverse().map(Number);
    } else {
        t=the_stamp.split("_").reverse().map(Number);
    }
    //[year,month,date,hour,minute]
    t[1] += 1; // month runs from 0 to 11. add 1
    // Slashes are cross-browser compatible, and are required here.
    ymd = t[0] + '/' + t[1] + '/' + t[2];
    this_time = new Date(ymd);
    this_time.setHours(parseInt(t[3]));
    this_time.setMinutes(parseInt(t[4]));
    return this_time;
};

// string format min_hour_date_month_year
fyt_min = function(datestamp_string){return datestamp_string.slice(0,2);};
fyt_hour = function(datestamp_string){return datestamp_string.slice(3,5);};
fyt_day = function(datestamp_string){return datestamp_string.slice(6,8);};
fyt_month = function(datestamp_string){return datestamp_string.slice(9,11);};
fyt_year = function(datestamp_string){return datestamp_string.slice(12);};
day_as_string = function(day){return Days[day]};
month_as_string = function(month){return Months[month]};

// Time slots come from the back end, and have hour, min, day, etc hard-coded.
// Subtract 1 from the month, since js dates start from zero.
generate_stamp_from_time_slot = function(time_slot, end, is_id) {
    if (end == true) {
        // Don't use start or end seconds here. Converting it leads to DST/UTC issues.
        // This must be done in the user's time zone, so construct a naive datetime, NOT from UTC values.
        var end_datetime = datestamp_to_date(leadingZero(time_slot.minute) + "_" + leadingZero(time_slot.hour) + "_" + leadingZero(time_slot.day_of_month) + "_" + leadingZero(parseInt(time_slot.date.slice(5,7)) - 1) + "_" + time_slot.date.slice(0,4));

        var old_time = new Date(end_datetime.getTime()); // deep copy
        end_datetime.setMinutes(end_datetime.getMinutes() + time_slot.duration_minutes);

        // This should only be necessary for Spring Ahead: to avoid a non-existent time.
        // js won't adjust a time to an non-existent hour, so it stays the same.
        if (old_time.getMinutes() == end_datetime.getMinutes() && old_time.getHours() == end_datetime.getHours()) {
            end_datetime.setHours(end_datetime.getHours()+2);
            if (duration_minutes > 60) {
                end_datetime.setMinutes(end_datetime.getMinutes() + time_slot.duration_minutes - 60);
            }
        }
        if (is_id == true) {
            return "stamp_" + generate_datestamp(end_datetime);
        } else {
            return generate_datestamp(end_datetime);
        }
    }
    else {
        if (is_id == true) {
            return "stamp_" + leadingZero(time_slot.minute) + "_" + leadingZero(time_slot.hour) + "_" + leadingZero(time_slot.day_of_month) + "_" + leadingZero(parseInt(time_slot.date.slice(5,7)) - 1) + "_" + time_slot.date.slice(0,4);
        } else {
            return leadingZero(time_slot.minute) + "_" + leadingZero(time_slot.hour) + "_" + leadingZero(time_slot.day_of_month) + "_" + leadingZero(parseInt(time_slot.date.slice(5,7)) - 1) + "_" + time_slot.date.slice(0,4);
        }
    }
};


/////////////////////////////////////////////////////////////
// Used for viewing/filling individual trainer schedule viewed by trainers & staff.
///////////////////////////////////////////////////////////////

// Adds days to the trainer's view of their own schedule.
// Lowest interval is in seconds.
jQuery.fn.add_day = function(midnight, lowest_interval, start_time_on_half_hour) {
    var today = new Date();

    var block_time = new Date(midnight),
        column = $('<ul class="availability"></ul').appendTo($('<li class="availability-column"></li>').appendTo(".availability-data")),
        limit = 24 * (3600/lowest_interval),
        divide_slot = 1;

    if (start_time_on_half_hour){
        limit = limit * 2;
        divide_slot = 2;
    }else{
        $("li.availability-column").addClass("full-session");
        $("li.time-slot").addClass("full-session");
    }

    if(today.getDate() == midnight.getDate() && today.getMonth() == midnight.getMonth() && today.getFullYear() == midnight.getFullYear()){
        var date_text = block_time.getDate() + ' ' + Days[block_time.getDay()] + ' ' + '<img id="warning-today" style="width: 20px; height: 15px;" src="/static/img/warning.svg">';
    } else {
        var date_text = block_time.getDate() + ' ' + Days[block_time.getDay()];
    }

    $('<li class="day-and-date">' + date_text + '</li>').appendTo('.day-date-container ul');

    // set i = 1 if i = 0 then last day if off by 1 hour
    for (var i=1; i<=limit; i++) {
        end_time = new Date(((block_time.getTime() / 1000) + lowest_interval) * 1000);
        if (block_time.getHours() >= 4) {
            $('<li class="blocked modal-clasp"></li>').appendTo(column).attr("id", "stamp_" + generate_datestamp(block_time)).attr('end', generate_datestamp(end_time));
        }
        var old_time = new Date(block_time.getTime()); // deep copy

        block_time.setMinutes(block_time.getMinutes() + lowest_interval/60/divide_slot);
        // This should only be necessary for Spring Ahead: to avoid a non-existent time.
        // js won't adjust a time to an non-existent hour, so it stays the same.
        if (old_time.getMinutes() == block_time.getMinutes() && old_time.getHours() == block_time.getHours()) {
            $('<li class="blocked modal-clasp"></li>').appendTo(column).attr("id", 'nonexistent_dst').attr('end', 'nonexistent_dst');
            block_time.setHours(block_time.getHours()+2);
        }
    }
    return block_time;
};

jQuery.fn.set_grid = function(trainerID, resulttime) {
    console.log('set_grid');
    $(this).find("#week_day_headings > li:not(.hourcolumn)").remove();
    $(this).find("#week_day_columns > li:not(.header-time-period)").remove();
    // make sure the span doesn't double up as the grid is updated
    $(this).find('.availability li span').remove();
    //find the next full hour and the last midnight within 7 days

    //this morning at 00
    var lastmidnight = new Date(resulttime);
    //seven days forward at 00
    lastmidnight.setHours(7 * 24);
    //this morning at 00
    var previoustime = new Date(resulttime);
    //seven days back at 00
    previoustime.setHours(-7 * 24);
    //seven days from today at 00
    var nexttime = new Date(lastmidnight);
    //reset to this sunday at 00
    nexttime.setDay(1);
    //reset for this morning at 00
    var block_time = new Date(resulttime);
    $(this).attr("start", generate_datestamp(resulttime)).attr("end", generate_datestamp(lastmidnight));

    // seven days from now at 11pm
    lastmidnight.setHours(-1);
    //set timestamp
    $('#slide-back').attr('time', generate_datestamp(previoustime)).text('Prev Week');
    $('#slide-forth').attr('time', generate_datestamp(nexttime)).text('Next Week');
    $('#date-range').text(Months[resulttime.getMonth()] + " " + resulttime.getDate() + " - " + Months[lastmidnight.getMonth()] + " " + lastmidnight.getDate());

    // Store and pass the scope of the whole schedule.
    whole_schedule = $(this);

    $.getJSON('/calendar/trainer_intervals/?trainer_id='+trainerID).done(function(response) {

        DURATION_SESSION_SECONDS = response.duration_minutes * 60, // Lowest interval in seconds.
        TRAVEL_INTERVAL = response.travel_interval,
        START_TIME_ON_HALF_HOUR = response.start_time_on_half_hour;

        if (!START_TIME_ON_HALF_HOUR){
            whole_schedule.addClass("show-full-sessions");
        }

        //create avail time slots for 7 days
        for (var i = 0; i < 7; i++) {
            block_time = $(this).add_day(block_time, DURATION_SESSION_SECONDS, START_TIME_ON_HALF_HOUR);
        }
        //populate grid with trainer availibility
        var data = {
            trainer: trainerID,
            state: 'all',
            start:  generate_datestamp(resulttime),
            end: generate_datestamp(lastmidnight)
        };
        $(this).fill_trainer_schedule(whole_schedule, "/calendar/schedule/", data, DURATION_SESSION_SECONDS,
             function(){},function(){alert("Error updating trainer schedule. Maybe network is down?");} );
    }).fail(function(){alert("Error gettting trainer intervals. Maybe network is down?");});
};

var draw_session = function(session){

    var slot = $('.availability li#'+session.slot_start).attr('data-duration', session.duration_minutes),
        notes = session.notes,
        next_slot = slot.next(),
        state = session.state;

    if (session.id_recurring != null) {
        slot.addClass('recurring');
    }

    if (START_TIME_ON_HALF_HOUR){
        slot.addClass("half-session");
    } else {
        slot.addClass("full-session");
    }

    if (state === SESSION_BOOKED_WITH_SUBSCRIPTION || state === SESSION_COMPLETED_BOOKED_WITH_SUBSCRIPTION){
        slot.attr("subscription_price", session.subscription_price);
    }

    switch (convertToOldStates(state)) {
        case AVAILABLE:

            slot.addClass('available modal-clasp')
            .attr('end', session.slot_end)
            .attr('slot_id', session.slot_id)
            .attr('single_session_price', session.single_session_price)
            .attr('subscribe_and_save_price', session.subscribe_and_save_price)
            .attr('subscription_discount', session.subscription_discount)
            .removeClass('blocked fyt-booked')
            .append('<span>Available</span>');
            break;

        case BOOKED_SESSION:
            var text = get_session_text(session),
                service_css_class = get_service_class(session);

            set_service_attr(slot, session.service);

            if(session.member_origin=="Non_FYT_Client"){
                slot.addClass('non-fyt-booked');
            }

            if(isConsultation(state)) {
                slot.addClass('consultation')
                text += " (CONSULT)"
            }

            slot.addClass("fyt-booked modal-clasp")
            .addClass(service_css_class)
            .attr('end', session.slot_end)
            .attr('slot_id', session.slot_id)
            .attr('session_price', session.session_price)
            .attr('single_session_price', session.single_session_price)
            .attr('subscribe_and_save_price', session.subscribe_and_save_price)
            .attr('address', session.address)
            .attr('special_instructions', session.special_instructions)
            .attr('member_phone', session.member_phone)
            .attr('trainer_payout', session.trainer_payout)
            .attr('location', session.location)
            .removeClass('blocked calendar-conflict')
            .append('<span>' + text + ' </span>');

            if (START_TIME_ON_HALF_HOUR){
                if (session.next_slot){

                    if (next_slot !== null){
                        slot.addClass('double-session')
                        .removeClass('half-session');
                        next_slot.hide();
                    }
                }
            }

            break;

        case COMPLETED_SESSION:
            var text = get_session_text(session),
                service_css_class = get_service_class(session);

            set_service_attr(slot, session.service);

            if(session.member_origin=="Non_FYT_Client"){
                slot.addClass('non-fyt-complete');
            }

            slot.addClass("fyt-complete modal-clasp")
            .addClass(service_css_class)
            .attr('end', session.slot_end)
            .attr('slot_id', session.slot_id)
            .attr('session_price', session.session_price)
            .attr('single_session_price', session.single_session_price)
            .attr('subscribe_and_save_price', session.subscribe_and_save_price)
            .removeClass('fyt-booked blocked')
            .attr('address', session.address)
            .attr('special_instructions', session.special_instructions)
            .attr('member_phone', session.member_phone)
            .attr('trainer_payout', session.trainer_payout)
            .attr('location', session.location)
            .append('<span>' + text +' completed</span>');

            // This sucks. There is a bug that some Complete Sessions.
            // has an extra <span>Available</span> and it should be removed.
            slot.find("span:contains('Available')").remove();

            if (START_TIME_ON_HALF_HOUR){
                if (session.next_slot){

                    if (next_slot != null){
                        slot.addClass('double-session')
                        .removeClass('half-session');
                        next_slot.hide();
                    }
                }
            }

            break;

        case UNAVAILABLE:

            if (notes === '' || notes === null){
                notes = 'Unavailable';
            }

            slot.addClass('trainer-reserved modal-clasp')
                .attr('start', session.slot_start)
                .attr('end', session.slot_end)
                .attr('slot_id', session.slot_id)
                .removeClass('blocked')
                .append('<span>'+notes+'</span>')
            break;

        case CALENDAR_CONFLICT:
            //if (notes === '' || notes === null){
            notes = 'Calendar Conflict';
           // }else{
             //   notes = notes.substring(0, 15);
            //}

            slot.addClass('calendar-conflict modal-clasp')
                .attr('start', session.slot_start)
                .attr('end', session.slot_end)
                .attr('slot_id', session.slot_id)
                .removeClass('blocked')
                .append('<span>'+notes+'</span>')
            break;

        case TRAVEL_TIME:
            if (notes === '' || notes === null){
                notes = 'Travel Time';
            }

            slot.addClass('travel-time modal-clasp')
                .attr('start', session.slot_start)
                .attr('end', session.slot_end)
                .attr('slot_id', session.slot_id)
                .removeClass('blocked')
                .append('<span>'+notes+'</span>')
            break;

        case INACCESSIBLE:

/*
            slot.addClass('inaccessible')
            .removeClass('blocked')
            .append('<span>overlapping sessions</span>');
            break;

*/
        case GYM_CANCELED_SESSION:
            //slot.addClass('unavailable').text('Gym/Trainer canceled');
            break;

        case MEMBER_CANCELED_SESSION:
            //slot.addClass('unavailable').text('Member canceled');
            break;

        //case COMPLETED_SESSION:
            //slot.addClass('unavailable').text('Booked and completed');
            //break;

        case DELETED_TIMESLOT:
/*
            slot.addClass('deleted')
            .removeClass('blocked')
            .append('<span>Deleted</span>');
*/
    }
};

jQuery.fn.fill_trainer_schedule = function(schedule, url, data, duration_session_seconds, success_callback, fail_callback) {
    console.log('fill schedule', url);
    var $this = $('.availability-container');

    $.getJSON(url, data).done(function(data) {
        data = data.objects;
        // BACKEND DATA
        $.each(data, function(index, item){
            var session = {
                state: item.state,
                slot_start: 'stamp_'+generate_stamp_from_time_slot(item, false),
                slot_end: generate_stamp_from_time_slot(item, true),
                duration_minutes: item.duration_minutes,
                start_secs: item.start_seconds,
                end_secs: item.end_seconds,
                member: item.member_name,
                notes: item.notes,
                session_price: item.session_price || '',
                single_session_price: item.single_session_price,
                subscribe_and_save_price: item.subscribe_and_save_price,
                subscription_discount: item.subscription_discount,
                subscription_price: item.subscription_price,
                id_recurring: item.recurring_id,
                slot_id: item.id,
                next_slot: item.next_slot,
                address: item.address,
                special_instructions: item.special_instructions,
                service: item.service,
                member_phone: item.member_phone,
                member_origin: item.member_origin,
                trainer_payout: item.trainer_payout,
                location: item.location,
            }
            draw_session(session);
        });

        // Find number of FYT sessions booked and display
        sessions_today = $(".availability-column").first().find(".fyt,.fyt-booked").length;
        $("#reserved_total").text(sessions_today);
        success_callback();

    }).fail(function(error){
        fail_callback();
        error = JSON.parse(error.responseText);
        alert(error.message);
    });
    mobileSchedule();
};


/////////////////////////////////////////////////////////////
// Used by FYTMembers for searching available, unbooked sessions.
/////////////////////////////////////////////////////////////
jQuery.fn.search_trainers = function(gymid, geo, resulttime) {
    var tcount = 0,
        containerWidth = $('.container').outerWidth(),
        now = new Date(),
        schedule = $(this),
        nextslot,
        markerlabel = 1,
        trainer_index_array = [],
        data = {
            gym: gymid,
            lat: geo.location.lat(),
            lng: geo.location.lng(),
            s: geo.viewport.getSouthWest().lat(),
            w: geo.viewport.getSouthWest().lng(),
            n: geo.viewport.getNorthEast().lat(),
            e: geo.viewport.getNorthEast().lng()
        };

    $(document).ajaxStart(function(){
        $('#progressouter').show();
    })
    .ajaxStop(function() {
        $('#progressouter').hide();
        javascript:document.getElementById('trainers_n').innerHTML=tcount;
    });

    //We controle from the backend the trainer's slots depend of its Minimum Advance Booking Time.
    now.setDate(now.getDate()-1); // KLUDGE: because of a bug, go back one day
    schedule.find(".schedule_table > .schedule_result:visible").remove();

    //find the next full hour and the last midnight within 7 days
    if (resulttime <= now) {
        nextslot = new Date(now);
        nextslot.setMinutes(60, 0, 0);
    } else nextslot = new Date(resulttime);

    var lastmidnight = new Date(nextslot);
    lastmidnight.setHours(7 * 24);
    var previoustime = new Date(lastmidnight);
    previoustime.setHours(-14 * 24);
    var nexttime = new Date(lastmidnight);
    nexttime.setDay(1);

    if (resulttime >= nextslot){
        $("#slide-back").attr('time', previoustime.getTime()).css("display", "block").text('Prev Week');
    }else{
        $("#slide-back").css("display", "none");
    }

    $("#slide-forth").attr('time', nexttime.getTime()).text('Next Week');
    var columntime = new Date(nextslot);
    schedule.find(".date").each(function() {
        $(this).children(".avai_date").text(columntime.getDate() + " " + Days[columntime.getDay()]);
        columntime.setHours(24);
    });
    columntime = new Date(nextslot);
    schedule.find(".week_day_sessions li").each(function() {
        $(this).attr('date', Days[columntime.getDay()] + ', ' + Months[columntime.getMonth()] + " " + columntime.getDate());
        columntime.setHours(24);
    });
    columntime.setHours(-1);
    $("#date-range").text(Months[nextslot.getMonth()] + " " + nextslot.getDate() + " - " + Months[lastmidnight.getMonth()] + " " + columntime.getDate());

    $.getJSON("/gym/findtrainers/", data, function(data) {

        var first = true,
            zoomcloser = false,
            trainer_profile_url = window.URLS.trainer_profile;

        var locations = {}
        var markerlabels = {}

        var center = map._map.getCenter();
        var bounds = new google.maps.LatLngBounds();

        // always include the original search point to the bounds
        bounds.extend(new google.maps.LatLng(geo.location.lat(), geo.location.lng()))

        // hack over hack: each loc is a trainer
        $.each(data, function(i, loc) {

            if (loc.type !== MY_HOME_OR_BUILDING_GYM) {

                markerlabels[loc.chain+':'+loc.name] = markerlabels[loc.chain+':'+loc.name] || markerlabel++

                locations[loc.chain+':'+loc.name] = {
                    'chain': loc.chain,
                    'name': loc.name,
                    'latitude': loc.latitude,
                    'longitude': loc.longitude,
                    'markerlabel': markerlabels[loc.chain+':'+loc.name],
                    'index': loc.index,
                    'type': loc.type
                }

            }

            $.each(loc.trainers, function(i, trainer) {
                var schedule_result = schedule.find(".hidden_stub").clone(true).removeClass("hidden_stub").appendTo(schedule.children(".schedule_table"));

                var style = get_style_marker(loc.type),
                    css_class = style.css_class,
                    deep_copy_url = trainer_profile_url,
                    trainer_name_container = schedule_result.find('.trainer_name'),
                    trainer_raty_container = schedule_result.find('.trainer_raty_container');

                schedule_result.find('.trainer_num_info').addClass(css_class);
                schedule_result.find('.trainer-location').text(loc.chain + ' - ' + loc.name);
                //schedule_result.find('.club-location').text(loc.name);
                schedule_result.find('.club-address').html(loc.address1 + '<span class="hidden-xs">, ' + loc.city + '</span>');
                trainer_name_container.html("<span class=complete-name>"+ trainer.full_name + "</span>");
                schedule_result.find('.trainer_thumb').attr('src', trainer.profile_img);
                schedule_result.find('.trainer_thumb').attr('alt', trainer.profile_img_alt);
                schedule_result.attr('fullprice', trainer.fullprice);
                schedule_result.attr('data-trainer', trainer.index);
                schedule_result.find('.profile_button').attr('id', 'trainer-' + trainer.index);
                schedule_result.find('.trainer-id').attr('id', 'trainer-' + trainer.index);
                schedule_result.find('.fytapi-fullprice').text(trainer.fullprice);
                schedule_result.find('.fytapi-saved').text(trainer.defaultdiscount);
                schedule_result.find('.single-price').text("$" + trainer.single_session_price + "/hr");

                schedule_result.find('.save-off').text("SAVE " + trainer.subscription_discount + "%")
                .attr("data-off", trainer.subscription_discount);

                deep_copy_url = deep_copy_url.replace('9999', trainer.index);
                schedule_result.find('.trainer-id').attr('trainer-profile-url', deep_copy_url);

                if (loc.type !== MY_HOME_OR_BUILDING_GYM) {
                    schedule_result.find('.trainer_num_info').text("" + markerlabels[loc.chain+':'+loc.name])
                }

                if ( parseInt(trainer.reviews_avg) > 0 ){
                    trainer_raty_container.raty({
                        score: trainer.reviews_avg,
                        readOnly: true,
                        path: window.URLS.raty_image_path,
                        numberMax : 5,
                    });
                }

                if ( trainer.offers_subscription === true ){
                    trainer_index_array.push(trainer.index);
                    schedule_result.find('.subscription-price').text("$" + trainer.session_subscription_price + "/hr");
                }

                // Use the end-users' UTC timestamp to search, but display times in the trainer's timezone.
                // This is done to ensure that we only show time slots which have not yet passed, irrespective
                // of the users' viewing time zone.
                var url = '';
                if ((loc.hour_ranges != [] && loc.hour_ranges != "") ||
                    (loc.price_ranges != [] && loc.price_ranges != "")) {
                    // If an hour or price range is specified, use it. Only show time slots which
                    // haven't yet passed.
                    var hours = 'null',
                        prices = 'null';

                    if (loc.hour_ranges != [] && loc.hour_ranges != "") {
                        hours = loc.hour_ranges.toString();
                    }

                    if (loc.price_ranges != [] && loc.price_ranges != "") {
                        prices = loc.price_ranges.toString();
                    }

                    url = "/api/v1/time_slots/times/UTC/rangelists/state/available/trainer/"
                        + trainer.index + '/'
                        + nextslot.getUTCFullYear() + "-"
                        + leadingZero(nextslot.getUTCMonth()+1) + "-"
                        + leadingZero(nextslot.getUTCDate()) + '/'
                        + lastmidnight.getUTCFullYear() + "-"
                        + leadingZero(lastmidnight.getUTCMonth()+1) + "-"
                        + leadingZero(lastmidnight.getUTCDate()) + '/'
                        + nextslot.getUTCHours() + '/'
                        + nextslot.getUTCMinutes()
                        + '/hours/'
                        + hours
                        + '/prices/'
                        + prices
                        + '/?format=json'
                } else {
                    url = "/api/v1/time_slots/times/UTC/state/available/trainer/"
                        + trainer.index + '/'
                        + nextslot.getUTCFullYear() + "-"
                        + leadingZero(nextslot.getUTCMonth() + 1) + "-"
                        + leadingZero(nextslot.getUTCDate())  + '/'
                        + lastmidnight.getUTCFullYear() + "-"
                        + leadingZero(lastmidnight.getUTCMonth() + 1) + "-"
                        + leadingZero(lastmidnight.getUTCDate()) + '/'
                        + nextslot.getUTCHours() + '/'
                        + lastmidnight.getUTCHours() + '/'
                        + nextslot.getUTCMinutes() + '/'
                        + lastmidnight.getUTCMinutes()
                        + '/?format=json'
                }

                $.getJSON(url, function(data) {
                    data = data.objects;
                    tcount++;
                    if (!data.length) {
                        //schedule_result.remove(); Show empties as well.
                        schedule_result.show();
                        return;
                    }
                    var columntime = new Date(nextslot);
                    var session = data.shift();
                    schedule_result.find(".week_day_sessions").find("li").each(function() {
                        columntime.setHours(24);
                        var daysessions = 0;
                        while (typeof(session) !== 'undefined')
                        // These times won't flutuate with DST changes, since we're getting them from the back end.
                        // Notice no date calculations are done here, except to figure out what time has not yet passed
                        // in the users' time zone.
                        if ( Date.parse(session.start_datetime) < columntime.getTime() ) {
                            // Do not calculate hours or minutes from these timestamps. use what is already
                            // calculated in the session data, to avoid DST issues.
                            var adj_start = adj_hour_minute(session.hour, session.minute),
                                end_hour = session.hour,
                                end_minute = session.minute + session.duration_minutes;

                            if (end_minute >= 60) {
                                end_hour = (end_hour + Math.floor(end_minute / 60)) % 24;
                                end_minute = end_minute % 60;
                            }
                            var adj_end = adj_hour_minute(end_hour,end_minute);
                            $(this).append('<ul class="clasp"><li class="container-session-info"><span session="'
                                + session.id
                                + '" class="session-info" duration="'+session.duration_minutes
                                + '" trainer="' + session.trainer.index
                                + '" start="' + generate_stamp_from_time_slot(session)
                                + '" end="' + generate_stamp_from_time_slot(session, end=true)
                                + '" endtime="' + adj_end[0]+':'+ adj_end[1] + adj_end[2]
                                + '" finalprice="'+ session.discount_amount
                                + '" fullprice="' + session.amount
                                + '" discount="' + session.discount_percentage
                                + '">'
                                + '<button id="start_book" class="btn btn-primary custom-btn">'
                                + adj_start[0] + ":" + adj_start[1] + adj_start[2] + '</button></span></li></ul>');
                            session = data.shift();
                        } else return;
                    });
                    schedule_result.show();
                });
            });

        });

        // add map markers
        $.each(locations, function(idx, loc) {
            // add marker
            map.addLocation(loc.chain + ": " + loc.name, loc.latitude, loc.longitude, loc.markerlabel, loc.index, loc.type);
            // extend map bounds
            bounds.extend(new google.maps.LatLng(loc.latitude, loc.longitude))
        })

        console.log(locations)
        // if there's only one marker, extend bounds a little.
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
           var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
           var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
           bounds.extend(extendPoint1);
           bounds.extend(extendPoint2);
        }

        // fit bounds based on locations
        map._map.fitBounds(bounds)

        // If trainers offer subscription, show the Subscription & Save box
        var array_length = trainer_index_array.length;
        while ( 0 <= array_length ){
            var trainer_schedule_result = $("div[data-trainer="+trainer_index_array[array_length]+"]");
            trainer_schedule_result.find(".offer-subscription").show();
            array_length --;
        }

        new_center = map._map.getCenter();

        // MOBILE ADD DATE
        if (containerWidth <= 723 && $('html').hasClass('touch')) {
            $('.session-date').each(function(){
                $('.week_headings').css('display','none');
                var date = $(this).attr('date');
                $(this).prepend('<h4>'+ date +'</h4>');
            });
        }
    });

};
// TRAINER PROFILE
// This is the function called by the individual trainer profile page.
jQuery.fn.trainer_sessions = function(trainerid, resulttime) {

    var schedule = $(this),
        now = new Date(),
        nextslot;

    $(document).ajaxStart(function(){
        $('#progressouter').show();
    })
    .ajaxStop(function() {
        $('#progressouter').hide();
    });

    $(".session_week:visible").remove();

    //find the next full hour and the last midnight within 7 days
    now.setDate(now.getDate()-1); // KLUDGE: because of a bug, go back one day

    if (resulttime < now){
        resulttime = new Date(now);
        nextslot = new Date(now);
        nextslot.setMinutes(60, 0, 0);
    }else{
        nextslot = new Date(resulttime);
    }

    var firstmidnight = new Date(resulttime);
    firstmidnight.setDay(0);
    firstmidnight.setHours(0, 0, 0, 0);

    var columntime = new Date(firstmidnight);

    // manage next/prev weeks
    while ((columntime.getMonth() + 12 - resulttime.getMonth()) % 11 < 2 || $('.session_week').length <= 4) {
        var new_week = schedule.children('.hidden_stub').clone().removeClass('hidden_stub').appendTo(schedule);
        new_week.find('.date').each(function() {
            $(this).children('.avai_date').text(columntime.getDate() + " " + Days[columntime.getDay()]);
            columntime.setHours(24);
        });
        columntime.setHours(-7 * 24);
        new_week.find('.week_day_sessions li').each(function() {
            $(this).attr('date', Days[columntime.getDay()] + ', ' + Months[columntime.getMonth()] + " " + columntime.getDate());
            columntime.setHours(24);
        });
    }

    // Use the end-users' UTC timestamp to search, but display times in the trainer's timezone.
    // This is done to ensure that we only show time slots which have not yet passed, irrespective
    // of the users' viewing time zone.
    var lastmidnight = new Date(columntime),
        schedule_week = schedule.find(".session_week:not(.hidden_stub)"),
        url = "/api/v1/time_slots/times/UTC/state/available/trainer/"
            + trainerid + '/'
            + nextslot.getUTCFullYear() + "-"
            + leadingZero(nextslot.getUTCMonth()+1) + "-"
            + leadingZero(nextslot.getUTCDate())  + '/'
            + lastmidnight.getUTCFullYear() + "-"
            + leadingZero(lastmidnight.getUTCMonth()+1) + "-"
            + leadingZero(lastmidnight.getUTCDate()) + '/'
            + nextslot.getUTCHours() + '/'
            + lastmidnight.getUTCHours() + '/'
            + nextslot.getUTCMinutes() + '/'
            + lastmidnight.getUTCMinutes()
            + '/?format=json';

    $.getJSON(url, function(data) {
        data = data.objects;
        columntime = new Date(firstmidnight);
        var session = data.shift();
        schedule_week.find(".week_day_sessions").find("li").each(function() {

            columntime.setHours(24);
            var daysessions = 0;

            while (typeof(session) !== 'undefined'){
                // These times won't flutuate with DST changes, since we're getting them from the back end.
                // Notice no date calculations are done here, except to figure out what time has not yet passed
                // in the users' time zone.

                if ( new Date(session.start_datetime) < columntime.getTime() ) {
                    // Do not calculate hours or minutes from these timestamps. use what is already
                    // calculated in the session data, to avoid DST issues.
                    var adj_start = adj_hour_minute(session.hour, session.minute),
                        end_hour = session.hour,
                        end_minute = session.minute + session.duration_minutes;

                    if (end_minute >= 60) {
                        end_hour = (end_hour + Math.floor(end_minute / 60)) % 24;
                        end_minute = end_minute % 60;
                    }

                    var adj_end = adj_hour_minute(end_hour,end_minute);
                    $(this).append('<ul><li class="container-session-info btn"><span  trainer="'+ trainerid
                        + '" class="session-info" duration="'+session.duration_minutes
                        + '" session="' + session.id
                        + '" start="' + generate_stamp_from_time_slot(session)
                        + '" end="' + generate_stamp_from_time_slot(session,end=true)
                        + '" endtime="' + adj_end[0] + ":" + adj_end[1] + adj_end[2]
                        + '" finalprice="' + session.discount_amount
                        + '" fullprice="' + session.amount
                        + '" discount="' + session.discount_percentage
                        + '">'
                        + '<button id="start_book" class="btn btn-primary custom-btn">'
                        + adj_start[0] + ":"
                        + adj_start[1] + adj_start[2]
                        + '</button></span></li></ul>');
                session = data.shift();
                }else{
                    return
                };
            }
        });

        function trainer_profile_mobile(){
            var containerWidth = $('.container').outerWidth();
            if (containerWidth <= 723 && $('html').hasClass('touch')) {
                $('.week_headings').css('display','none');
                $('.day_columns').find('.session-date').each(function(){
                    if($(this).children().length === 0 ){

                    } else {
                        var date = $(this).attr('date');
                        $(this).prepend('<h4>'+ date +'</h4>');
                    }
                });
            }
        }

        trainer_profile_mobile();
    });
};
/////////////////////////////////////////////////////////////////
// Used for populating and updating the trainers' Price Grid
/////////////////////////////////////////////////////////////////
jQuery.fn.updatepricegrid = function(data) {
    grid = $(this);
    defaultdiscount = Math.ceil(Number($("#defaultdiscount").text()));
    fullprice = Math.ceil(Number($("#fullprice").text()));
    defaultprice = Math.ceil(Number($("#discountprice").text()));
    grid.find(".sessionprice").text(defaultprice);
    grid.find(".sessiondiscount").text(defaultdiscount);
    grid.find(".sessiondiscount").attr('oldvalue', defaultdiscount);
    grid.find(".sessionprice").text(Math.ceil(fullprice-(fullprice*defaultdiscount)/100));
    grid.find(".sessionprice").attr('oldvalue', Math.ceil(fullprice-(fullprice*defaultdiscount)/100));
    $.each(data, function(record) {
        // The id is the seconds_key value
        grid.find("#" + data[record].seconds_key).find(".sessionprice").text(Math.ceil(data[record].discount_amount)).attr('oldvalue', Math.ceil(data[record].discount_amount));
        grid.find("#" + data[record].seconds_key).find(".sessiondiscount").text(Math.ceil(data[record].discount_percentage)).attr('oldvalue', Math.ceil(data[record].discount_percentage));
    });
    grid.find('.sessiondiscount').each(function() {
        var thisDiscount = $(this).text();
        if (thisDiscount > defaultdiscount) {
            $(this).parent().parent().parent().addClass('greater-than');
        }
        if (thisDiscount < defaultdiscount) {
            $(this).parent().parent().parent().addClass('less-than');
        }
    });
};
jQuery.fn.setuppricegrid = function(trainerid) {
    var schedule = $(this);
    var timeslot = 0; // seconds since Midnight
    for (var day = 0; day < 7; day++) { // days of one week, starting at Sunday, unlike the rest of the system.
        var day_column = $('<li class="price-column"></li>').appendTo('.price-data');
        for (var hour = 0; hour < 24; hour++) {
            if (timeslot / 3600 % 24 >= 4) //start at 4AM: skip 5 hours until we have scrollbars
            $('<li class="container-data" id="' + timeslot % (3600 * 24 * 7) + '"><ul id="session-data"><li><span class="sessiondiscount" contenteditable="true"></span>%</li><li>$<span class="sessionprice" contenteditable="true"></span></li></ul></li>').appendTo(day_column);
            timeslot += 3600;
        }
    }
    $.getJSON("/api/v1/price_grid/trainer/" + trainerid + "/?format=json", function(data) {
        schedule.updatepricegrid(data.objects);
    });
};