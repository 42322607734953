var requestAvailability = (function(){

    return {
        init: function() {
            $("a.accordion-toggle").click(function(){
                if ( $(this).attr("aria-expanded") === "false" || _.isUndefined($(this).attr("aria-expanded")) ){
                    $(this).children("span").switchClass("glyphicon-plus-sign", "glyphicon-minus-sign");
                }else{
                    $(this).children("span").switchClass("glyphicon-minus-sign", "glyphicon-plus-sign");
                }
            });

            $("#submit-request").click(function(){
                    $("#request-availability").submit();
            });
        }
    }
})()