var Plan = (function() {
  var plans = [];
  if (JSON.parse(localStorage.getItem("booking_data"))) {
    booking_data = JSON.parse(localStorage.getItem("booking_data"));
  };

  function buildPlanBoxes() {
    for (var i = 0; i < plans.length; i++) {
      const plan = plans[i];
      const planBox = $(".plan-box[id="+i+"]");
      $(planBox).attr("data-plan-id", plan.type.id);
      $(planBox).find("h2").text("$" + plan.calculated_price);
    }
    $(".plan-box#1").find("#discount-percent").text(plans[1].type.discount);
    $(".plan-box#2").find("#discount-percent").text(plans[2].type.discount);
  }

  function initialPlanBoxes(time_slot_pk) {
    const currentPlanSelected = booking_data["plan_id"];
    const currentTimeSlot = booking_data["time_slot_id"];
    if (currentTimeSlot===time_slot_pk && currentPlanSelected !== null) {
      $("[data-plan-id="+currentPlanSelected+"]").css("border-color", "#5340ff");
    }
  }

  function handlePlanBox(time_slot_pk, trainer_slug) {
    var planBoxList = $(".plan-boxes").find(".plan-box");
    $(".plan-box").click(function() {
      var id = $(this).data("plan-id");
      booking_data["plan_id"] = id;
      localStorage.setItem("booking_data", JSON.stringify(booking_data));
      for (var j = 0; j < planBoxList.length; j++) {
        const element = planBoxList[j];
        if ($(element).data("plan-id") === id) {
          $(element).css("border-color", "#5340ff");
        } else {
          $(element).css("border-color", "#e8e8e8");
        }
      }
      var baseUrl = window.location.origin + "/fyt/book/booking_step2/payment/" + trainer_slug;
      if (time_slot_pk) window.location.href = baseUrl + "/?time_slot=" + time_slot_pk;
      else window.location.href = baseUrl;
    })
  }

  function getPlans(trainer_pk, time_slot_pk, virtual_only) {
    if (!virtual_only){
      var is_virtual = booking_data.suscriptionData.virtual_session;
      if (booking_data.location_id === 'VIRTUAL') {
        var loc_id = null;
      } else{
        var loc_id = booking_data.location_id;
      }
    } else {
      var loc_id = null;
      var is_virtual = true;
    }
    
    return $.ajax({
      url: "/trainer/"+ trainer_pk +"/subscriptions/price/",
      data: {
        'service_pk': booking_data.service_id,
        'addl_location_pk': loc_id,
        'time_slot': time_slot_pk ? time_slot_pk : null,
        'virtual_session': is_virtual
      },
      success: function(data) {
        var objectState = JSON.parse(localStorage.getItem("objectState"));
        objectState.plans = data;
        localStorage.setItem("objectState", JSON.stringify(objectState));
        plans = data.subscription_prices;
      },
      error: function (error) {
        console.error(error['responseText'])
      }
    })
  }

  function completeIntroPricingSpan() {
    const sessionSpanSet = $(".ip_span_session");
    const priceSpanSet = $(".ip_span_price");
    const IntroPriceSpanSet = $(".intro_price_span_price");
    for (var i=0; i<plans.length; i++) {
      const plan = plans[i];
      const sessionSpan = sessionSpanSet[i];
      const priceSpan = priceSpanSet[i];
      $(sessionSpan).text(plan.type.sessions);
      $(priceSpan).text(plan.calculated_price);
      if(plan.type == "intro_offer"){
        $(IntroPriceSpanSet).text(plan.trainer_intro_price + (2*plan.surcharge))

      }
    }
  }

  function nextButtonHref(timeSlotId, trainerSlug) {
    const btn = $("#ip_next_btn");
    var url = window.location.origin + "/fyt/book/booking_step2/payment/" + trainerSlug;
    if (timeSlotId) {
      url = url + "?time_slot=" + timeSlotId + "&intro_price=true";
    } else {
      url = url + "?intro_price=true";
    }
    $(btn).attr("href", url);
  }

  return {
    init: function(trainer_pk, time_slot_pk, trainer_slug, virtual_only) {
      $.when(getPlans(trainer_pk, time_slot_pk, virtual_only)).done(function() {
        buildPlanBoxes();
        initialPlanBoxes(time_slot_pk);
        handlePlanBox(time_slot_pk, trainer_slug);
      })
    },
    introPrice: function(trainer_pk, time_slot_pk, trainer_slug) {
      $.when(getPlans(trainer_pk, time_slot_pk)).done(function() {
        completeIntroPricingSpan();
        nextButtonHref(time_slot_pk, trainer_slug);
      })
    }
  }
})();