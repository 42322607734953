/*globals
    jQuery,
    _,
*/

var dataPopup = function () {

    return {
        init: function (params) {
            var target = params.target;
            var modal_selector = params.modal_selector || "#new-message-modal";
            var template = params.template;
            var data_callback = params.data;

            $(target).on('click', {}, function (e) {
                e.preventDefault();

                var render = function(data) {
                    var html = $(template).html()
                    var content = _.template(html)(data);
                    $(modal_selector + " .modal-content").html(content);
                    $(modal_selector).modal("show");
                }

                if (typeof data_callback === "function") {
                    var req_params = $(this).data();
                    data_callback(req_params, render);
                } else {
                    var data = $(this).data();
                    render(data);
                }

            });
        }
    };

};