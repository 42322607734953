var CardHandler = (function () {
  var cardIsCharged = false;

  function displayPaymentDiv(div, data) {
    $("#payment_data").css("display", "flex");
    var span = "<span></span>"
    const star = $(span).css("font-size", "12px").text(" ∗∗∗∗∗∗∗∗∗∗∗∗");
    $(div).append([
      $(span).addClass("bolder").text("Card Details"),
      $(span).addClass("normal").append([data.brand, star, data.last4]),
      $(span).addClass("normal").text(data.exp_month + "/" + data.exp_year),
      $('<a data-toggle="modal" data-target="#editCard_modal"></a>').text("Edit")
    ])
  }

  function getCard() {
    var dataSent = null;
    const paymentDataDiv = $("#payment_data");
    $("#booking-spinner").show();
    BookingSpinner(document.getElementById("booking-spinner"));
    return $.ajax({
      url: "/fyt/book/member/payment/",
      beforeSend: function() {
        BookingSpinner.show();
        $(paymentDataDiv).hide();
      },
      success: function (data) {
        if (data.active_card) {
          $(paymentDataDiv).empty();
          $(".btn-payment").attr("disabled", false);
          inputs = $("#editCard_modal").find("input");
          cardIsCharged = true;
          dataSent = data;
          setTimeout(function() {
            BookingSpinner.hide();
            displayPaymentDiv($("#payment_data"), dataSent.active_card);
            $("#booking-spinner").hide();
          }, 1000);
        } else {
          setTimeout(function() {
            BookingSpinner.hide();
            $(paymentDataDiv).show();
            $("#booking-spinner").hide();
          }, 1000);
        }
      },
      error: function (error) {
      },
    });
  }

  function showSpinner() {
    $("#booking-spinner").show();
    BookingSpinner(document.getElementById("booking-spinner"));
    $(".btn-payment").attr("disabled", true);
    $("#save_card_data").attr("disabled", true);
    BookingSpinner.show();
  }

  function hideSpinner() {
    BookingSpinner.hide();
    $("#booking-spinner").hide();
    $(".btn-payment").attr("disabled", false);
    $("#save_card_data").attr("disabled", false);
  }

  function initStripe(stripe) {
    var elements = stripe.elements();

    var style = {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
            color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    };

    var card = elements.create('card', {style: style});

    card.mount('#card-element');

    return card;
  }

  function loadCard(stripe, card, introPrice, trainer_pk, trainer_slug, time_slot_pk, csrf_token) {
    $(".error-box").empty();
    return stripe.createToken(card).then(function(result) {
      if (result) {
        if (result.error) {
          $(".error-box").text(result.error.message);
        } else {
          showSpinner();
          $.ajax({
            url: "/fyt/book/member/payment/",
            method: "POST",
            headers:{"X-CSRFToken": csrf_token},
            data: {
              stripe_token_id: result.token.id
            },
            success: function(response) {
              console.log("Success!");
              if (response) {
                $("#payment_data").empty();
                displayPaymentDiv($("#payment_data"), response);
              }
              if (response.error) {
                console.log(response.error);
                $(".error-box").text(response.error);
              }
            },
            error: function(error) {
              console.log("Error!", error);
              $(".error-box").text(error.responseJSON[0]);
              hideSpinner();
            },
          }).done(function() {
            if (csrf_token) {
              Payment.handlePayment(trainer_pk, trainer_slug, time_slot_pk, csrf_token, introPrice);
            }
            hideSpinner();
          });
        }
      };
    });
  }

  return {
    init: function (trainer_pk, trainer_slug, time_slot_pk, csrf_token, stripe, introPrice) {
      $.when(getCard()).done(function() {
        var cardElement = initStripe(stripe);
        // Handles behavior on update card info
        $("#save_card_data").click(function () {
          loadCard(stripe, cardElement, introPrice).then(function() {
            $('#editCard_modal').modal('hide');
          });
        });
        // Handle behavior on payment
        $(".btn-payment").click(function() {
          if (!cardIsCharged) {
            loadCard(stripe, cardElement, introPrice, trainer_pk, trainer_slug, time_slot_pk, csrf_token);
          } else {
            Payment.handlePayment(trainer_pk, trainer_slug, time_slot_pk, csrf_token, introPrice);
          };
          try{
            var currentTotal = $("#total_price").text();
            currentTotal = currentTotal.substring(1, currentTotal.length);
            ttq.track('CompletePayment',{  
              content_id: trainer_pk.toString(),
              quantity: 1,
              value: parseFloat(currentTotal),
              currency: 'USD',})
          } catch (error) {
            console.error(error);
          }
        });

      })
    },
  };
})();
