var quizValidation = (function() {
    var answernum = 6;
    var count = 0;
    var percent = 0;
    var spinnerOpts = {
        lines: 13 // The number of lines to draw
            ,
        length: 9 // The length of each line
            ,
        width: 14 // The line thickness
            ,
        radius: 42 // The radius of the inner circle
            ,
        scale: 0.30 // Scales overall size of the spinner
            ,
        corners: 1 // Corner roundness (0..1)
            ,
        color: '#000' // #rgb or #rrggbb or array of colors
            ,
        opacity: 0.25 // Opacity of the lines
            ,
        rotate: 0 // The rotation offset
            ,
        direction: 1 // 1: clockwise, -1: counterclockwise
            ,
        speed: 1 // Rounds per second
            ,
        trail: 60 // Afterglow percentage
            ,
        fps: 20 // Frames per second when using setTimeout() as a fallback for CSS
            ,
        zIndex: 2e9 // The z-index (defaults to 2000000000)
            ,
        className: 'spinner' // The CSS class to assign to the spinner
            ,
        top: '12.5%' // Top position relative to parent
            ,
        left: '50%' // Left position relative to parent
            ,
        shadow: false // Whether to render a shadow
            ,
        hwaccel: false // Whether to use hardware acceleration
            ,
        position: 'absolute' // Element positioning
    }

    var formUrl,debug;

    function showTooltip() {
        var tooltip = $('input[name=your-location]');
        tooltip.tooltipster({
            theme: 'tooltipster-shadow',
            contentCloning: true,
            trigger: 'click',
            side: 'right',
        });
        tooltip.tooltipster('open');
    }

    function deleteTooltip() {
        var tooltip = $('input[name=your-location]');
        try {
            tooltip.tooltipster('destroy');
        } catch (err) {
            console.log('destroying unitialized tooltip');
        }
    }

    function showSpinner(id) {
        var target = document.getElementById(id);
        var spinner = new Spinner(spinnerOpts).spin(target);
    }

    function deleteSpinner(id) {
        $(document.getElementById(id)).html('');
    }

    function send_quiz_response() {
        var $form = $("#form-quiz"),
            submited = $form.attr("data-submited");

        if (submited !== "true") {
            $form.attr("data-submited", "true");
            /*$form.submit();*/
            $('.close-modal-x').attr("disabled", "true");
            $.ajax({ // create an AJAX call...
                data: $form.serialize(), // get the form data
                type: $form.attr('method'), // GET or POST
                url: formUrl, // the file to call
                success: function(response) { // on success..
                    if (response['status'] == 'success') {
                        if (debug) {
                            top.location = '/match/view-matches/' + document.location.search
                        }else {
                                dataLayer.push({
                                'event': 'email.capture',
                                'source': 'quiz',
                                'eventCallback': function() {
                                window.top.location = '/match/view-matches/' + document.location.search
                            }
                        });
                        }


                    } else {
                        // window.location = '/find-personal-trainers-near-me/'
                    }
                },
                error: function(request, status, error) {
                    $('.field-error').html(error);
                    $('.field-error').removeClass('hidden');
                    deleteSpinner('spinner2');
                    $form.attr("data-submited", "false");
                    $('.close-modal-x').removeAttr("disabled");
                    $('.buttons-container').removeClass('hidden');
                }
            });
        }

    };

    function submit_questions() {
        var question_responses = {};

        var radios_checked = $("input[type=radio]:checked");
        radios_checked.each(function(index, element) {
            var e = $(element),
                val = e.val();

            question_text = e.attr("data-question-title");
            response_text = $("div[data-option-id=" + val + "]").text();

            question_responses[question_text] = response_text;

        });

        var checkboxs_checked = $("input[type=checkbox]:checked");
        checkboxs_checked.each(function(index, element) {
            var val = $(element).val();
            question_text = $(element).attr("data-question-title");

            response = $("div[data-option-id=" + val + "]");

            if (response.length) {
                var text = response.text();
            } else {
                var text = $(this).parent().text();
            }

            if (question_responses.hasOwnProperty(question_text)) {
                question_responses[question_text] += "; " + text;
            } else {
                question_responses[question_text] = text;
            }

        });

        var selects = $("select option:selected");
        selects.each(function(index, element) {

            var e = $(element),
                response = e.text(),
                question = e.parent().attr("data-question-title");

            question_responses[question] = response;
        });

        var text_areas = $("textarea");
        text_areas.each(function(index, element) {

            var e = $(element),
                response = e.val();

            if (response) {
                question = e.attr("data-question-title");
                question_responses[question] = response;
            }
        });

        var inputs_text = $("input[type=text]");
        inputs_text.each(function(index, element) {
            var response = $(element).val();
            if (response) {
                question_text = $(element).attr("data-question-title");
                question_responses[question_text] = response;
            }
        });

        var table_body = $("#table-answers tbody"),
            responses_modal = $("#responses-modal");
        responses_modal.find(".modal-header").css("background-color", "#464646");

        table_body.empty();

        for (key in question_responses) {
            var row = "<tr><td>" + key + "</td><td>" + question_responses[key] + "</td></tr>";
            table_body.append(row);
        }

        send_quiz_response();
    }

    return {
        init: function(params) {

            var steps = params.steps;
            var visitedSteps = [];
            formUrl = params.formUrl;
            debug= params.debug;

            var visitedSteps = [];

            var isMobile = $(window).width() <= 767;

            function updateProgressBar() {

                var title = $('#carousel-example-generic .item.active').find('.question-title').text();

                if ((visitedSteps.indexOf(title) < 0) && (steps.indexOf(title) >= 0)) {
                    visitedSteps.push(title);
                    var currentStageIndex = steps.indexOf(title);
                    var statusBar = document.getElementsByClassName("status-bar")[0];
                    var currentStatus = document.getElementsByClassName("current-status")[0];

                    /*console.log('width', currentStageIndex);*/
                    var width = 14 * currentStageIndex;

                    setTimeout(function() {
                        /*currentStatus.style.width = (100 * currentStageIndex) / (steps.length - 1) + '%';*/
                        currentStatus.style.width = width + '%';
                        currentStatus.style.marginLeft = '22%';
                        currentStatus.style.transition = 'width ' + (currentStageIndex * ProgressBar.singleStepAnimation) + 'ms linear';
                    }, 200);

                    statusBar.appendChild(currentStatus);

                    var animationDelay = 100;
                    for (var index = 0; index < $('li.section').length; index++) {
                        var li = $('li.section')[index];
                        if (currentStageIndex >= index) {
                            setTimeout(function(li, currentStageIndex, index) {
                                li.className += (currentStageIndex > index) ? ' visited' : ' visited current';
                            }, animationDelay, li, currentStageIndex, index);
                            animationDelay += ProgressBar.singleStepAnimation;
                        }
                    }
                }
            }

            function centerOptions(key) {
                var $currentItem = $($('#carousel-example-generic .carousel-inner .item')[key]);
                if ($currentItem.find('.five').not('.centered').length > 0) {
                    var fiveWidth = $currentItem.find('.five').width();
                    var fiveOptionsWidth = (90 * 3) + (4 * 3);
                    var fiveRest = fiveWidth - fiveOptionsWidth;
                    // $currentItem.find('.five').css({ 'padding-left': (fiveRest / 2) - 1 });
                    $currentItem.find('.five').addClass('centered');
                } else if ($currentItem.find('.four').not('.centered').length > 0) {
                    var fourWidth = $currentItem.find('.four').width();
                    var fourOptionsWidth = (90 * 2) + (40 * 2);
                    var fourRest = fourWidth - fourOptionsWidth;
                    // $currentItem.find('.four').css({ 'margin-left': (fourRest / 2) - 1, 'margin-right': (fourRest / 2) - 1 });
                    $currentItem.find('.four').addClass('centered');
                } else if ($currentItem.find('.three').not('.centered').length > 0) {
                    var threeWidth = $currentItem.find('.three').width();
                    var threeOptionsWidth = (90 * 3) + (6 * 3);
                    var threeRest = threeWidth - threeOptionsWidth;
                    // $currentItem.find('.three').css({ 'margin-left': (threeRest / 2) - 1, 'margin-right': (threeRest / 2) - 1 });
                    $currentItem.find('.three').addClass('centered');
                }
            }

            var $allQuestions = $('.item .question');

            $('.modal#quiz-modal').on('shown.bs.modal', function(e) {
                if (isMobile) {
                    var $currentQuestion = $('.item.active .question');
                    centerOptions(0);
                }
            })

            function goNextQuestion($currentQuestion) {
                var indexOf = $('.question').not('.hidden').index($currentQuestion);
                var nextQuestion = $('.question').not('.hidden')[indexOf + 1];
                var key;
                
                $('#carousel-example-generic .item').each(function(index) {
                    if ($(this).has($(nextQuestion)).length) {
                        key = index;
                    }
                });
                
                $('#carousel-example-generic').carousel(key);
                $('.back').removeClass('hidden');
                if (isMobile) { centerOptions(key) }

                if ((key + 1) == ($('#carousel-example-generic .item').length)) {
                    $("#handle-quiz-btn")
                        .addClass("btn-custom-full")
                        .removeClass("btn-custom")
                        .text("View my matches");
                        try{
                            document.getElementById("handle-quiz-btn").onclick = function(){
                                ttq.track('CompleteRegistration')
                            }
                        } catch (error) {
                            console.log(error)
                        }
                        
                        
                    $('#question-37').after($('#question-35'));

                    setTimeout(function() {
                        $('.next').html('View my matches');
                        $('.next').addClass('submit');
                        $('#carousel-example-generic .item .question').removeClass('hidden');
                    }, 2400);
                } else {
                    $("#handle-quiz-btn")
                        .addClass("btn-custom")
                        .removeClass("btn-custom-full")
                        .text("Next")            
                }

            }

            $('#quiz-modal .next').on('click', function() {
                var $currentQuestion = $('.item.active .question');
                if (true) {
                    //this is because in mobile question doesn't fit
                    if (($currentQuestion.attr('id') == 'question-20') && ($('#question-22').hasClass('hidden'))) {
                        if (validateQuestion($currentQuestion)) {
                            $('#question-20').find('.four').addClass('hidden');
                            $('#question-21').removeClass('hidden');
                            $('#question-22').removeClass('hidden');
                            $('#question-24').removeClass('hidden');
                            $('#question-20 .question-title').html("Tell Us A Little About Yourself");
                        }
                        return;
                    }
                }
                if (validateQuestion($('.item.active .question'))) {
                    count++;
                    barProgress()

                    $('.field-error').addClass('hidden');
                    if ($('.next').hasClass('submit')) {
                        $('.buttons-container').addClass('hidden');
                        showSpinner('spinner2');
                        $('.last-spinner').removeClass('hidden');
                        submit_questions();
                    } else {
                        if ($('.item.active .question').find('#question-24').length > 0) {

                            $('.zipcode-error').remove();
                            var input = $('#question-24').find('input[type=text]');

                            $.when(zipcodeValidation()).done(function(response) {
                                if (response['is_valid'] == true) {
                                    goNextQuestion($currentQuestion);
                                } else {
                                    var error = '<p class="text-center zipcode-error" style="color:red; margin-top:20px">Please enter a valid zipcode.</p>';
                                    input.after(error);
                                }
                            });

                            function zipcodeValidation() {
                                var data = { 'zipcode': input.val() };
                                return $.ajax({
                                    data: data,
                                    type: 'GET',
                                    url: '/validate-zipcode/',
                                    success: function(response) {
                                        if (response['is_valid'] == true) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                });
                            }

                        } else {

                            goNextQuestion($currentQuestion);

                        }
                    }
                } else {
                    $('.field-error span').text('This field is required');
                    $('.item.active .question .option-can-check').css('border', '2px solid red');
                    $('.item.active .question input[type="text"]').css('border', '2px solid red');
                    $('.field-error').removeClass('hidden');

                    setTimeout(function() {
                        $('.item.active .question .option-can-check').css('border', 'none');
                        $('.item.active .question input[type="text"]').css('border', 'none');
                        $('.field-error').addClass('hidden');
                    }, 4000);
                }
            });

            $('#quiz-modal .back').on('click', function() {
                if ((key + 1) !== ($('#carousel-example-generic .item').length)) {
                    $("#handle-quiz-btn")
                        .addClass("btn-custom")
                        .removeClass("btn-custom-full")
                        .text("Next")
                        .removeClass('submit');
                }

                $('.field-error').addClass('hidden');
                $('.next').html('Next');
                $('.next').removeClass('submit');
                deleteSpinner('spinner1');

                var $currentQuestion = $('.item.active .question');
                if (isMobile) {
                    if (($currentQuestion.attr('id') == 'question-20') && ($('#question-20').find('.four').hasClass('hidden'))) {
                        $('#question-21').addClass('hidden');
                        $('#question-22').addClass('hidden');
                        $('#question-24').addClass('hidden');
                        $('#question-20').find('.four').removeClass('hidden');
                        $('#question-20 .question-title').html("How Active Are You Now?");

                        return;
                    }
                }
                var indexOf = $('.question').not('.hidden').index($currentQuestion);
                var nextQuestion = $('.question').not('.hidden')[indexOf - 1];
                var key;
                $('#carousel-example-generic .item').each(function(index) {
                    if ($(this).has($(nextQuestion)).length) {
                        key = index;
                    }
                });
                if (key == 0) {
                    $('.back').addClass('hidden');
                }
                if (key >= 0) {
                    $('#carousel-example-generic').carousel(key);
                }

                $('.item .question .option-can-check').css('border', 'none');
                $('.item .question input[type="text"]').css('border', 'none');
                $('.field-error').addClass('hidden');

                count--;
                barProgress()
            })
            

            function validateQuestion(question) {

                var question = $(question);

                /*if question is no required and has no childs*/
                if ((!isRequired(question)) && (!hasChilds(question))) {
                    return true;
                } else if (!isRequired(question) || ((isRequired(question)) && (validChoice(question)))) {
                    if (hasChilds(question) == true) {
                        var questionChilds = childs(question);
                        for (var i = questionChilds.length - 1; i >= 0; i--) {
                            if (!validateQuestion(questionChilds[i])) {
                                return false;
                            }
                        }
                        // every child is valid
                        return true;
                    } else {
                        // not required or required with answer and no childs
                        return true;
                    }
                } else {
                    return false;
                }
            }

            function isRequired(question) {
                return question.attr('required_field') == 'required';
            }

            function childs(question) {
                return question.find('.child-question').not('.hidden');
            }

            function hasChilds(question) {
                return childs(question).length > 0;
            }

            function validChoice(question) {
                if (question.hasClass('radio-button')) {
                    return $('.option-checked').length > 0;
                }
                if (question.hasClass('block')) {
                    return true;
                }
                if (question.hasClass('select')) {
                    if (question.find('select option:selected').val()) {
                        return true;
                    }
                    return false;
                }
                if (question.hasClass('input-text')) {
                    var input = question.find('input[type=text]');
                    if (input.attr('name') == 'email-address') {
                        return validateEmail(input.val());
                    }
                    /*if (input.attr('name') == 'your-location') {
                        var place = autocomplete.getPlace() || {};
                        if ("geometry" in place) {
                            return true;
                        } else {
                            showTooltip();
                            return false;
                        }
                    }*/
                    if ((input.val()) && (input.val() != "")) {
                        return true;
                    }
                    return false;
                    /*for now*/
                }
                if (question.hasClass('checkbox')) {
                    return question.find('input[type=checkbox]:checked').length > 0;
                }
                return false;
            }

            function validateEmail($email) {
                if ($email == '') {
                    return false;
                }
                var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
                return emailReg.test($email);
            }
            
            function barProgress() {
                percent = (count*100)/answernum;
                $('.progress-bar-success').css("width",percent+"%");
            }

            /* function inAnimation() {
                var text =[
                    'GOALS', 'TRAINER PREFERENCES', ' LOCATION', 'AVAILABILITY'
                ];
                var color =[
                    'e6c5ea',
                    '#e6c5ea',
                    '#c0f9a0',
                    '#a1fbfa'

                ];
                // function remove(e, time) {
                //     setTimeout(function(){
                //         e.removeClass("out")
                //     }, time)
                // }
                $(".item-animated").each(function(i, e){
                     $('.item-animated').removeClass('out');
                    changeT(i);
                });

                function removeHidden(){
                    $('.pre-result-foot').removeClass('hdn');
                }

                $('.final-load').show();
                function changeT(i) {
                    setTimeout(function () {
                     $('.changing-text').html(text[i]).css('color',color[i]);
                     if (text[i] =='AVAILABILITY'){
                         $('.progress-bar-success').css("width","100%");
                         removeT(i);
                         removeHidden()
                     }
                },i*1000)

                }
                function removeT(i){
                    setTimeout(function () {
                        $('.changing-text').hide();
                        $('.fctext').html('MATCHES FOUND!').css('width', '100%');
                        $('.loader').hide()
                        $('.last-spinner').addClass('centerSpiner');

                    }, 1500)
                }
            } */

        }
    };
})();