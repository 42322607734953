/*
Trainer Calendars (reusing code from the previous Trainer Profile calendar)
*/
var CalendarManager = (function($, is) {

    // TrainerCalendar instances
    var calendars = {},
        start, end;

    // DOM cache
    var td_header_template = $("#td-availability-header"),
        td_body_template = $("#td-availability-body"),
        li_session_template = $("#li-session"),
        ul_mobile_content = $(".session-list-mobile"),
        td_width,

        //mobile
        title_popup_mobile = $("#day-mobile"),
        sessions_modal_mobile = $("#sessions-modal-mobile");

    var options,
        defaultOptions = {
            time_span: 4,
            debug: false,
            hideOnMobile: false,
            $dom: {
                loading: $(".preloader")
            },
            selectors: {
                open_calendar: '.open-calendar',
                header: 'tr.day-header',
                next: '.calendar-next',
                prev: '.calendar-prev',
                td_date_session_info: 'td.date-session-info',
                ul_session_list: 'ul.session-list',
                trainer_availability: '.trainer-availability'
            },
            data: []
        }

    // sessions endpoint
    var trainer_sessions_by_category_url = window.URLS.trainer_get_sessions_by_category;
    // calendars endpoint
    var availability_url = window.URLS.trainers_get_availability;

    // flag to check if the first session list was opened
    var firstSessionOpened = false;

    /*
     * Trainer Calendar prototype
     */
    var TrainerCalendar = function (params){
        var container = $(params.container);

        this.trainer_pk = params.trainer_pk;
        this.availability = params.availability || [];

        this.$dom = {
            container: container,
            ul_session_list: container.find("ul_session_list"),
            TR_MAPPER: {
                "Morning": container.find(".morning"),
                "Day": container.find(".afternoom"),
                "Night": container.find(".night")
            }
        }

        // show session callback
        var show_sessions_cb = is.mobile() ? this.show_sessions_for_mobile : this.show_sessions;

        // sessions click event
        this.$dom.container.on("click", options.selectors.td_date_session_info, show_sessions_cb.bind(this));

        this.trainer_sessions_by_category_url = trainer_sessions_by_category_url.replace("9999", this.trainer_pk);
        this.populate_trainer_availability(params);

        return this;
    }

    TrainerCalendar.prototype.generate_booking_url = function(session) {
        var start = generate_stamp_from_time_slot(session),
            end = generate_stamp_from_time_slot(session, true),
            session_id = session.id,
            url = "/fyt/book/booking_step1/";

         return url + "?time_slot="+session_id+"&start="+start+"&end="+end;
    }

    TrainerCalendar.prototype.reset_table_availability = function() {
        _.each(this.$dom.TR_MAPPER, function(value, key){
            value.empty();
        })
    }

    TrainerCalendar.prototype.populate_trainer_availability = function(params) {
        var self = this;

        // if trainer_availability isn't provided, we get it by ajax
        if (typeof params.availability === "undefined") {
            this.get_availability(params.trainer_pk, this.render.bind(this))
        } else {
            this.render(params.availability);
        }
    }

    TrainerCalendar.prototype.get_availability = function(trainer_pk, callback) {
        if (options.debug) {
            console.log('populating availability for:', trainer_pk.toString())
        }
        // the endpoint expect a list of trainer pks
        $.ajax({
            url: availability_url,
            data: {
                start_date: start.apiFormat(),
                end_date: end.apiFormat(),
                trainer_pk: [trainer_pk]
            }
        })
        .done(function(trainers_availability) {
            var availability = trainers_availability[trainer_pk];
            callback(availability);
        })
    }

    TrainerCalendar.prototype.render = function(availability) {
        var self = this;

        _.each(availability, function(element, index, list){

            var availability_object = list[index],
                total_sessions = availability_object.total_sessions;

            _.each(total_sessions, function(value, key){
                var data_td_body = {
                        time_category: key,
                        total_sessions: value,
                        date: availability_object.date,
                        weekday: availability_object.weekday,
                        extra_class: ""
                    }

                var tr = self.$dom.TR_MAPPER[key];

                if ( value === 0 || availability_object.is_old ){
                    data_td_body.extra_class = "empty-day";
                }

                var body_td_content = _.template(td_body_template.html())(data_td_body);
                tr.append(body_td_content);
            })
        })
        // by default open the first cell in the schedule table with availability
        if ( is.desktop() && !firstSessionOpened ) {
            $("td.date-session-info").filter(":not(.empty-day)").first().click();
            firstSessionOpened = true;
        }
    }

    TrainerCalendar.prototype.show_sessions = function(event) {
        var self = this; // the TrainerCalendar instance
        var _this = $(event.currentTarget); // the event target

        if ( !_this.hasClass("empty-day") ){
            var date = _this.data("day"),
                time_category = _this.data("time-category"),
                ul_content = _this.children("ul"),
                total_session = _this.children(".total-sessions"),
                all_total_sessions = $(".total-sessions"),
                close_icon = $(".close-sessions"),
                close_current_sessions_button = _this.children(".sessions-time").find(".close-sessions");

            function complete_task(){
                ul_content.show();
                close_current_sessions_button.addClass("open-icon").show();
                total_session.hide();
                _this.attr("data-displayed", true)
                .attr("data-has-been-populated", true);
            }

            close_icon.hide().removeClass("open-icon");
            $(options.selectors.ul_session_list).hide();
            all_total_sessions.show();

            // set list width
            $(options.selectors.ul_session_list).css('width', td_width)

            if ( _this.attr("data-displayed") === "true" ){
                ul_content.hide();
                _this.attr("data-displayed", false);
                close_current_sessions_button.css("display", "none");
                return;
            }

            // reset all displayed flags
            $(options.selectors.td_date_session_info).attr("data-displayed", false)

            //to avoid multiple ajax request for the same day for a specific category.
            if ( _this.attr("data-has-been-populated") === "false" ){
                $.ajax({
                     url: self.trainer_sessions_by_category_url,
                     data: {
                        date: date,
                        time_category: time_category
                      }
                })
                .done(function(session_list){
                    _.each(session_list, function(element, index, list){
                        var adj_start = adj_hour_minute(element.hour, element.minute),
                            url_for_booking = self.generate_booking_url(element),
                            data_session = {
                                session_hour: adj_start[0],
                                session_minute: adj_start[1],
                                session_format: adj_start[2],
                                booking_url: url_for_booking
                            };

                        session = _.template(li_session_template.html())(data_session);
                        ul_content.append(session);
                    })
                    complete_task();
                })
                .fail(function(){
                    console.log("FAIL")
                })
            } else {
                complete_task();
            }
        }
    }

    TrainerCalendar.prototype.show_sessions_for_mobile = function(event){

        var self = this; // the TrainerCalendar instance
        var _this = $(event.currentTarget); // the event target

        var date_str = _this.data("day"),
            time_category = _this.data("time-category"),
            date_obj = new Date(date_str);

        ul_mobile_content.empty();

        if ( !_this.hasClass("empty-day") ){

            $.ajax({
                url: self.trainer_sessions_by_category_url,
                data: {
                    date: date_str,
                    time_category: time_category
                }
            })
            .done(function(session_list){

                var date_json = self.date_to_str_mobile(date_obj),
                    text = date_json.day_name + " " + date_json.month + " " + date_json.day_number;

                title_popup_mobile.text(text);

                _.each(session_list, function(element, index, list){
                    var adj_start = adj_hour_minute(element.hour, element.minute),
                        url_for_booking = self.generate_booking_url(element),
                        data_session = {
                            session_hour: adj_start[0],
                            session_minute: adj_start[1],
                            session_format: adj_start[2],
                            booking_url: url_for_booking
                        };

                    session = _.template(li_session_template.html())(data_session);
                    ul_mobile_content.append(session);
                });

                sessions_modal_mobile.modal();
            })
            .fail(function(){
                console.log("FAIL")
            })
        }
    }

    TrainerCalendar.prototype.date_to_str_mobile = function(date){
        // time string manipulation
        var date_str = date.toDateString();

        date_str = date_str.split(" ");

        return {
            day_name: date_str[0],
            month: date_str[1],
            day_number: date_str[2]
        }
    }

    TrainerCalendar.prototype.update = function(availability) {
        if (availability) {
            this.availability = availability
        }
        this.reset_table_availability()
        this.render(this.availability)
    }

    /* ------------------------------------------------------------------- */

    function initialize_paginator(start_str, end_str){
        if (options.debug) console.log('initilizing paginator: ', start_str, ' - ', end_str)
        update_paginator(start_str, end_str);
        $(document.body).on("click", options.selectors.prev, prev);
        $(document.body).on("click", options.selectors.next, next);
    }

    function update_paginator(start_str, end_str) {
        if (options.debug) console.log('updating paginator: ', start_str, ' - ', end_str)
        $(options.selectors.prev).each(function() {
            $(this).attr('data-date', start_str);
        })
        $(options.selectors.next).each(function() {
            $(this).attr('data-date', end_str);
        })
    }

    function update_headers() {
        if (options.debug) console.log('updating calendar headers')
        $headers = $(options.selectors.header);

        $.each($headers, function() {
            $(this).empty();

            for (var day = new Date(start); day < end; day.addDays(1)) {

                // ex. Fri Jan 15 2016
                var date = day.toDateString().split(' ');

                var data = {
                    day_name: date[0].toUpperCase(),
                    month_name: date[1].toUpperCase(),
                    day_number: date[2]
                }

                var html = td_header_template.html()
                var header_td_content = _.template(html)(data);
                $(this).append(header_td_content);
            }

            $(this).children('td').css('width', td_width)
        })
    }

    function next(evt) {
        evt.preventDefault();

        var date_str = $(evt.target).attr('data-date');

        // update global start/end
        start = new Date(date_str);
        end = new Date(date_str).addDays(options.time_span);

        var start_str = start.apiFormat(),
            end_str = end.apiFormat();

        // trigger date shift
        dateshift(start_str, end_str);
    }

    function prev(evt) {
        evt.preventDefault();

        var yesterday = new Date().addDays(-1);
        var date_str = $(evt.target).attr('data-date');

        var _end = new Date(date_str);
        var _start = new Date(date_str).addDays(-options.time_span);

        // check if start is greater than yesterday
        if (_start >= yesterday) {

            // update global start/end
            start = _start;
            end = _end;

            var end_str = end.apiFormat(),
                start_str = start.apiFormat();

            // trigger date shift
            dateshift(start_str, end_str);
        }
    }

    function dateshift(start_str, end_str) {
        // update paginator
        update_paginator(start_str, end_str);

        var trainers_pk = $.map(calendars, function(item, pk) { return pk } );

        var data = {
            start_date: start_str,
            end_date: end_str,
            trainer_pk: trainers_pk
        }

        loading(true);

        $.getJSON(availability_url, data, function(response) {
            updateTrainerCalendars(response); loading(false);
        })

        // update headers
        update_headers();
    }

    function addTrainerCalendar(params) {
        /* ex.
        * params = {
        *   trainer_pk: 23,
        *   availability: { availability object },
        *   container: containerEl
        } */
        calendars[params.trainer_pk] = new TrainerCalendar(params)
    }

    function updateTrainerCalendars(trainers_availability) {
        $.each(trainers_availability, function(pk, availability) {
            calendars[pk].update(availability);
        })
    }

    function openCalendarBtn() {
        // Book a Time opens the trainer calendar.
        $(document.body).on('click', options.selectors.open_calendar, function(evt) {
            evt.preventDefault();

            var $btn = $(evt.target);
            var pk = $btn.data('trainer-pk')
            var $container = calendars[pk].$dom.container;

            // show all "Book a Time" buttons.
            $(options.selectors.open_calendar).show();

            // hide the target button
            $btn.hide()

            // hide all calendars
            $(options.selectors.trainer_availability).hide();

            // show the target calendar
            //$(options.selectors.trainer_availability +'[data-trainer-pk='+pk+']').show();
            $container.show();
            $(document.body).scrollTop($container.position().top)

            // update calendar headers and paginator
            update_paginator(start.apiFormat(), end.apiFormat());
            update_headers();
        })

    }

    function loading(isLoading) {
        if (isLoading) {
            options.$dom.loading.show();
        } else {
            options.$dom.loading.hide();
        }
    }

    function init(opts) {
        var self = this;
        options = $.extend(defaultOptions, opts)

        // relative TD width based on time span
        td_width = (100 / options.time_span).toString() + "%";

        // set global start/end
        start = new Date();
        end = new Date().addDays(options.time_span);

        // update shared calendar header
        // based on global start/end
        update_headers()

        // set paginator dates
        initialize_paginator(start.apiFormat(), end.apiFormat());

        // populate data if any
        $.each(options.data, function(idx, item) {
            addTrainerCalendar(item)
        })

        // Book a Time button click handler
        //openCalendarBtn()
    }

    return {
        init: init,
        calendars: calendars,
        add: addTrainerCalendar,
        next: next,
        prev: prev
    }

})(jQuery, is)