/*globals
    bootbox,
*/

var askBefore = (function (bootbox) {

    var confirmed;

    function clickHandler(e) {
        var _this = this;
        if (confirmed) {
            confirmed = false;
            return;
        }
        e.preventDefault();
        bootbox.confirm($(this).data('confirm-msg'), function (answer) {
            if (answer) {
                confirmed = true;
                $(_this).click();
            }
        });
    }

    return {
        init: function () {
            confirmed = false;
            $('.ask-before').on('click', clickHandler);
        }
    };

}(bootbox));