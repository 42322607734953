var SearchAutocomplete = (function() {
    'use strict';

    var KEYCODE = {
        ENTER: 13
    }

    var autocompletes = []

    var config = {
        url: $('.search-form').data('href'),
        $inputs: $('.search-query'),
        redirect: true
    }

    function dispatch(place) {
        console.log(place);

        var params = {
            q: place.formatted_address,
            lat: place.geometry.location.lat().toFixed(4),
            lng: place.geometry.location.lng().toFixed(4),
            types: place.types.join(',')
        }

        if (window.Intercom) window.Intercom('trackEvent', 'action.search', { q: params.q })

        var zipcode = place.address_components.filter(function(elem) {
            return elem.types.indexOf('postal_code') >= 0;
        }).pop();

        var state = place.address_components.filter(function(elem) {
            return elem.types.indexOf('administrative_area_level_1') >= 0;
        }).pop();

        if (zipcode) {
            params = $.extend(params, { zipcode: zipcode.short_name });
        }

        if (state) {
            params = $.extend(params, { state: state.short_name });
        }

        if (config.redirect) {
            window.location = config.url + '?' + $.param(params);
        }
    }

    function forcePlaceChanged(i) {
        google.maps.event.trigger(autocompletes[i], 'place_changed');
    }

    function showTooltip(i) {
        var tooltip = $($('.btn-search')[i]);
        tooltip.tooltipster({
            theme: 'tooltipster-shadow',
            contentCloning: true,
            trigger: 'click',
            side: 'right',
        });
        tooltip.tooltipster('open');
    }

    function deleteTooltip(i) {
        var tooltip = $($('.btn-search')[i]);
        try {
            tooltip.tooltipster('destroy');
        } catch (err) {
            console.log('destroying unitialized tooltip');
        }
    }

    /*function forceAutocompleteFocus() {
        if (!$('.search-query').is(':focus')) {
            $('.search-query').focus()
        } else {
            $('.pac-container').each(function(idx, elem) {
                $(elem).css('display', 'block')
            })
        }
    }*/

    return {
        init: function(params) {
            config = $.extend(config, params || {});

            var options = {
                types: ['geocode'],
                componentRestrictions: { country: 'us' }
            }

            config.$inputs.each(function() {
                var input = $(this)[0] // actual DOM elem
                var autocomplete = new google.maps.places.Autocomplete(input, options);
                autocompletes.push(autocomplete)
            })

            for (var i = 0; i < autocompletes.length; i++) {
                (function(i) {

                    var autocomplete = autocompletes[i];
                    google.maps.event.addListener(autocomplete, 'place_changed', function() {

                        var place = autocomplete.getPlace() || {};
                        console.log('place changed');
                        if ("geometry" in place) {
                            dispatch(place);
                        } else {
                            showTooltip(i);
                        }

                    })

                }(i));
            }

            $('.btn-search').click(function() {
                var listVisible = $('.pac-container').is(':visible');
                var indexOf = $('.btn-search').index($(this));
                forcePlaceChanged(indexOf);
            })

            $('.submit-search-trainer').attr('data-tooltip-content', '#autofill_tooltip');

            $("input.search-query").on('keydown', function(e) {
                var listVisible = $('.pac-container').is(':visible');
                var indexOf = $("input.search-query").index($(this));
                if ((e.keyCode == 13) && (!listVisible)) {
                    forcePlaceChanged(indexOf);
                } else {
                    deleteTooltip(indexOf);
                }
            });
        }
    };
})();