var Location = (function() {
  var locationList = [];
  if (JSON.parse(localStorage.getItem("booking_data"))) {
    booking_data = JSON.parse(localStorage.getItem("booking_data"));
  };

  var location_boxes = $(".location-boxes");

  function buildArray(data) {
    console.log(data)
    data.location["pk"] = 0;
    if (data.virtual_location) {
      locationList.push(data.virtual_location);
    };
    locationList.push(data.location);
    for (var i = 0; i < data.locations.length; i++) {
      const element = data.locations[i];
      element.location["surcharge"] = element.surcharge;
      element.location["pk"] = element.pk;
      locationList.push(element.location);
    }
    return locationList;
  }

  function buildLocationBox(location) {
    // Build html for Location Box (render like a component)
    var surcharge = "";
    var addressData = JSON.parse(localStorage.getItem("addressData"));
    var locationBoxDiv = $("<div></div>").attr("data-location-id", location.pk).addClass("location-box");
    if (location.surcharge) surcharge = $("<div></div>").addClass("surcharge").text("($" + location.surcharge + " session surcharge)");
    if (location.in_home) {
      locationBoxDiv.attr("data-inhome", true);
      if (addressData.street) {
        var userAddress = $("<h2></h2>").text(addressData.street + " " + addressData.city + ", " + addressData.zip_code);
        var editAddress = $("<a data-toggle='modal' data-target='#addressModal'>Edit Address</a>");
        locationBoxDiv.append(["<i class='fas fa-home'></i>", userAddress, editAddress]);
      } else {
        locationBoxDiv.attr("data-toggle", "modal");
        locationBoxDiv.attr("data-target", "#addressModal");
        locationBoxDiv.append("<i class='fas fa-home'></i><h2>Your location</h2><a id='addressModalHandleOpen'>Enter Address</a>");
      }
    } else {
      if (location.id == 'VIRTUAL') {
        const title = $("<h2>Live<br> Virtual Training</h2>");
        locationBoxDiv.attr("data-inhome", false).attr("data-location-id", location.id);
        locationBoxDiv.append(["<i id='virtual' class='fas fa-video'></i>", title, surcharge]);
      } else {
        const title = $("<h2></h2>").text(location.name + " " + location.chain_name);
        locationBoxDiv.attr("data-inhome", false);
        locationBoxDiv.append(["<i id='marker' class='fas fa-map-marker-alt'></i>", title, surcharge]);
      }
    }
    return locationBoxDiv;
  }

  function generateNextButton(trainer_slug, time_slot_pk) {
    $(".next-button").css("display", "block");
    $(".next-button").click(function() {
      if (booking_data.suscriptionData && booking_data.suscriptionData.has_suscription) {
        var baseUrl = window.location.origin + "/fyt/book/booking_step1/plan/" + trainer_slug;
        if (time_slot_pk) window.location.href = baseUrl + "/?time_slot=" + time_slot_pk + "&service=" + booking_data.service_id;
        else window.location.href = baseUrl + "/?service=" + booking_data.service_id;
      } else {
        var baseUrl = window.location.origin + "/fyt/book/booking_step2/rebook/" + trainer_slug;
        if (time_slot_pk) window.location.href = baseUrl + "/?time_slot=" + time_slot_pk;
        else window.location.href = baseUrl;
      }
    })
  }

  function handleLocationBox(time_slot_pk, trainer_slug, trainer_pk) {
    // Handle Service Box behaviors on click
    $(".location-box").click(function() {
      var hasInHome = $(this).data('inhome');
      var addressData = JSON.parse(localStorage.getItem("addressData"));
      console.log(hasInHome);
      var id = $(this).data("location-id");
      booking_data["location_id"] = id;
      localStorage.setItem("booking_data", JSON.stringify(booking_data));
      var locationBoxList = $(".location-boxes").find(".location-box");
      for (var j = 0; j < locationBoxList.length; j++) {
        const element = locationBoxList[j];
        if ($(element).data("location-id") === id) {
          $(element).css("border-color", "#5340ff");
        } else {
          $(element).css("border-color", "#e8e8e8");
        }
      }
      var loc_id = booking_data.location_id;
      var is_virtual = false;
      if (loc_id == 'VIRTUAL') {
        loc_id = null;
        is_virtual = true;
      }
      $.ajax({
        url: '/fyt/book/member/can-subscribe/',
        method: "POST",
        data: {
            'trainer': trainer_pk,
            'service': booking_data.service_id,
            'addl_location_pk': loc_id,
            'time_slot': time_slot_pk,
            'is_virtual': is_virtual
        },
        success: function(data) {
            console.log(data);
            const array = data['can_subscribe'];
            booking_data.suscriptionData = {
              has_suscription: array[0],
              credits: array[1],
              sessions_remaining: array[2],
              virtual_session: array[3]
            }
            localStorage.setItem("booking_data", JSON.stringify(booking_data));
        },
        error: function (error) {console.error(error['responseText'])}
      }).done(function() {
        $(".next-button").css("display", "none");
        if (!hasInHome || addressData.street) {
          generateNextButton(trainer_slug, time_slot_pk);
        }
      })
    })
  }

  function renderLocationBoxes(trainer_pk, time_slot_pk, trainer_slug) {
    $.ajax({
      url: "/trainer/" + trainer_pk + "/locations/",
      dataType: "json",
      success: function(data) {
        var objectState = JSON.parse(localStorage.getItem("objectState"));
        objectState.locations = data;
        localStorage.setItem("objectState", JSON.stringify(objectState));
        buildArray(data);
        for (var i = 0; i < locationList.length; i++) {
          const location = locationList[i];
          $(location_boxes).append(buildLocationBox(location, i));
        }
        const currentLocationSelected = booking_data["location_id"];
        const currentTimeSlot = booking_data["time_slot_id"];
        if ((currentTimeSlot===time_slot_pk || booking_data.reschedule_session) && currentLocationSelected !== null) {
          $("[data-location-id="+currentLocationSelected+"]").css("border-color", "#5340ff");
        }
        if (booking_data.location_id+1 || booking_data.location_id === 'VIRTUAL') {
          var loc_id = booking_data.location_id;
          var is_virtual = false;
          if (loc_id == 'VIRTUAL') {
            loc_id = null;
            is_virtual = true;
          }
          $.ajax({
            url: '/fyt/book/member/can-subscribe/',
            method: "POST",
            data: {
                'trainer': trainer_pk,
                'service': booking_data.service_id,
                'addl_location_pk': loc_id,
                'time_slot': time_slot_pk,
                'is_virtual': is_virtual
            },
            success: function(data) {
                console.log(data);
                const array = data['can_subscribe'];
                booking_data.suscriptionData = {
                  has_suscription: array[0],
                  credits: array[1],
                  sessions_remaining: array[2],
                  virtual_session: array[3]
                }
                localStorage.setItem("booking_data", JSON.stringify(booking_data));
            },
            error: function (error) {console.error(error['responseJSON'])}
          }).done(function() {
            generateNextButton(trainer_slug, time_slot_pk);
          })
        }
        handleLocationBox(time_slot_pk, trainer_slug, trainer_pk);
        $(".location-box").find("h2").bind("DOMSubtreeModified", function() {
          generateNextButton(trainer_slug, time_slot_pk);
        })
      }
    });
  }

  return {
    init: function(trainer_pk, time_slot_pk, trainer_slug) {
      renderLocationBoxes(trainer_pk, time_slot_pk, trainer_slug);
    }
  };
})();
