var Stepper = (function() {

  function handleUrls(time_slot_pk) {
    const steps = $("#stepper").find("a");
    for (var i = 0; i < steps.length; i++) {
      const step = $(steps[i]);
      const baseUrl = step.attr("href");
      step.attr("href", baseUrl + "?time_slot=" + time_slot_pk);
    }
  }

  function handleActiveStep(nOptions) {
    var options = $(".options").find(".option.active");
    for (var i = 0; i <= nOptions; i++) {
      const element = options[i];
      $(element).css("border-bottom", "6px solid #5340ff");
    }
  }

  return {
    init: function(nOptions, time_slot_pk, virtual_only) {
      if (JSON.parse(localStorage.getItem("booking_data"))) {
        booking_data = JSON.parse(localStorage.getItem("booking_data"));
        
        if (virtual_only) {
          $("a#location-step").removeClass("active");
        }

        if (booking_data.suscriptionData && !booking_data.suscriptionData.has_suscription) {
          $("a#plan-step").removeClass("active");
          $("a#payment-step").removeClass("active");
          $("a#book-step").addClass("active");
        } else {
          $("a#plan-step").addClass("active");
          $("a#payment-step").addClass("active");
          $("a#book-step").removeClass("active");
        }

      }
      if (time_slot_pk) handleUrls(time_slot_pk);
      handleActiveStep(nOptions);
    }
  }
})();