var GoogleMap = function(params) {
  'use strict'

  return {

    map: null,
    bounds: null,
    locationMarkers: {},
    markers: [],
    circles: [],

    params: $.extend({
      container: '#map-canvas',
      lat: undefined,
      lng: undefined
    }, params),

    init: function(params){
      // extend paramsuration
      $.extend(this.params, params);

      var canvas = $(this.params.container)[0];
      var center = new google.maps.LatLng(params.lat, params.lng);

      var mapOptions = {
        zoom: 8,
        center: center
      };

      $.extend(mapOptions, this.params.mapOptions);

      this.map = new google.maps.Map(canvas, mapOptions);
      this.bounds = new google.maps.LatLngBounds();
      this.infowindow = new google.maps.InfoWindow({ size: new google.maps.Size(50,50) });

      // add searched point to the bounds
      this.bounds.extend(center)
    },

    addCircle: function(latLng, miles) {
      var self = this;
      var circle = new google.maps.Circle({
        center: latLng || this.map.center,
        radius: miles*1609.34,
        map: this.map,
        strokeColor: "#0000FF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#0000FF",
        fillOpacity: 0.4
      });

      self.circles.push(circle);
    },

    addLocationMarker: function(location, markerlabel){
      var self = this;

      var position = new google.maps.LatLng(location.latitude, location.longitude)

      var icon = {
        url: '/static/images/pink-marker.png',
        scaledSize: new google.maps.Size(22, 31),
        labelOrigin: new google.maps.Point(11, 12)
      };

      var markerlabel = markerlabel <= 10 ? markerlabel : ' ';

      var marker = new google.maps.Marker({
        map: self.map,
        position: position,
        title: location.chain_name,
        animation: google.maps.Animation.DROP,
        icon: icon,
        label: {
            text: markerlabel.toString(),
            fontFamily: 'Open Sans',
            fontSize: '13px',
            fontWeight: '800',
            color: 'white'
        }
      });

      // extend bounds
      self.bounds.extend(position)

      // set content
      marker.content = location.chain_name + '<br/>' + location.name

      // add click event
      google.maps.event.addListener(marker, 'click', function() {
        window.location.href = URI().addSearch({
            location_pk: location.pk
        }).href()
      });
    },

    addMarker: function(latLng){
      var self = this;

      var marker = new google.maps.Marker({
        position: latLng,
        map: self.map
      });

      self.markers.push(marker);
    },

    addLocationMarkers: function(locations) {
      var self = this;
      var counter = 0;

      $.each(locations, function(lochash, location) {
        self.addLocationMarker(location);
        counter++;
      })

      //if (counter > 0) this.fitBounds();
    },

    fitBounds: function() {
      this.map.fitBounds(this.bounds);
    },

    clearCircles: function(){
      var self = this;
      for (var i = 0; i < self.circles.length; i++) {
        self.circles[i].setMap(null);
      }
      self.circles = [];
    },

    clearMarkers: function(){
      var self = this;
      for (var i = 0; i < self.markers.length; i++) {
        self.markers[i].setMap(null);
      }
      self.markers = [];
    },

    renderZipcodes: function(zipcodes) {
        var self = this;

        var src = window.location.origin + '/zipcodes.kml?zipcodes=' + JSON.stringify(zipcodes);

        var kmlLayer = new google.maps.KmlLayer({
            suppressInfoWindows: true,
            preserveViewport: true,
            map: self.map,
            url: src
        });
    },

    resize: function(){
      var self = this;
      google.maps.event.trigger(self.map, "resize");
    },

    setCenter: function(latLng){
      var self = this;
      this.map.setCenter(latLng);
    },

    setZoom: function(zoomNumber){
      var self = this;
      this.map.setZoom(zoomNumber);
    },

    uniqueCircle: function(latLng, miles){
      var self = this;
      self.clearCircles();
      self.addCircle(latLng, miles);
    },

    uniqueMarker: function(latLng){
      var self = this;
      self.clearMarkers();
      self.addMarker(latLng);
    },

  }
}

var GoogleStyle = {
  STATES: [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#464646"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "weight": "2"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#6ccdf4"
            }
        ]
    }
  ]
}