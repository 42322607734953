var footer = (function () {

    return {
        init: function () {
            var $container = $('.masonry_container');
            $container.masonry({
                itemSelector: '.footer_col'
            });
        }
    };

}());
