var learnMore = (function () {

    var $trigger,
        $target,
        $close,
        $replacement;

    return {
        init: function (params) {

            $trigger = $(params.triggerSelector);
            $target = $(params.targetSelector);
            $replacement = $(params.replacementSelector);
            $close = $(params.closeSelector);

            $replacement.hide();

            $trigger.click(function (e) {
                e.preventDefault();
                $target.hide();
                $replacement.show();
            });

            $close.click(function (e) {
                e.preventDefault();
                $replacement.hide();
                $target.show();
            });

            return this;
        }
    };
}());