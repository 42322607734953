var MiniModal = (function() {

    var params = {
        element_action_selector: '.minimodal',
        modal_selector: '#mini-modal',
        template_selector: undefined,
        centered: true,
        data: {}
    };

    return {
        init: function(opts) {
            params = $.extend(params, opts);

            $(params.element_action_selector).click(function(evt) {
                var $elem = $(evt.target);
                var modal_selector = $elem.data("target") || params.modal_selector;
                var template_selector = $elem.data("template") || params.template_selector;
                var $modal = $(modal_selector);
                var $container = $(template_selector);

                var html = $container.html();
                var render = _.template(html);

                $modal.find(".modal-body").html(render(params.data));
                $modal.modal("show");

                if (!params.centered) {
                    var position = evt.pageY / 6;
                    $modal.removeClass("modal-vcenter")
                    $modal.css('top', position);
                }
            });
        }
    }
})()