var NationalHomePage = (function(){

    /*var $places_modal = $('#modal-state-places'),
        $places_container = $('#tpl-places-content');*/

    function send_new_member_email(){
        var member_email = $("#user-email"),
            city = $("#city-to-request"),
            data_json = {
                source : "p",
                city_requested: city.val(),
                email: member_email.val()
            };

        $.post(
            window.URLS.newmember_email_only,
            data_json
        )
        .done(function(response){
            request_a_city_modal.modal("hide");
            member_email.val("");
            member_email.attr("placeholder", "Email Address");
            city.val();
            city.attr("placeholder", "Which city needs Find Your Trainer?");
        })
        .fail(function(response){
            var errors = JSON.parse(response.responseText);
                email_error = errors.error.email.shift();

            member_email.val("");
            member_email.parent().append(email_error);
        })
    }

    /*function show_places_modal(state, places) {
        // fill place template
        var template = _.template($places_container.html())
        var content = template({
            state: state,
            places: places
        })
        $places_modal.find('.modal-content').html(content)

        // set tab content height
        $places_modal.find('.tab-content').css('max-height', $(window).height()*0.8)

        // show modal
        $places_modal.modal('show');
    }*/

    return {
        init: function(){

            var state_name_container = $(".state-name"),
                $container = $('.masonry_container');

            /*$(".state_name").click(function(e){
                e.preventDefault();

                var $link = $(e.target)
                var $state_container = $link.parent()

                var state = $state_container.data('state-name')
                var state_pk = $state_container.data('state-pk')

                // if state is empty only show "Request a City" tab
                if ( $link.hasClass('state_empty') ) {
                    show_places_modal(state, [])
                    $('.nav a[href="#request-a-city"]').tab('show');
                    $('.nav a[href="#places-list"]').hide();
                }
                else
                {
                    var request = $.post('/state-places/', { 'state_pk': state_pk }, "json")

                    request.done(function (places) {
                        show_places_modal(state, places);
                    })

                    request.fail(function() {
                        alert('Error loading places.')
                    })
                }
            });*/

            $("body").on('click', '#request-city', function(e) {
                $('#request-city-form').validate({
                    submitHandler: function(form) {
                        send_new_member_email();
                    }
                });
            });

            // CAROUSEL

            $('.carousel-control.left').click(function() {
              $('#market-carousel').carousel('prev');
            });

            $('.carousel-control.right').click(function() {
                $('#market-carousel').carousel('next');
            });
        }
    }

})();