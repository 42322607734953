var reviewPopupForm = (function (bootbox, popupFormBuilder) {

    var popupForm = popupFormBuilder();

    return {
        init: function (params) {

            var postSuccessCallback = params.postSuccessCallback || function () {return; },
                modal_selector = params.modal_selector || "#new-message-modal";

            return popupForm.init({
                openModalBtnSelector: params.openModalBtnSelector,
                modalSelector: modal_selector,
                templateSelector: "#review-form-template",
                url: params.url,
                validators: [
                    popupForm.buildNotEmptyValidator('title', '.title-is-empty'),
                    popupForm.buildNotEmptyValidator('content', '.body-is-empty'),
                    popupForm.buildNotEmptyValidator('stars', '.stars-required'),
                ],
                postOpenCallback: function (context) {
                    var options = {
                        readOnly: false,
                        hints: ['bad', 'poor', 'regular', 'good', 'great'],
                        click: function (score) {
                            $('[name=stars]').val(score);
                        }
                    };
                    if (context.initialData.review_stars) {
                        options.score = context.initialData.review_stars;
                    }
                    $('#stars-widget').raty(options);
                },
                postSuccessCallback: function (context) {

                    var $btn = context.$openModalBtn;

                    console.log(context.formData);

                    $btn.data('review_title', context.formData.title);
                    $btn.data('review_content', context.formData.content);
                    $btn.data('review_stars', context.formData.stars);
                    $btn.data('author_ip', context.formData.author_ip);
                    $btn.text("Edit my review");
                    
                    bootbox.alert(
                        "Thanks for your review of " + context.initialData.trainer_name + "!",
                        function () {
                            postSuccessCallback(context);
                        }
                    );
                },
            });

        },
    };

}(bootbox, popupFormBuilder));
