var ImageCropper = (function() {

    var initOpts = {
        preview: 'img#photo-preview',
        modal: '#modal-photo-preview',
        input: 'input#photo',
        preloader: '.preloader',
        showModal: true,
        compress: true,
        cropperOpts: {
            viewMode: 1,
            aspectRatio: 1,
            autoCropArea: 0.5,
            dragMode: 'move',
            scalable: false,
            strict: false
        },
        compressorOpts: {
            quality: .7,
            maxWidth: 800,
            maxHeight: 800
        }
    }

    var uploadOpts = {
        uploadURL: window.URLS.upload_image,
        onSuccess: function() { console.log('Upload success!') },
        onError: function() { console.log('Upload error') },
        onComplete: null,
        imageField: 'img',
        payload: {}
    }

    var config = {};
    var $preview;
    var $modal;
    var url; // HTML5 objectURL

    var getCroppedBlob = function(callback) {
        $preview.cropper('getCroppedCanvas').toBlob(function (blob) {
            if (config.compress) {
                console.log('compressing crop...')
                new ImageCompressor(blob, $.extend(config.compressorOpts, {
                    success: function(compressed) {
                        console.log('compressed ok')
                        if (callback) callback(compressed)
                    },
                    error: function(e) {
                        console.log(e)
                    }
                }))
            } else {
                if (callback) callback(blob);
            }
        }, "image/jpeg")
    }

    return {
        init: function(args) {
            config = $.extend(initOpts, args);

            $preview = $(config.preview);
            $modal = $(config.modal);
            $input = $(config.input);

            $input.change(function(e) {
                url = window.URL.createObjectURL(this.files[0])
                $preview.attr('src', url)
                console.log('opening', url)

                if (config.showModal) {
                    $modal.modal('show')
                } else {
                    $preview.cropper(config.cropperOpts);
                }
            })

            if (config.showModal) {
                $modal
                .on('shown.bs.modal', function () {
                    $preview.cropper(config.cropperOpts);
                })
                .on('hidden.bs.modal', function () {
                    $preview.cropper('destroy');
                    window.URL.revokeObjectURL(url)
                    $('input[type="file"]').val('');                
                });
            }
        },
        upload: function(args) {
            var opts = $.extend(uploadOpts, args)
            var $preloader = $(config.preloader);

            $preloader.show();

            getCroppedBlob(function(blob) {
                var payload = new FormData()

                payload.append(opts.imageField, blob, "blob.jpg")

                for (key in opts.payload) {
                    var value = opts.payload[key]
                    payload.append(key, value)
                }

                $.ajax(opts.uploadURL, {
                    method: "POST",
                    data: payload,
                    processData: false,
                    contentType: false,
                    success: opts.onSuccess,
                    error: opts.onError,
                    complete: function() {
                        $preloader.hide();
                        if (config.showModal) $modal.modal('hide');
                        if (opts.onComplete) opts.onComplete();
                    }
                });
            })
        },
        showModal: function() {
            $modal.modal('show')
        },
        hideModal: function() {
            $modal.modal('hide')
        }
    }
})()