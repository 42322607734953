var ValidateSubs = (function () {
  var slug
  var full_name
  var user

  return {
    init: function (params) {
      slug = params.slug
      full_name = params.full_name
      user = params.user

      if (user) {
        $.ajax({
          method: 'POST',
          url: '/validate-have-subscription/',
          data: {'trainer_slug': slug},
          success: function(response) {
            if (response.exists === true) {
              $('.modal-name-trainer').text(full_name)
              $('#cant_book_subc').modal('show');
            } else {
              window.location.href = window.location.origin + '/fyt/book/booking_step1/services/' + slug;
            }
          },
          error: function (error) {console.error(error)}
        })
      } else {
        window.location.href = window.location.origin + '/fyt/book/booking_step1/services/' + slug;
      }
    }
  };
}());