var ReschedulerCancelEditModal = (function () {

    return {

        init: function () {
            
            // Session Edit Address Modal
            $('.edit-location').click(function(e) {	
                e.preventDefault();
                var session_pk = $(this).data('session-pk');

                $.ajax({
                    url: window.URLS.update_session_address,
                    data: {session_pk: session_pk}
                })
                .done(function(form) {
                    $('#session-address').modal('show')
                    $('#address-form').html(form)
                    $('#address-form input#id_session_pk').val(session_pk)
                })
            })

            $('.save-address').click(function(e) {
                e.preventDefault();
                var $form = $('#address-form > form');
                var session_pk = $('#address-form input#id_session_pk').val();

                $.ajax({
                    method: 'POST',
                    url: window.URLS.update_session_address,
                    data: $form.serialize(),
                    dataType: 'json'
                })
                .done(function(r) {
                    $('#session-address').modal('hide')
                    $('p.data-address[data-session-pk='+session_pk+']').text(r.address)
                })
                .fail(function(jqXHR) {
                    var errors = jqXHR.responseJSON;
                    $.each(errors, function(key, value) {
                        var field_input = $("input[name="+key+"]");
                        $('<p class="alert alert-danger">'+ value +'</p>').insertAfter(field_input);
                    });
                })
            })

            var timeslot_id = null;

            $('button.open-reschedule-modal').click(function(){
                var RescheduleModal = $('#RescheduleModalAccount');
        
                var start_datetime = $(this).data('start_datetime');
                RescheduleModal.find('h3#start_datetime').text(start_datetime);
        
                var avatar = $(this).data('avatar');
                RescheduleModal.find('img#avatar-trainer').attr("src",avatar);
        
                var name = $(this).data('name');
                RescheduleModal.find('h4#trainer-name').text(name);
                
                var street = $(this).data('street');
                RescheduleModal.find('p#street').text(street);
                var address = $(this).data('address');
                RescheduleModal.find('p#address').text(address);
                
                var id = $(this).data('id');
                timeslot_id = id;
                RescheduleModal.find('input#training_session_id').attr("value",id);
        
                RescheduleModal.modal('show');
            })
        
            $('button.open-cancel-modal').click(function(){
                var CancelModal = $('#CancelModalAccount');
        
                var start_datetime = $(this).data('start_datetime');
                CancelModal.find('h3#start_datetime').text(start_datetime);
        
                var avatar = $(this).data('avatar');
                CancelModal.find('img#avatar-trainer').attr("src",avatar);
        
                var name = $(this).data('name');
                CancelModal.find('h4#trainer-name').text(name);
                
                var street = $(this).data('street');
                CancelModal.find('p#street').text(street);

                var address = $(this).data('address');
                CancelModal.find('p#address').text(address);
                
                var id = $(this).data('id');            
                CancelModal.find('input#training_session_id').attr("value",id);
        
                CancelModal.modal('show');
            })

            $("#reschedule_session_button").click(function() {
                var reschedule_session = {
                    timeslot_id: timeslot_id
                };
                localStorage.setItem("reschedule_session", JSON.stringify(reschedule_session));
            })

        }
    }; 
}());