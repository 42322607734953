/*globals
    bootbox
*/

var locationsForm = (function(GoogleMap) {

    var $dom,
        chains_pks = new Array(),
        original_chains = new Array(),
        gmap = GoogleMap();

    var primaryLocation = {
        lat: null,
        lng: null
    };

    function uniqueCircle(latLng, $miles_input) {
        var miles = $miles_input.val();
        if (miles !== 0) {
            gmap.uniqueCircle(latLng, miles);
        } else {
            gmap.clearCircles();
        }
    }

    function listenToInputChanges($miles_input) {
        $miles_input.on('keyup mouseup', function() {
            var latLng = new google.maps.LatLng(parseFloat($dom.input.primary_lat.val()), parseFloat($dom.input.primary_lng.val()));
            uniqueCircle(latLng, $(this));
        });
    }

    function setMap(lat, lng, $miles_input) {
        gmap.resize();
        var latLng = new google.maps.LatLng(lat, lng);
        gmap.uniqueMarker(latLng);
        gmap.setZoom(11);
        uniqueCircle(latLng, $miles_input);
        gmap.setCenter(latLng);
    }

    function initializeLocationFields($address_input, $miles_input) {
        var lat = parseFloat($dom.input.primary_lat.val());
        var lng = parseFloat($dom.input.primary_lng.val());
        gmap.init({
            container: '#' + $dom.map_container[0].id,
            lat: lat,
            lng: lng
        });
        setMap(lat, lng, $miles_input);
        setupPrimaryLocationAutocomplete($address_input, $miles_input);
        listenToInputChanges($miles_input);
    }

    function toggleAdditionalTypes(bool) {
        $("input[name*='addl_location'][value='c']").each(function() {
            $(this).prop('readonly', bool);
        })
    }

    function toggle_in_home_option(edit_inhome) {
        if (($('input[value="c"]:checked').length == 0) && (edit_inhome !== true)) {
            $('input[value="c"]').prop('readonly', false);
            $('input[value="c"]').each(function() {
                $(this).parent().show();
            });
        } else {
            $('input[value="c"]').prop('readonly', true);
            $('input[value="c"]').each(function() {
                if ($(this).is(':checked') == false) {
                    $(this).parent().hide();
                }
            });
        }
    }

    function toogleWhereDoYouTrain(edit_inhome) {
        var value = $('input[name$=where_do_you_train]:checked').val();
        switch (value) {
            case 'g':
                $("#add-location").show();
                $dom.gym_fields.show();
                if ($dom.input.multi_locations[0]['gym_pk'].val()) {
                    $dom.input.multi_locations[0]['address'].prop('required', false);
                } else {
                    $dom.input.multi_locations[0]['address'].prop('required', true);
                }
                $dom.inhome_fields.hide();
                toggleAdditionalTypes(false);
                break;
            case 'c':
                $("#add-location").show();
                $dom.gym_fields.hide();
                $dom.input.multi_locations[0]['address'].prop('required', false);
                $dom.map_container.parent().appendTo($dom.inhome_fields);
                $dom.map_container.show();
                $dom.inhome_fields.show();
                initializeLocationFields($dom.input.primary_location, $dom.input.miles_willing_to_travel);
                toggleAdditionalTypes(true);
                break;
            case 'v':
                $dom.inhome_fields.hide();
                $dom.gym_fields.hide();
                $("#add-location").hide();
                break;
        }
        toggle_in_home_option(edit_inhome);
    }

    // fix to be able to do selectize inputs required
    function selectizeValidationFix($select) {

        var self = $select[0].selectize;

        self.$input.on('invalid', function(event) {
            event.preventDefault();
            self.focus(true);
            self.$wrapper.addClass('invalid');
        });

        self.$input.on('change', function(event) {
            if (event.target.validity && event.target.validity.valid) {
                self.$wrapper.removeClass('invalid');
            }
        });

    }

    function setupPrimaryLocationAutocomplete($address_input, $miles_input) {
        var options = {
            types: ['address'],
            componentRestrictions: { country: 'us' }
        }

        var elem = $address_input.get(0);
        var autocomplete = new google.maps.places.Autocomplete(elem, options);

        $address_input.attr('data-tooltip-content', '#autofill_tooltip');

        autocomplete.addListener('place_changed', function() {
            var place = autocomplete.getPlace() || {};
            if ("geometry" in place) {
                primaryLocation.lat = place.geometry.location.lat();
                primaryLocation.lng = place.geometry.location.lng();
                var latLng = new google.maps.LatLng(primaryLocation.lat, primaryLocation.lng);
                gmap.setCenter(latLng);
                gmap.setZoom(11);
                gmap.uniqueMarker(latLng);
                uniqueCircle(latLng, $miles_input);
                $dom.input.primary_lat.val(primaryLocation.lat);
                $dom.input.primary_lng.val(primaryLocation.lng);
            } else {
                console.log('no place');
                showTooltip($address_input);
            }
        })

        $address_input.on('keydown', function(e) {
            var listVisible = $('.pac-container').is(':visible');
            if ((e.keyCode == 13) && (!listVisible)) {
            } else {
                deleteTooltip($address_input);
            }
        });
    }

    // Additional locations
    var addl_counter = 1;

    function show_addl_loc_form() {
        switch (addl_counter) {
            case 1:
                if ($dom.input.multi_locations[1]['row'].hasClass('hidden')) {
                    $dom.input.multi_locations[1]['row'].removeClass('hidden');
                    $dom.input.multi_locations[1]['gym_option'].attr('checked', true).triggerHandler('click');
                    addl_counter += 1;
                } else {
                    addl_counter += 1;
                    show_addl_loc_form();
                }
                break;
            case 2:
                if ($dom.input.multi_locations[2]['row'].hasClass('hidden')) {
                    $dom.input.multi_locations[2]['row'].removeClass('hidden');
                    $dom.input.multi_locations[2]['gym_option'].attr('checked', true).triggerHandler('click');
                    addl_counter += 1;
                } else {
                    addl_counter += 1;
                    show_addl_loc_form();
                }
                break;
            case 3:
                $dom.input.multi_locations[3]['row'].removeClass('hidden');
                $('#add-location').addClass('hidden');
                $dom.input.multi_locations[3]['gym_option'].attr('checked', true).triggerHandler('click');
                break;
        }
        /*addl_counter += 1; */
    }

    function get_checkbox_value(checkbox) {
        var value = "";
        for (var i = checkbox.length - 1; i >= 0; i--) {
            if (checkbox[i].checked) {
                value = $(checkbox[i]).val();
            }
        }
        return value;
    }

    function update_addl_corresponding_fields(input, index, edit_inhome) {
        var value = get_checkbox_value(input);
        if (value) {
            $dom.input.multi_locations[index]['fields'].removeClass('hidden');
            switch (value) {
                case 'g':
                    $dom.input.multi_locations[index]['miles_col'].addClass('hidden');
                    $dom.input.multi_locations[index]['gym_name_row'].removeClass('hidden');
                    $dom.input.multi_locations[index]['membership'].removeClass('hidden');
                    if ($dom.input.multi_locations[index]['row'].has($dom.map_container).length > 0) {
                        $dom.map_container.hide();
                    };
                    break;
                case 'c':
                    $dom.input.multi_locations[index]['gym_name'].prop('required', false);
                    $dom.input.multi_locations[index]['branch_name'].prop('required', false);
                    $dom.input.multi_locations[index]['gym_name_row'].addClass('hidden');
                    $dom.input.multi_locations[index]['membership'].addClass('hidden');
                    $dom.input.multi_locations[index]['miles_col'].removeClass('hidden');
                    $dom.map_container.parent().appendTo($dom.input.multi_locations[index]['row']);
                    $dom.map_container.show();
                    initializeLocationFields($dom.input.multi_locations[index]['address'], $dom.input.multi_locations[index]['miles']);
                    break;
            }
            toggle_in_home_option(edit_inhome);
        }
    }

    function existing_location_selected(i) {
        var gym_name = $dom.input.multi_locations[i]['branch_name'].val();

        var indexOf = $dom.input.multi_locations[i]['locations_names'].indexOf(gym_name);

        var gym_pk = $dom.input.multi_locations[i]['locations_pks'][indexOf];

        /*var gym_pk = $dom.input.multi_locations[i]['branch_name'].val();*/

        var indexOf = $dom.input.multi_locations[i]['locations_pks'].indexOf(gym_pk);

        $dom.input.multi_locations[i]['gym_pk'].val(gym_pk);
        $dom.input.multi_locations[i]['street'].val($dom.input.multi_locations[i]['locations_streets'][indexOf]);
        $dom.input.multi_locations[i]['city'].val($dom.input.multi_locations[i]['locations_cities'][indexOf]);
        $dom.input.multi_locations[i]['zipcode'].val($dom.input.multi_locations[i]['locations_zipcodes'][indexOf]);
        $dom.input.multi_locations[i]['state'].val($dom.input.multi_locations[i]['locations_states'][indexOf]);

        $dom.input.multi_locations[i]['address'].prop('required', false);
        var index = i + 1;

        $dom.input.multi_locations[i]['new_address_fields'].addClass('hidden');
        $dom.input.multi_locations[i]['pre_filled_address_fields'].removeClass('hidden');
    }

    function new_location_selected(i) {
        $dom.input.multi_locations[i]['gym_pk'].val('');
        $dom.input.multi_locations[i]['street'].val('');
        $dom.input.multi_locations[i]['city'].val('');
        $dom.input.multi_locations[i]['zipcode'].val('');
        $dom.input.multi_locations[i]['state'].val('');

        /*$dom.input.multi_locations[0]['branch_name'].prop('required', false);*/

        /*$("label[for='"+$dom.input.multi_locations[i]['branch_name'][0].id+"']").html('Branch Name (Optional)');*/

        /*$dom.input.multi_locations[i]['branch_name'].empty();*/

        var $branch_name = $dom.input.multi_locations[i]['branch_name'].selectize({
            create: true,
            delimiter: ';',
            openOnFocus: false,
        });

        selectizeValidationFix($branch_name);

        $dom.input.multi_locations[i]['address'].prop('required', true);
        $dom.input.multi_locations[i]['branch_name'].unbind('change');

        var index = i + 1;

        $dom.input.multi_locations[i]['pre_filled_address_fields'].addClass('hidden');
        $dom.input.multi_locations[i]['new_address_fields'].removeClass('hidden');
    }

    function fill_place_fields(autocomplete, $addres_input, $zipcode_field, $route_field) {
        console.log('place changed');
        var place = autocomplete.getPlace();
        var zipcode = '';
        var route = $addres_input.val();
        if (place != null) {
            for (var w = 0; w < place.address_components.length; w++) {
                for (var j = 0; j < place.address_components[w].types.length; j++) {
                    if (place.address_components[w].types[j] == "postal_code") {
                        if ('long_name' in place.address_components[w]) {
                            zipcode = place.address_components[w]['long_name'];
                        } else if ('short_name' in place.address_components[i]) {
                            zipcode = place.address_components[w]['short_name'];
                        }
                    } else if (place.address_components[w].types[j] == "route") {
                        if ('long_name' in place.address_components[w]) {
                            route = place.address_components[w]['long_name'];
                        } else if ('short_name' in place.address_components[i]) {
                            route = place.address_components[w]['short_name'];
                        }
                    }
                }
            }
            if ('name' in place) {
                route = place['name'];
            }
        }
        $zipcode_field.attr('value', zipcode);
        $route_field.attr('value', route);
    }

    function filled_gym_name(index, pre_filled, str) {
        var gym_name = $dom.input.multi_locations[index]['gym_name'].val() || str;
        if (original_chains.indexOf(gym_name) < 0) {
            $dom.input.multi_locations[index]['chain_pk'].val(chain_pk);
            /*$dom.input.multi_locations[index]['branch_name'].selectize()[0].selectize.destroy();*/
            if (pre_filled == false) {
                $("label[for='" + $dom.input.multi_locations[index]['branch_name'][0].id + "']").text("Branch Name");
                $dom.input.multi_locations[index]['branch_name'].empty();
            }
            new_location_selected(index);
        } else {
            var chain_pk = original_chains.indexOf(gym_name);
            $dom.input.multi_locations[index]['chain_pk'].val(chain_pk);
            $dom.input.multi_locations[index]['branch_name'].prop('required', true);
            $("label[for='" + $dom.input.multi_locations[index]['branch_name'][0].id + "']").html('Branch Name (*)');

            var url = '/get-chain-locations/' + chain_pk;
            $.ajax({
                    method: "GET",
                    url: url,
                    data: {}
                })
                .done(function(data) {
                    $dom.input.multi_locations[index]['locations_pks'] = data['locations_pks'];
                    $dom.input.multi_locations[index]['locations_names'] = data['locations_names'];
                    $dom.input.multi_locations[index]['locations_streets'] = data['locations_streets'];
                    $dom.input.multi_locations[index]['locations_cities'] = data['locations_cities'];
                    $dom.input.multi_locations[index]['locations_states'] = data['locations_states'];
                    $dom.input.multi_locations[index]['locations_zipcodes'] = data['locations_zipcodes'];
                    $dom.input.multi_locations[index]['branch_name'].selectize()[0].selectize.destroy();

                    var pre_filled_options = [];

                    if ((pre_filled == true) && ($dom.input.multi_locations[index]['gym_pk'].val() == '')) {
                        for (var i = 0; i < $dom.input.multi_locations[index]['branch_name'][0].options.length; i++) {
                            pre_filled_options.push($dom.input.multi_locations[index]['branch_name'][0].options[i].text);
                        }
                    }

                    $dom.input.multi_locations[index]['branch_name'].empty();

                    if (pre_filled_options.length > 0) {
                        var option = $('<option>', {
                            value: pre_filled_options[0],
                            text: pre_filled_options[0],
                        });
                    } else {
                        var option = $('<option>', {
                            value: '',
                            text: '',
                        });
                    }
                    $dom.input.multi_locations[index]['branch_name'].append(option);

                    $.each($dom.input.multi_locations[index]['locations_names'], function(i, item) {
                        var option = $('<option>', {
                            value: item,
                            text: item,
                        });
                        if ($dom.input.multi_locations[index]['gym_pk'].val() == $dom.input.multi_locations[index]['locations_pks'][i]) {
                            option.attr('selected', true)
                        }
                        $dom.input.multi_locations[index]['branch_name'].append(option);
                    });

                    var $branch_name = $dom.input.multi_locations[index]['branch_name'].selectize({
                        create: true,
                        delimiter: ';',
                        openOnFocus: false,
                    })

                    selectizeValidationFix($branch_name);

                    if (pre_filled == true) {
                        var current_selected = $dom.input.multi_locations[index]['branch_name'].val();
                        if ($dom.input.multi_locations[index]['locations_names'].indexOf(current_selected) >= 0) {
                            existing_location_selected(index);
                        }
                    }

                    $branch_name[0].selectize.on('item_add', function(str) {
                        var item_added = str;
                        var selectSizeControl = $branch_name[0].selectize;
                        var options = $('.item');
                        $.each(options, function(key, value) {
                            // 1. Get the value
                            var data_value = $(value).attr('data-value')

                            // 2. Remove the option if it is not the last added
                            if (data_value !== str) {
                                selectSizeControl.removeItem(data_value);
                                selectSizeControl.refreshItems();
                                selectSizeControl.refreshOptions();
                            }
                        });

                        if ($dom.input.multi_locations[index]['locations_names'].indexOf(item_added) >= 0) {
                            console.log('existing loc');
                            existing_location_selected(index);
                        } else {
                            console.log('new loc');
                            new_location_selected(index);
                        }

                        this.close();
                    })
                    /*existing_location_selected(index);
                    $dom.input.multi_locations[index]['branch_name'].on('change', function(){
                        existing_location_selected(index);
                    });*/
                })
                .fail(function(response) {
                    console.log('fail!!');
                    console.log(response);
                })
        };
    }

    function showTooltip(tooltip) {
        console.log('show tooltip');
        tooltip.tooltipster({
            theme: 'tooltipster-shadow',
            contentCloning: true,
            trigger: 'click',
            side: 'right',
        });
        tooltip.tooltipster('open');
    }

    function deleteTooltip(tooltip) {
        console.log('delete tooltip');
        try {
            tooltip.tooltipster('destroy');
        } catch (err) {
            console.log('destroying unitialized tooltip');
        }
    }

    return {
        init: function(params) {
            $dom = $.extend({
                input: {
                    training_locations: $('#training_locations'),
                    edit_training_locations: $('#edit_training_locations'),
                    gym_name: $('select[name$=gym_name]'),
                    branch_name: $('select[name$=branch_name]'),
                    gym_pk: $('input[name$=gym_pk]'),
                    gym_address: $('input[name$=gym_address]'),
                    gym_street: $('input[name$=gym_street]'),
                    gym_city: $('input[name$=gym_city]'),
                    gym_zipcode: $('input[name$=gym_zipcode]'),
                    gym_state_abb: $('input[name$=gym_state_abb]'),
                    primary_location: $('input[name$=primary_location]'),
                    primary_lat: $('input[name$=primary_lat]'),
                    primary_lng: $('input[name$=primary_lng]'),
                    miles_willing_to_travel: $('input[name$=miles_willing_to_travel]'),
                    where_do_you_train: $('input[name$=where_do_you_train]'),
                    original_chains: $('select[name$=original_chains]'),
                    chains_pks: $('select[name$=chains_pks]'),
                    delete_primary: $('.remove-primary-location'),
                    multi_locations: [{
                        'row': null,
                        'pk': null,
                        'fields': null,
                        'type': $('input[name$=where_do_you_train]'),
                        'gym_option': $('input[name$=where_do_you_train][value="g"]'),
                        'gym_name': $('select[name$=gym_name]'),
                        'branch_name': $('select[name$=branch_name]'),
                        'branch_name_options': $('select[name$=branch_name] option'),
                        'chain_pk': $('input[name$=chain_pk]'),
                        'gym_pk': $('input[name$=gym_pk]'),
                        'gym_name_row': null,
                        'address': $('input[name$=gym_address_]'),
                        'route': $('input[name$=route]'),
                        'gmap_zipcode': null,
                        'street': $('input[name$=gym_street]'),
                        'city': $('input[name$=gym_city]'),
                        'zipcode': $('input[name$=gym_zipcode]'),
                        'state': $('input[name$=gym_state_abb]'),
                        'miles': $('input[name$=miles_willing_to_travel]'),
                        'membership': null,
                        'miles_col': null,
                        'delete_button': null,
                        'make_primary': null,
                        'cancel_button': null,
                        'locations_pks': new Array(),
                        'locations_names': new Array(),
                        'locations_streets': new Array(),
                        'locations_cities': new Array(),
                        'locations_states': new Array(),
                        'locations_zipcodes': new Array(),
                        'new_address_fields': $('.gym-new-address'),
                        'pre_filled_address_fields': $('.gym-filled-address'),
                    }],
                },
                map_container: $('#primary_location_map'),
                gym_fields: $('#gym_fields'),
                inhome_fields: $('#inhome_fields')
            }, params.$dom);

            // addl location's fields
            for (var i = 3; i >= 1; i--) {
                $dom.input.multi_locations[i] = {
                    'row': $('.addl-loc-' + i),
                    'pk': $('input[name$=addl_pk_' + i + ']'),
                    'fields': $('.addl-loc-fields-' + i),
                    'type': $('input[name$=addl_location_type_' + i + ']'),
                    'gym_option': $('input[name$=addl_location_type_' + i + '][value="g"]'),
                    'gym_name': $('select[name$=addl_name_' + i + ']'),
                    'branch_name': $('select[name$=addl_branch_name_' + i + ']'),
                    'branch_name_options': $('select[name$=addl_branch_name_' + i + '] option'),
                    'chain_pk': $('input[name$=addl_chain_pk_' + i + ']'),
                    'gym_pk': $('input[name$=addl_gym_pk_' + i + ']'),
                    'gym_name_row': $('.row-gym-name-' + i),
                    'address': $('input[name$=addl_address_' + i + ']'),
                    'route': $('input[name$=addl_route_' + i + ']'),
                    'gmap_zipcode': $('input[name$=addl_gmap_zipcode_' + i + ']'),
                    'street': $('input[name$=addl_street_' + i + ']'),
                    'city': $('input[name$=addl_city_' + i + ']'),
                    'zipcode': $('input[name$=addl_zipcode_' + i + ']'),
                    'state': $('input[name$=addl_state_' + i + ']'),
                    'miles': $('input[name$=addl_miles_' + i + ']'),
                    'membership': $('.addl-membership-' + i),
                    'miles_col': $('.addl-miles-' + i),
                    'delete_button': $('.remove-location-' + i),
                    'make_primary': $('.make-primary-' + i),
                    'cancel_button': $('.cancel-location-' + i),
                    'new_address_fields': $('.new-address-' + i),
                    'pre_filled_address_fields': $('.pre-filled-address-' + i),
                    'locations_pks': new Array(),
                    'locations_names': new Array(),
                    'locations_streets': new Array(),
                    'locations_cities': new Array(),
                    'locations_states': new Array(),
                    'locations_zipcodes': new Array(),
                }
            }

            $dom.map_container.height('350');
            $dom.map_container.css('margin', '0 auto 10px auto');

            toogleWhereDoYouTrain(params.edit_inhome);
            $dom.input.where_do_you_train.on('click', function(e) {
                if ( (($(this).val() == 'c') && ($('input[value="c"]:checked').length <= 1)) || ($(this).val() == 'v') || ($(this).val() == 'g') ) {
                    console.log("toogleWhereDoYouTrain")
                    toogleWhereDoYouTrain(params.edit_inhome);
                } else {
                    e.preventDefault();
                }
            });

            // Locations FORM

            if (($dom.input.training_locations.length > 0) || ($dom.input.edit_training_locations.length > 0)) {

                $('#add-location').on('click', show_addl_loc_form);

                $('.cancel-location').on('click', function(e){
                    e.preventDefault();
                    var index_cancel = $('.cancel-location').index($(this));
                    var location_row = $($('.addl-loc')[index_cancel]);
                    location_row.find('input[type=radio]').removeAttr('checked')
                    location_row.addClass('hidden');
                })

                $dom.input.chains_pks.find('option').each(function(i) {
                    chains_pks[i] = $(this).val();
                });

                $dom.input.original_chains.find('option').each(function(i) {
                    var index = chains_pks[i];
                    original_chains[index] = $(this).val();
                });

                var locations_options = new Array();

                // TRAINER'S PRIMARY LOCATION 

                if ($dom.input.multi_locations[0]['gym_name'].length > 0) {

                    //autocomplete gym address
                    var address_input = $dom.input.multi_locations[0]['address'];
                    address_input.attr('data-tooltip-content', '#autofill_tooltip');
                    address_input.attr('placeholder', 'Enter an address');
                    var elem = address_input.get(0);
                    var options = {
                        types: ['address'],
                        componentRestrictions: { country: 'us' }
                    }
                    var autocomplete = new google.maps.places.Autocomplete(elem, options);

                    autocomplete.addListener('place_changed', function() {
                        var place = autocomplete.getPlace() || {};
                        if (!("geometry" in place)) {
                            showTooltip(address_input);
                        } else {
                            fill_place_fields(autocomplete, address_input, $dom.input.multi_locations[0]['zipcode'], $dom.input.multi_locations[0]['route']);
                        }
                    })

                    address_input.on('keydown', function(e) {
                        var listVisible = $('.pac-container').is(':visible');
                        if ((e.keyCode == 13) && (!listVisible)) {
                            google.maps.event.trigger(autocomplete, 'place_changed');
                        } else {
                            deleteTooltip(address_input);
                        }
                    });

                    if ((original_chains.indexOf($dom.input.multi_locations[0]['gym_name'].val()) >= 0) && ($dom.input.multi_locations[0]['gym_name'].val() != '')) {
                        filled_gym_name(0, true);
                    };

                    var $gym_name = $dom.input.multi_locations[0]['gym_name'].selectize({
                        create: true,
                        delimiter: ';',
                        openOnFocus: false,
                    })

                    $gym_name[0].selectize.on('item_add', function(str) {
                        var selectSizeControl = $gym_name[0].selectize;
                        var options = $('.item');
                        $.each(options, function(key, value) {
                            // 1. Get the value
                            var data_value = $(value).attr('data-value')

                            // 2. Remove the option if it is not the last added
                            if (data_value !== str) {
                                selectSizeControl.removeItem(data_value);
                                selectSizeControl.refreshItems();
                                selectSizeControl.refreshOptions();
                            }
                        });

                        filled_gym_name(0, false, str);

                        this.close();
                    })

                }

                // TRAINER'S ADDITIONAL LOCATIONS

                for (var i = $dom.input.multi_locations.length - 1; i >= 1; i--) {

                    (function(i) {
                        var type_value = get_checkbox_value($($dom.input.multi_locations[i]['type']));
                        if (type_value) {
                            update_addl_corresponding_fields($($dom.input.multi_locations[i]['type']), i, params.edit_inhome);
                            if ($dom.input.multi_locations[i]['gym_name'].val()) {
                                filled_gym_name(i, true);
                            }
                            $dom.input.multi_locations[i]['row'].removeClass('hidden');
                        }

                        // on change type of location
                        $($dom.input.multi_locations[i]['type']).click(function(e) {
                            if ((($(this).val() == 'c') && ($('input[value="c"]:checked').length <= 1)) || ($(this).val() == 'g')) {
                                update_addl_corresponding_fields($(this), i, params.edit_inhome);
                            } else {
                                e.preventDefault();
                            }
                        });

                        if ($dom.input.multi_locations[i]['gym_name'].length > 0) {

                            //autocomplete address
                            var address_input = $dom.input.multi_locations[i]['address'];
                            address_input.attr('data-tooltip-content', '#autofill_tooltip');
                            address_input.attr('placeholder', 'Enter an address');
                            var elem = address_input.get(0);
                            var options = {
                                types: ['address'],
                                componentRestrictions: { country: 'us' }
                            }
                            var autocomplete = new google.maps.places.Autocomplete(elem, options);

                            autocomplete.addListener('place_changed', function() {
                                var place = autocomplete.getPlace() || {};
                                if (!("geometry" in place)) {
                                    showTooltip(address_input);
                                } else {
                                    fill_place_fields(autocomplete, address_input, $dom.input.multi_locations[i]['gmap_zipcode'], $dom.input.multi_locations[i]['route']);
                                }
                            })

                            address_input.on('keydown', function(e) {
                                var listVisible = $('.pac-container').is(':visible');
                                if ((e.keyCode == 13) && (!listVisible)) {
                                    google.maps.event.trigger(autocomplete, 'place_changed');
                                } else {
                                    deleteTooltip(address_input);
                                }
                            });

                            var $gym_name = $dom.input.multi_locations[i]['gym_name'].selectize({
                                create: true,
                                delimiter: ';',
                                openOnFocus: false,
                            })

                            selectizeValidationFix($gym_name);

                            // when item is added to selectize
                            $gym_name[0].selectize.on('item_add', function(str) {
                                console.log('selectize added');
                                var selectSizeControl = $gym_name[0].selectize;
                                var options = $('.item');
                                $.each(options, function(key, value) {
                                    // 1. Get the value
                                    var data_value = $(value).attr('data-value');

                                    // 2. Remove the option if it is not the last added
                                    if (data_value !== str) {
                                        selectSizeControl.removeItem(data_value);
                                        selectSizeControl.refreshItems();
                                        selectSizeControl.refreshOptions();
                                    }
                                });
                                console.log('item added');
                                filled_gym_name(i, false, str);
                                this.close();
                            });

                            $gym_name[0].selectize.on('change', function(value) {
                                if (value == null) {
                                    $dom.input.multi_locations[i]['address'].val('');
                                }
                            });
                        }

                    }(i));
                }
            }

            /*editing additional locations*/

            if ($dom.input.edit_training_locations.length > 0) {

                if (params.edit_inhome == true) {
                    initializeLocationFields($dom.input.primary_location, $dom.input.miles_willing_to_travel);
                    $('.row-primary-gym').addClass('hidden');
                    $('.row-primary-in-home').removeClass('hidden');
                } else {
                    $('.row-primary-gym').removeClass('hidden');
                    $('.row-primary-in-home').addClass('hidden');
                }

                var locations = 0;

                for (var i = $dom.input.multi_locations.length - 1; i >= 1; i--) {
                    var type_value = get_checkbox_value($($dom.input.multi_locations[i]['type']));
                    if ($dom.input.multi_locations[i]['gym_pk'].val()) {
                        $dom.input.multi_locations[i]['make_primary'].removeClass('hidden');
                        $dom.input.multi_locations[i]['make_primary'].attr('pk', $dom.input.multi_locations[i]['pk'].val());
                        $dom.input.multi_locations[i]['delete_button'].removeClass('hidden');
                        $dom.input.multi_locations[i]['delete_button'].attr('pk', $dom.input.multi_locations[i]['pk'].val());
                        $dom.input.multi_locations[i]['cancel_button'].addClass('hidden');
                    }
                    switch (type_value) {
                        case 'g':
                            $dom.input.multi_locations[i]['type'].parents('.form-group').hide();
                            locations += 1;
                            break;
                        case 'c':
                            $dom.input.multi_locations[i]['type'].parents('.form-group').hide();
                            $dom.map_container.parent().appendTo($dom.input.multi_locations[i]['row']);
                            $dom.map_container.show();
                            initializeLocationFields($dom.input.multi_locations[i]['address'], $dom.input.multi_locations[i]['miles']);
                            $dom.input.multi_locations[i]['delete_button'].removeClass('hidden');
                            $dom.input.multi_locations[i]['delete_button'].attr('pk', $dom.input.multi_locations[i]['pk'].val());
                            locations += 1;
                            break;
                        default:
                            $dom.input.multi_locations[i]['delete_button'].addClass('hidden');
                            $dom.input.multi_locations[i]['row'].addClass('hidden');
                    }
                }

                /*Cheking if we enable remove primary location or not*/
                var first_additional = $dom.input.multi_locations[1];
                var first_option = get_checkbox_value($($dom.input.multi_locations[1]['type']));
                if (first_option && (((first_option == 'g') && ($($dom.input.multi_locations[1]['gym_pk']).val())) || first_option == 'c')) {
                    $dom.input.delete_primary.removeClass('hidden');
                    if (params.edit_inhome == true) {
                        $dom.input.delete_primary.appendTo($dom.map_container.parent());
                    }
                    $dom.input.delete_primary.on('click', function(e) {
                        e.preventDefault();
                        $.ajax({
                                method: "POST",
                                url: '../../remove_primary_location/',
                                data: {}
                            })
                            .done(function(data) {
                                window.location.reload();
                            })
                            .fail(function(response) {
                                console.log(response);
                            })
                    });
                } else {
                    $dom.input.delete_primary.addClass('hidden');
                }

                if (locations == 3) {
                    $('#add-location').hide();
                } else {
                    $('#add-location').show();
                }

                if ((params.edit_inhome != true) && ($('input[value="c"]:checked').length == 0)) {
                    $dom.map_container.hide();
                }

                if (params.edit_inhome == true) {
                    $dom.map_container.parent().appendTo($('.primary_location'));
                    $dom.map_container.show();
                    initializeLocationFields($('input[name=primary_location]'), $('input[name=miles]'));
                    $('input[value="c"]').prop('readonly', true);
                    $('input[value="c"]').each(function() {
                        if ($(this).is(':checked') == false) {
                            $(this).parent().hide();
                        }
                    });
                }

                $('.make-primary').on('click', function(e) {
                    e.preventDefault();
                    $.ajax({
                            method: "POST",
                            url: '../../make_location_primary/',
                            data: { 'pk': $(this).attr('pk') }
                        })
                        .done(function(data) {
                            window.location.reload();
                        })
                        .fail(function(response) {
                            console.log(response);
                        })
                })

                $('.remove-loc').on('click', function(e) {
                    var pk = $(this).attr('pk');
                    e.preventDefault();

                    if (pk) {
                        $.ajax({
                                method: "POST",
                                url: '../../remove_addl_location/',
                                data: { 'pk': pk }
                            })
                            .done(function(data) {
                                window.location.reload();
                            })
                            .fail(function(response) {
                                console.log(response);
                            })
                    }

                })

            }
        }
    };

}(GoogleMap));