var HEALTH_CLUB = 1,
    PRIVATE_STUDIO = 2,
    IN_HOME = 4;

Date.prototype.addHours= function(h) {
    this.setHours(this.getHours()+h);
    return this;
};

Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + days);
    return this;
};

Date.prototype.apiFormat = function(){
    var year = this.getFullYear();

    var zeropadded = function(str) {
        return ('0' + str).slice(-2)
    }

    var month = zeropadded(this.getMonth()+1)
    var day = zeropadded(this.getDate())

    return year + '/' + month + '/' + day;
};