var trainerPaymentInfo = (function () {

    var method = '[METHOD]',
        PAYPAL = 'p',
        CHASE_QUICKPAY = 'q',
        DIRECT_DEPOSIT = 'd',
        VENMO = 'v',
        CHECK = 'c';

    function checkPaymenthMethodField() {
        var $label = $('#div_id_other_methods_email label'),
            $payment_method = $('#id_payment_method');

        $label.text($label.text().replace(method, $payment_method.find('option:selected').text()));
        method = $payment_method.find('option:selected').text();

        if ($.isInArray($payment_method.val(), [PAYPAL, CHASE_QUICKPAY, VENMO])) {
            $('#div_id_other_methods_email').show();
        } else {
            $('#div_id_other_methods_email').hide();
        }

        if ($payment_method.val() === DIRECT_DEPOSIT) {
            $('#div_id_bank_name, #div_id_routing_number, #div_id_account_number,' +
              '#div_id_ssn_or_ein, #div_id_account_number_confirmation, #div_id_legal_name').show();
        } else {
            $('#div_id_bank_name, #div_id_routing_number,' +
              '#div_id_account_number, #div_id_ssn_or_ein,' +
              '#div_id_legal_name, #div_id_account_number_confirmation').hide();
        }

        if ($payment_method.val() === CHECK) {
            $('#div_id_check_mailing_address').show();
        } else {
            $('#div_id_check_mailing_address').hide();
        }
    }

    return {
        init: function () {
            checkPaymenthMethodField();
            $('#id_payment_method').change(checkPaymenthMethodField);
        }
    };

}());