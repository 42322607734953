var CouponsHandler = (function() {

  function handleMemberCredit(member_credit) {
    const credit = Math.trunc(member_credit);
    if (credit) {
      var currentTotal = $("#total_price").text();
      currentTotal = currentTotal.substring(1, currentTotal.length);
      var creditToShow = credit;
      if (credit > currentTotal) {
        creditToShow = currentTotal;
      };
      console.log(creditToShow);
      const memberCreditDiv = $("<p></p>").append([
        $('<span class="name"></span>').text("Credits"),
        $('<span class="amount"></span>').text("-$" + creditToShow)
      ]);
      $("#subtotal").append([
        $('<p><span class="name">Subtotal</span><span class="amount">$' + currentTotal +'</span></p>'),
        "<br/>",
        memberCreditDiv
      ]);
      var total = currentTotal - credit;
      if (total < 0) total = 0;
      $("#total_price").text("$" + total);
    }
  }

  function handlePromoCodeInput() {
    var openPromoCode = false;
    $(".promo-code").click(function() {
      openPromoCode = !openPromoCode;
      if (openPromoCode) {
        //$("#find-one").append("Find one now!")
        $("#enter-code").addClass("active");
      } else {
        //$("#find-one").empty();
        $("#enter-code").removeClass("active");
      }
    })
  }

  function handleErrorMessage(error) {
    const key = Object.keys(error)[0];
    const errorMessage = error[key];
    $(".error-msg").text(errorMessage);
    setTimeout(function() {
      $(".error-msg").empty();
    }, 3000);
  }

  function handleSuccessPromoCode(data) {
    const dataKeys = Object.keys(data);
    var codeData = {};
    for (var i=0; i<dataKeys.length; i++) {
      const key = dataKeys[i];
      if (data[key]) codeData[key] = data[key];
    };
    localStorage.setItem("codeData", JSON.stringify(codeData));
  }

  function showSubtotal() {
    var sessionPrice = parseInt($("#session_rate_price").text().replace("$", ""))
    var numOfSessions = parseInt($("#n_sessions").text().replace("x", ""))
    var subTotal = sessionPrice * numOfSessions;
    var codeData = JSON.parse(localStorage.getItem("codeData"));
    const subtotalDiv = $("#subtotal");
    if (codeData && subtotalDiv.is(':empty')) {
      const subtotal = $('<p><span class="name">Subtotal</span><span class="amount">$' + subTotal +'</span></p>');
      var spanCode = $('<span class="name"></span>').text("Promo Code: " + codeData.code);
      var keys = Object.keys(codeData)[1];
      var spanAmount = $('<span class="amount"></span>').text("-$" + Math.trunc(codeData[keys]));
      var promoCode = $('<p></p>').append([spanCode, spanAmount]);
      $(subtotalDiv).append([subtotal, "<br/>", promoCode]);
      $("#total_price").text("$" + (subTotal - codeData.credit));
    } else {
      $(subtotalDiv).empty();
    }
  }

  function formatServiceToSend() {
    const currentService = $(".select-wrapper[id='2']").find(".normal").text();
    var serviceToSend = null;
    switch (currentService) {
      case "Individual Training":
        serviceToSend = 'Individual training';
        break;
      case "Partner Training":
        serviceToSend = 'Partner training (2 people)';
        break;
      case "Small Group Training":
        serviceToSend = 'Small group training (up to 6 people)';
        break;
      default:
        return null;
    }

    return serviceToSend;
  }
  
  function validateCoupon(introPrice) {
    $("#submit-promo-code").click(function() {
      var code = $("#enter-code").find(".form-control").val();
      var serviceType = formatServiceToSend();
      var subscriptionType = null;
      if (introPrice) { 
        subscriptionType = 1;
      } else {
        subscriptionType = JSON.parse(localStorage.getItem("booking_data")).plan_id;
      }
      $.ajax({
          url: '/fyt/book/validate/coupon/',
          method: 'POST',
          data: {
              'code': code,
              'service_type': serviceType,
              'booking_type': 'subscription',
              'subscription_type_id': subscriptionType,
              'intro_price': introPrice,
          },
          success: function(data) {
            handleSuccessPromoCode(data);
            showSubtotal();
          },
          error: function (error) {
            const errorToSend = JSON.parse(error['responseText']);
            handleErrorMessage(errorToSend);
          }
      })
    });
  }

  function revalidateCoupon(codeToReview, introPrice) {
    var code = codeToReview;
      var serviceType = formatServiceToSend();
      if (introPrice) { 
        subscriptionType = 1;
      } else {
        subscriptionType = JSON.parse(localStorage.getItem("booking_data")).plan_id;
      }
      $.ajax({
          url: '/fyt/book/validate/coupon/',
          method: 'POST',
          data: {
              'code': code,
              'service_type': serviceType,
              'booking_type': 'subscription',
              'subscription_type_id': subscriptionType,
              'intro_price': introPrice,
          },
          success: function(data) {
            handleSuccessPromoCode(data);
            showSubtotal();
          },
          error: function (error) {
            localStorage.removeItem("codeData");
            $("#subtotal").empty();
          }
      })
  }

  return {
    init: function(member_credit, introPrice) {
      handleMemberCredit(member_credit);
      handlePromoCodeInput();
      validateCoupon(introPrice);
      if (JSON.parse(localStorage.getItem("codeData"))) {
        const code = JSON.parse(localStorage.getItem("codeData")).code;
        revalidateCoupon(code, introPrice);
      }
    },
    revalidateCoupon: function(code, introPrice) {
      revalidateCoupon(code, introPrice);
    }
  }
})();