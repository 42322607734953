/* globals bootbox, popupFormBuilder */

var makeNotePopupForm = (function (bootbox, popupFormBuilder) {

    var popupForm = popupFormBuilder();

    return {
        init: function (params) {

            var postSuccessCallback = params.postSuccessCallback || function () {return; },
                modal_selector = params.modal_selector || "#new-message-modal";

            return popupForm.init({
                openModalBtnSelector: params.openModalBtnSelector,
                modalSelector: modal_selector,
                templateSelector: "#make-a-note-template",
                url: params.url,
                validators: [
                    popupForm.buildNotEmptyValidator('note', '.note-is-empty'),
                ],
                postSuccessCallback: function (context) {
                    var $button = $("a[data-slot_id=" + context['initialData']['slot_id'] + "]");
                    console.log($button);
                    if($button.hasClass('make-a-note')){
                        $button.parent().html('<a class="show-note" data-slot_id='+ context['initialData']['slot_id'] + ' href="#"><span class="glyphicon glyphicon-book"></span><span class="count">1</span></a>');
                    } else {
                        console.log('exits');
                        var oldvalue = parseInt($button.children('span.count').text());
                        $button.children('span.count').text(oldvalue+1);
                    }
                    bootbox.alert(
                        "Note created!",
                        function () {
                            postSuccessCallback(context);
                        }
                    );
                },
            });

        },
    };

}(bootbox, popupFormBuilder));


