var SettingsEditPhoto = (function () {

    return {

        init: function () {
            
            if ($('#avatar-preview').attr('src').indexOf('static/images/avatar') > 0) {
                $('#avatar-preview').css('filter', 'opacity(.4)');
                $('#add-avatar').show();
            }
        
            $('a#add-picture').click(function() { $('input[name=avatar]').click(); })
            
            ImageCropper.init({
                input: 'input[name="avatar"]',
                preview: '#photo-preview',
                modal: '#modal-photo-preview',
            })
        
            $('#save-photo').click(function() {
                ImageCropper.upload({
                    payload: {'update_avatar': true},
                    onSuccess: function () {
                        window.location.reload()
                    },
                    onError: function (r) {
                        console.log(r.responseJSON)
                        $feedback.text('Upload error').show();
                    }
                })
            })

        }
    }; 
}());