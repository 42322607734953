var RewardsSwell = (function () {

    var get_static_prefix

    return {

        init: function (params) {

            get_static_prefix = params.get_static_prefix

            $(document).on("swell:initialized", function() {
                var how_your_customers_earn_points = $('#how_your_customers_earn_points')

                if (how_your_customers_earn_points.length != 0) {
                    
                    var how_your_customers_fyt = $('#how_your_customers_fyt')
                    var referer_friend = $('#referer_friend')
                    var div_earn_points = $('#earn_points')

                    var data_campaign = {
                        410443 : { 'id': 410443,  'img_url' : get_static_prefix+"img/rewards/Icons/create-account.png", 'dynamic' : false },
                        410449 : { 'id': 410449,  'img_url' : get_static_prefix+"img/rewards/Icons/refer-friend.png", 'dynamic' : false },
                        426337 : { 'id': 426337,  'img_url' : get_static_prefix+"img/rewards/Icons/session.png", 'dynamic' : false },
                        426338 : { 'id': 426338,  'img_url' : get_static_prefix+"img/rewards/Icons/review.png", 'dynamic' : true },
                        426339 : { 'id': 426339,  'img_url' : get_static_prefix+"img/rewards/Icons/instagram.png", 'dynamic' : true },
                        426340 : { 'id': 426340,  'img_url' : get_static_prefix+"img/rewards/Icons/facebook.png", 'dynamic' : true },
                        431543 : { 'id': 431543,  'img_url' : get_static_prefix+"img/rewards/Icons/facebook.png", 'dynamic' : true },
                        410445 : { 'id': 410445,  'img_url' : get_static_prefix+"img/rewards/Icons/birthday.png", 'dynamic' : false },
                    }

                    var order = [410443,410449,426337,426338,426339,426340,431543,410445]

                    var activeCampaigns = swellAPI.getActiveCampaigns();

                    activeCampaigns.forEach(function(campaign){
                        if (data_campaign[campaign['id']]) {    
                            data_campaign[campaign['id']].title = campaign['title'];
                            data_campaign[campaign['id']].rewardText = campaign['rewardText'];
                        } 
                    });

                    order.forEach(function(i){
                      
                      if ( i !== 410449 ) {
                        var html = how_your_customers_earn_points.html()
                        var content = _.template(html)(data_campaign[i]);
                      }else {
                        var html = referer_friend.html()
                        var content = _.template(html)(data_campaign[i]);   
                      }
                      div_earn_points.append(content);

                    })

                    var html = how_your_customers_fyt.html()
                    var content = _.template(html);
                    div_earn_points.append(content);

                }

                var RedemptionOptions = swellAPI.getActiveRedemptionOptions();

                $.each(RedemptionOptions, function( k, value ) {
                    var html = $('#get_redeem_points_template').html()
                    var content = _.template(html)(value);
                    $('#get_redeem_points_modal').append(content);
                });

            });
            
            $(document).on("swell:setup", function(){

                var copylink = swellAPI.getCustomerShareReferralLink('copy_link');
                $("#referral-code").html(copylink);
                console.log(copylink);
                
                var redemptionOptionSelect = $(".redemption-option-select");

                redemptionOptionSelect.click(function(){

                    var fillAndSubmitCouponCodeForm = function(couponCode) {
                        // set the value for the coupon code input
                        $("#coupon-code-input-element").val(couponCode);
                        // trigger a click on the submit button
                        $("#coupon-code-submit-btn").click();
                    };

                    var onSuccess = function(redemption) {
                        fillAndSubmitCouponCodeForm(redemption.couponCode);
                        var html = $('#get_redeem_points_success_template').html()
                        var content = _.template(html)(redemption);
                        $('#get_redeem_points_msg').empty();
                        $('#get_redeem_points_msg').append(content);
                    };
                    var onError = function(err) {
                        var html = $('#get_redeem_points_errors_template').html()
                        var content = _.template(html)(err);
                        $('#get_redeem_points_msg').empty();
                        $('#get_redeem_points_msg').append(content);
                    };

                    swellAPI.makeRedemption(
                        { redemptionOptionId: $(this).data('redemptionOptionId') },
                        onSuccess,
                        onError
                    );
                })

                /* LOAD template */
                var your_referrals_details = $('#your_referrals_details')
                var your_referrals_details_script = $('#your_referrals_details_script')
                var doesnt_have_referrals = $('#doesnt_have_referrals')


                /* EMAIL REFER */
                var referredCustomersInput = $(".shared_emails_input");
                var sendEmailsBtn = $(".shared_emails_btn");

                $(sendEmailsBtn).click(function() {

                  var onSuccess = function() {
                    $('#refer_friend_success_modal').modal('show')              
                  }

                  var onError = function(err) {
                    $('#refer_friend_errors_modal').modal('show')
                  }
                  
                  for (var i = 0; i < referredCustomersInput.length; i++) {
                    var val = $(referredCustomersInput[i]).val() 
                    if (val) {
                      var emails = val.split(",");
                      swellAPI.sendReferralEmails(emails,onSuccess, onError);
                      break;
                    }
                  }

                });              
                
                /* SHARED ICONS */
                $(".primary-redeem-btn").click(function(){
                    $('#redeem_points_modal').modal('show')
                })

                /* Submit your email below to register */
                var customersInput = $(".customers-input");
                var sendEmailBtn = $(".customers-send-btn");
  
                $(sendEmailBtn).click(function() {
                  var email = customersInput.val();
                  window.location.href = window.URLS.register  + "?email=" + email + "&next=" + window.URLS.referrals
                });

                $('#close_success_refer').click(function(){
                    location.reload();
                })

                /* Calculate points need */

                var vip_classes = $('.vip_classes')
                console.log(vip_classes.length )
                if (vip_classes.length != 0) {
                    var number_points_need_template = $('#number_points_need_template')

                    var customerDetailsInitialized = swellAPI.getCustomerDetails();
                    var pointsEarned = customerDetailsInitialized.pointsEarned;
                    if (pointsEarned < 1500) { number = 1500 - pointsEarned } else { number = 3000 - pointsEarned }
                    class_info = {
                        'advanced' : true ? pointsEarned > 1500 : false,
                        'elite': true ? pointsEarned > 3000 : false,
                        'number': number ,
                    }

                    var html = number_points_need_template.html()
                    var content = _.template(html)(class_info);
                    vip_classes.append(content);
                }


                /* REFERER DATA */
                var referer_data = function() {
                    
                    var customerDetails = swellAPI.getCustomerDetails();
                    
                    if (customerDetails['referrals'].length != 0 ) {
                        unique_referrals = [] 
                        for(var i = customerDetails['referrals'].length - 1; i >= 0; i--) {
                            if (jQuery.inArray( customerDetails['referrals'][i]['email'] , unique_referrals ) == -1) {
                                unique_referrals.push(customerDetails['referrals'][i]['email'])
                                var data_reference = {
                                    email : customerDetails['referrals'][i]['email'],
                                    complete : customerDetails['referrals'][i]['completedAt'],
                                }
                                var html = your_referrals_details_script.html()
                                var content = _.template(html)(data_reference)
                                your_referrals_details.append(content)
                            }
                        }
                    } else {
                        var html = doesnt_have_referrals.html()
                        var content = _.template(html)
                        your_referrals_details.append(content)
                    }
                }

                referer_data();
                
            })
        }
    }; 
}());