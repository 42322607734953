/*globals
    jQuery,
    bootbox,
*/

var reviews = (function ($, bootbox) {

    var $reviewModal,
        $reviewForm,
        $openPopupBtn,
        createReviewUrl,
        ratyImagesPath,
        trainerName,
        title,
        content,
        stars;

    function getRatyOptions() {
        var options = {
            path: ratyImagesPath,
            hints: ['bad', 'poor', 'regular', 'good', 'great'],
            click: function (score) {
                $('[name=stars]').val(score);
            }
        };
        if (stars) {
            options.score = stars;
        }
        return options;
    }

    function openReviewModal() {

        var rid = $(this).data('trainer'),
            rname = $(this).data('trainer-name');
        title = $(this).data('review-title');
        content = $(this).data('review-content');
        stars = $(this).data('review-stars');

        trainerName = rname;

        $("#review-form [name=target]").val(rid);
        $(".review-target-name").text(rname);

        if (title) {
            $("#review-form [name=title]").val(title);
        }
        if (content) {
            $("#review-form [name=content]").val(content);
        }
        if (stars) {
            $("#review-form [name=stars]").val(stars);
        }

        $reviewModal.modal('show');
        $('#stars-widget').raty(getRatyOptions());
    }

    function submitReview(e) {

        e.preventDefault();

        var data = $reviewForm.serialize(),
            errorsCount = 0;

        title = $reviewForm.find("[name=title]").val();
        content = $reviewForm.find("[name=content]").val();
        stars = $reviewForm.find("[name=stars]").val();

        if (title === "") {
            $reviewForm.find(".title-is-empty").show().fadeOut(6000);
            errorsCount++;
        }
        if (content === "") {
            $reviewForm.find(".body-is-empty").show().fadeOut(6000);
            errorsCount++;
        }
        if (stars === "") {
            $reviewForm.find(".stars-required").show().fadeOut(6000);
            errorsCount++;
        }

        if (errorsCount > 0) {
            return;
        }

        $.ajax({
            dataType: 'json',
            type: 'POST',
            url: createReviewUrl,
            data: data,
            success: function (response) {
                if (response.errors) {
                    console.log(response.errors);
                } else {
                    $openPopupBtn.data('review-title', title);
                    $openPopupBtn.data('review-content', content);
                    $openPopupBtn.data('review-stars', stars);
                    $openPopupBtn.text("Edit my review");
                    $reviewForm.trigger("reset");
                    $reviewModal.modal('hide');
                    bootbox.alert("Thanks for your review of " + trainerName + "!");
                }
            },
            error: function () {
                bootbox.alert("Internal error");
            }
        });

    }

    return {
        init: function (params) {

            $reviewModal = $("#review-modal");
            $reviewForm = $reviewModal.find('form');
            $openPopupBtn = $('.write-review');
            createReviewUrl = params.createReviewUrl;
            ratyImagesPath = params.ratyImagesPath;
            trainerName = params.trainerName;

            $openPopupBtn.on('click', openReviewModal);
            $('#submit-review').on('click', submitReview);
        }
    };

}(jQuery, bootbox));
