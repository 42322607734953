var PressSection = (function($) {

	var anchorPress = $('#anchor-press');
	var activePress = $('#active-press');
	var activePressQuote = $(".active-press-description");
	var pressItems = $(".column-press");
	var clickedItem = false;
	var lastSrc, selectedItem;
	var pressIndex = 0;

	function arrayObjectIndexOf(array, searchTerm, property) {
	    for(var i = 0, len = array.length; i < len; i++) {
	        if ($(array[i]).children().attr(property) === searchTerm) return i;
	    }
	    return -1;
	}
    
    return {
        init: function() {

			setInterval(function(){ 
				if(!clickedItem){
					var fadeOutActivePressDone = activePress.fadeOut(1000).promise();
					var fadeOutActivePressQuoteDone = activePressQuote.fadeOut(1000).promise();

					Promise.all([fadeOutActivePressDone, fadeOutActivePressQuoteDone]).then(function(value) {
						if(!clickedItem){
							//remove current classes
							pressItems.each(function() {
								$(this).removeClass('current-press');
							}); 
							$(pressItems[pressIndex]).addClass('current-press');
							activePress.attr("src",
								$(pressItems[pressIndex]).children().attr('src')
							);
							activePress.attr("alt", 
								$(pressItems[pressIndex]).children().attr('alt')
							);
							activePress.attr("title", 
								$(pressItems[pressIndex]).children().attr('title')
							);
							anchorPress.attr("href", 
								$(pressItems[pressIndex]).children().data("url")
							);
							activePressQuote.text(
								$(pressItems[pressIndex]).children().data("quote")
							);
							activePress.fadeIn(1000);
							activePressQuote.fadeIn(1000);

							if(pressIndex==pressItems.length-1){
								pressIndex = 0;
							} else {
								pressIndex = pressIndex+1;
							}
						}
					}, function(reason) {
						console.log(reason)
					});
				}
			}, 3500);

			pressItems.on('click', function(){
				clickedItem = true;
				activePress.attr("src", $(this).children().attr('src'));
				activePress.attr("alt", $(this).children().attr('alt'));
				activePress.attr("title", $(this).children().attr('title'));
				anchorPress.attr("href", $(this).children().data("url"));
				pressItems.each(function() {
					$(this).removeClass('current-press');
				});
				$(this).addClass('current-press');
				activePressQuote.text(
					$(this).children().data("quote")
				);
				activePress.fadeIn(500);
				activePressQuote.fadeIn(500);
				if(arrayObjectIndexOf(pressItems, $(this).children().attr('src'), 'src')!==-1){
					pressIndex = arrayObjectIndexOf(pressItems, $(this).children().attr('src'), 'src') + 1;
				}
				setTimeout(function(){
					console.log('play animation');
					clickedItem = false;
				}, 3500);
			});
        }
    }
})(jQuery)