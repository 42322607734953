var YoutubeModal = (function (){

    var config = {
        trigger: '.youtube-video',
        modal: '#modal-youtube',
        rel: 0,
        autoplay: 0,
        showinfo: 0
    }

    var in_safari = (navigator.userAgent.indexOf('Safari') != -1
                    && navigator.userAgent.indexOf('Chrome') == -1)

    var setupModal = function() {
        var modal = config.modal || $elem.data('modal');

        // click event
        $('body').on('click', config.trigger, function(e) {
            var elem = e.currentTarget;

            var params = {
                'autoplay': config.autoplay,
                'showinfo': config.showinfo,
                'rel': config.rel,
            }

            var embed = $(elem).data('embed') || $(elem).data('src').toYouTubeEmbedLink();

            var $iframe = $('<iframe>', {
                src: embed + '?' + $.param(params)
            });

            $iframe
                .attr('width', '100%')
                .attr('height', '100%')
                .attr('frameborder', '0')
                .attr('allowfullscreen', '')

            $(modal + ' #player').html($iframe);
            $(modal).modal('show');
        })

        // hidden modal event
        $(modal).on('hidden.bs.modal', function() {
            $(modal + ' iframe').remove();
        })
    }

    var setupLink = function() {
        $(config.trigger).each(function(e) {
            var elem = e.currentTarget;

            var src = $(elem).data('embed') || $(elem).data('src');
            var params = {
                'autoplay': config.autoplay,
                'showinfo': config.showinfo,
                'rel': config.rel,
            }

            $(elem)
                .attr('href', src + '?' + $.param(params))
                .attr('target', '_blank')
        })
    }

    String.prototype.toYouTubeEmbedLink = function() {
        var vid = this.split('?v=')[1]
        return '//youtube.com/embed/' + vid;
    }

    return {
        init: function(opts) {
            $.extend(config, opts)

            if (in_safari) {
                setupLink()
            } else {
                setupModal();
            }
        }
    }
})()