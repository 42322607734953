var FusionTables = {

    API_KEY: 'AIzaSyCM53XPdChmUIBxVqaDM3xO-JnkWbHLBc4',
    US_ZIPCODES_ID: '1cJO6B7_WatUoaDmjWElwKOMsmZn3RBjVYUXJ1kDJ',
    US_STATES_ID: '1CVb2EEf7sb5AoCR2nBpHQFpBxrJ7pc-DGEhiJ08Y',

    url: 'https://www.googleapis.com/fusiontables/v2/query?',

    query: function(sql, callback) {
        var url = this.url + $.param({
            'key': this.API_KEY,
            'sql': sql
        })

        $.getJSON(url, function(data) {
            var columns = {}
            var rows = []

            // map columns to indexes
            $.each(data.columns, function(key, value) {
                columns[value] = key
            })

            // rows
            if (data.rows) {
                rows = data.rows;
            }

            if (callback) callback(columns, rows);
        })

        .fail(function() {
            console.log('FusionTables is failing.')
            if (callback) callback({}, [])
        })
    },

    zipcodesByLatLng: function(lat, lng, radius, callback) {

        var sql = 'SELECT PONAME, ZIP ' +
                  'FROM ' + this.US_ZIPCODES_ID + ' WHERE ' +
                  'ST_INTERSECTS(geometry, CIRCLE(LATLNG('+lat+','+lng+'), '+radius+'))'

        this.query(sql, function(columns, rows) {
            var zipcodes = []

            $.each(rows, function(key, value) {
                var zipcode = value[columns.ZIP]
                zipcodes.push(zipcode)
            })

            if (callback) callback(zipcodes);
        });

    },

    zipcodesByRectangle: function(e, n, w, s, callback) {

        var sql = 'SELECT PONAME, ZIP ' +
                  'FROM ' + this.US_ZIPCODES_ID + ' WHERE ' +
                  'ST_INTERSECTS(geometry, RECTANGLE(LATLNG('+s+','+w+'), LATLNG('+n+','+e+')))'

        this.query(sql, function(columns, rows) {
            var zipcodes = []

            $.each(rows, function(key, value) {
                var zipcode = value[columns.ZIP]
                zipcodes.push(zipcode)
            })

            if (callback) callback(zipcodes);
        });

    },

    renderZipcodes: function(map, zipcodes) {

        var layer = new google.maps.FusionTablesLayer({
            query: {
                select: 'geometry',
                from: FusionTables.US_ZIPCODES_ID,
                where: 'ZIP IN (' + zipcodes.join(',') + ')'
            },
            options: {
                styleId: 2,
                templateId: 2
            }
        });

        layer.setMap(map);

    },

    getBounds: function(zipcodes, callback) {
        var self = this;

        var sql = 'SELECT longitude, latitude, PONAME ' +
                  'FROM ' + FusionTables.US_ZIPCODES_ID + ' WHERE ' +
                  'ZIP IN (' + zipcodes.join(',') + ')'

        this.query(sql, function(columns, rows) {
            var areas = {}
            var bounds = new google.maps.LatLngBounds();

            $.each(rows, function(key, value) {
                var name = value[columns.PONAME]
                var lat = value[columns.latitude]
                var lng = value[columns.longitude]

                if (self._isNaN(lat) || self._isNaN(lng))
                    return;

                var coords = new google.maps.LatLng(lat, lng)
                // extend bounds
                bounds.extend(coords)
                // add area
                areas[name] = (areas[name] || 0) + 1
            })

            if (callback) callback(bounds, areas)
        })
    },

    _isNaN: function(value) {
        return value === "NaN" || value === undefined;
    }
}