var IN_HOME = 4;

$(document).ready(function () {
    var containerWidth = $('.container').outerWidth();

    // Always hidden and only show the popup if no user logged or no cookie created
    function getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
    }
    // var visited = getCookie('signup_modal');
    // if (visited === undefined || visited === 'False') {
    //     setTimeout(function() {
    //         $('#modal-signup, .modal-backdrop').show();
    //         $('.modal-backdrop').addClass('in');
    //     }, 30000);
    // } else {
    //     $('#modal-signup, .modal-backdrop').hide();
    // }
    function createCookie(name, value, days) {
       var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            var expires = '; expires=' + date.toGMTString();
        }
        document.cookie = name + '=' + value + expires + '; path=/';
    }
    // $.getJSON('/is_user_logged_in/', function (logged_in) {
    //     if (logged_in) {
    //         createCookie('signup_modal','True',0);
    //       }
    // });
    // hack for booking flow
    var amount_charged = $('.container-details').data('credits');
    if (amount_charged === 0.00){
        $('.contact-details').addClass('no-charge');
    }
    // nav redirect
    $('footer .info').on('click', function (e) {
        e.preventDefault();
        var targetUrl = '/fyt/info/#about';
        window.location.href = targetUrl;
    });
    $('footer .contact').on('click', function (e) {
        e.preventDefault();
        var targetUrl = '/fyt/info/#contact';
        window.location.href = targetUrl;
    });
    $('footer .faq').on('click', function (e) {
        e.preventDefault();
        var targetUrl = '/fyt/info/#faq';
        window.location.href = targetUrl;
    });
    $('footer .request').on('click', function (e) {
        e.preventDefault();
        var targetUrl = '/fyt/info/#club';
        window.location.href = targetUrl;
    });
    // Safari browser back to homepage bug
    window.onpageshow = function (event) {
    if (event.persisted) {
            window.location.reload();
        }
    };

    // homepage submit
    $('#zipcode').keyup(function (event) {
        if (event.keyCode === 13) {
            $('#submit-btn').click();
        }
    });
    // price manager disable enter key
    $('[contenteditable="true"]').keydown(function (event) {
        if (event.which === 13) {
            event.preventDefault();
            $(this).next('[contenteditable="true"]:first').focus();
        }
    });
    //toggle div
    $('.toggle-click').click(function () {
        $('.toggle').toggle();
    });
    function close_pop() {
        $('#dialog-overlay,.popups-container').hide();
    }

    $('#trainer-schedule .btn-group').find('.dropdown-menu li').click(function () {
        var itemSelect = $(this).text();
        $(this).parents('.btn-group').find('.btn:eq(0)').text(itemSelect);
    });
    // reposition datepicker on schedule popups
    $('input#repeat_enddate').datepicker({
        beforeShow: function (input, inst) {
            inst.dpDiv.css({
                marginRight: input.offsetWidth + 'px'
            });
        }
    });
    $('#id_newgym_name').change(function () {
        var itemSelect = $(this).val();
        if (itemSelect === '15') {
            $(this).parents('form').find('.request-club').css('display', 'block');
        } else {
            $(this).parents('form').find('.request-club').css('display', 'none');
        }
    });
    // accordion image swapp
    $('.accordion-body').on('show', function () {
        $(this).siblings('.accordion-heading').find('.accordion-toggle').addClass('minus');
        console.log('Entro show');
    });
    $('.accordion-body').on('hide', function () {
        $(this).siblings('.accordion-heading').find('.accordion-toggle').removeClass('minus');
        console.log('Entro hide');
    });

    // modal position
    if (!Modernizr.touch && containerWidth > 723) {
        $('.modal-clasp').on('click', function (){
            var windowPosition = $(window).scrollTop();
            $('.modal.fade.in').css({
                'top': windowPosition+20,
                'position': 'absoulte'
            });
        });
    } else {
        $('.modal-clasp').on('click', function (){
            var windowPosition = $(window).scrollTop();
            $('.modal.fade.in').css({
                'top': windowPosition,
                'position': 'absolute'
            });
        });
    }

    // center modals w/ class .modal-vcenter
    // source: http://www.minimit.com/demos/vertical-center-bootstrap-3-modals
    function centerModals($element) {
      var $modals;
      if ($element.length) {
        $modals = $element;
      } else {
        $modals = $('.modal-vcenter:visible');
      }
      $modals.each( function(i) {
        var $clone = $(this).clone().css('display', 'block').appendTo('body');
        var top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2);
        top = top > 0 ? top : 0;
        $clone.remove();
        $(this).find('.modal-content').css("margin-top", top);
      });
    }
    $('.modal-vcenter').on('show.bs.modal', function(e) {
      centerModals($(this));
    });
    $(window).on('resize', centerModals);

    // close popovers modal window
    $('[data-dismiss="window"]').on('click', function () {
        $('.popups-container').hide();
        $('#mouseover_pop').hide();
        $('#dialog-overlay').hide();
    });
    // info/learn more page animate scroll
    $('#sub-nav a[href^="#"]').on('click', function (e) {
        $('.subnav nav .nav li a').removeClass('active');
        e.preventDefault();
        var target = this.hash;
        $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
        $(this).addClass('active');
    });

    // contact us form
    var contactTest = $('#contact p');
    if ( contactTest.hasClass('label')) {
        $('#contactForm').hide();
    }

    var lastId,
    topMenu = $('#sub-nav'),
    topMenuHeight = topMenu.outerHeight();
    $(window).scroll(function () {
        var fromTop = $(this).scrollTop()+topMenuHeight;
        if (fromTop < 97 && fromTop < 640) {
            $('#sub-nav ul li:nth-child(2)').removeClass('active');
            $('#sub-nav ul li:nth-child(1)').addClass('active');
        } else if (fromTop > 640 && fromTop < 800) {
            $('#sub-nav ul li:nth-child(2)').addClass('active');
            $('#sub-nav ul li:nth-child(1)').removeClass('active');
        }
    });

    //////////////////////////////////////////////////
    // Signup Coerce
    //////////////////////////////////////////////////
    $('#trainer_search .alert-error').hide();
    var frm = $('#signup-coerce');
    frm.submit(function (e) {
        $.ajax({
            dataType:'json',
            type: frm.attr('method'),
            url: frm.attr('action'),
            data: frm.serialize(),
            // success means HTTP 200, not form parse success.
            success: function (response) {
                if (response.status === 'failed') {
                    var error_str = '';
                    for (var key in response.data){
                        if (response.data[key][0].indexOf('These field is required') <= -1) {
                        error_str = error_str + response.data[key][0] + ',';
                        }
                    }
                }
                else {
                    //mixpanel.track("NewMember Email Form");
                    //clicky.goal('Create account submitted');
                    //trackFacebookPixel();
                    dataLayer.push({
                        'event': 'email.capture',
                        'source': 'modal'
                    });
                    if ($('.container').hasClass('trigger-signup')){
                        $('#modal-signup, .modal-backdrop').css('display','none');
                        $('#modal-email').modal('show');
                        // $('.container-trainer-results .alert-success').css('display', 'block');
                        window.location.href = "#email_footer";
                    } else {
                        window.location.href = "#email";
                        location.reload();
                    }
                }
            },
            error: function (response) {
                //var error_txt = JSON.parse(response['responseText'])['error']['email'][0];
                $('#signup-coerce .alert-error').show();
            }
        });
        return false;
    });

    // hashes for tracking
    var hashValue = location.hash;
    var loggedInOrEmail = $('.search').hasClass('logged_in_or_email');
    if (loggedInOrEmail === true) {
        if (hashValue !== '#email' && hashValue !== '#account') {
            window.location.href = "#logged_in_or_email";
        }
        else if (hashValue === '#email') {
            $('#trainer-find .alert-success').show();
        }
    } else if ($('.search').hasClass('non-member')) {
        window.location.hash = '';
    }

    //////////////////////////////////////////////////
    // Request a time - form
    //////////////////////////////////////////////////
    var frmRequest = $('#request-availability');
    $('.modal_availability').on('click', function (){
        console.log("dadsdsa");
        var trainerId = $(this).parents('.schedule_result').data('trainer');
        //var is_authenticated = $(this).parents('.user-auth').data('trainer');
        $('.request-trainerid').attr('value', trainerId);
        $('.alert-error').hide();
        var notMember = $('.request-memberid').attr('value');
        //console.log(notMember);
        if (notMember === 'None') {
            $('#request-availability').hide();
            $('.not-logged-in').show();
        } else {
            $('#request-availability').show();
            $('.not-logged-in').hide();
        }
        frmRequest.each(function (){
            this.reset();
        });
    });
    frmRequest.submit(function () {
        var trainerId = $('.request-trainerid').attr('value');

        $.ajax({
            dataType:'json',
            type: frmRequest.attr('method'),
            url: frmRequest.attr('action'),
            data: frmRequest.serialize(),
            // success means HTTP 200, not form parse success.
            success: function (response) {
                if (response.status === 'failed') {
                    var error_str = '';
                    for (var key in response.data){
                        if (response.data[key][0].indexOf('These field is required') <= -1) {
                        error_str = error_str + response.data[key][0] + ',';
                        }
                    }
                    $('.alert-error').show().html('Fill in required field');
                    $('.control-group').addClass('error').find('.help-inline').show();
                }
                else {
                    $('#modal-request-availability, .modal-backdrop').hide();
                    $('#trainer-profile .alert-success').show();
                    $('[data-trainer="'+ trainerId +'"'+']').find('.alert-success').show();
                }
            },
            error: function (response) {
                $('.alert-error').show().html('Please select at least 1 day and time.');
            }
        });
        return false;
    });
    //////////////////////////////////////////////////
    // Booking
    //////////////////////////////////////////////////
    $('.book-appt').on('click', function (){
        $(this).addClass('disabled');
        $('.progress').show();
    });

    $('.trainer_avai_schedule, .schedule_result').on('click', 'span.session-info', function (){
        var trainer = $(this).attr('trainer'),
            trainerStart = $(this).attr('start'),
            trainerEnd = $(this).attr('end'),
            session = $(this).attr('session'),
            backurl = $(this).parents('.week_day_sessions').data('backurl');

        window.location = "/fyt/book/booking_step1/?backurl="+backurl+"&time_slot="+session+"&trainer="+trainer+"&start="+trainerStart+"&end="+trainerEnd;
    });

    function donot_submit() {
        $('.book-appt').on('click', function (e) {
            $('.progress').hide();
            e.preventDefault();
        });
    }
    function disable_btn() {
        $('.book-appt').addClass('disabled');
        $('.booking-it .alert-error').show();
    }
    function enable_btn() {
        $('.book-appt').removeClass('disabled');
        $('.booking-it .alert-error').hide();
        $('.book-appt').unbind('click');
        $('.book-appt').on('click', function () {
            $('.progress').show();
        });
    }
    $('.booking-it .alert-error').hide();
    $('.terms-checkbox').change(function () {
        if ($(this).is(":checked")) {
            enable_btn();
        } else {
            disable_btn();
            donot_submit();
        }
    });
    //////////////////////////////////////////////////
    // account page
    //////////////////////////////////////////////////
   /*
 if (!Modernizr.touch && containerWidth > 723) {
        var containerHeight = $('#account .hero-unit').outerHeight();
        var asideHeight = $('aside header').outerHeight();
        var upNext = $('.up-next').outerHeight();
        if (containerHeight < ){

        }
        $('.up-next').css('height',containerHeight - asideHeight - 40);
    }
*/

    //////////////////////////////////////////////////
    // Outbound link tracking
    //////////////////////////////////////////////////
    $('.outbound').on('click', function (){
        var url = $(this).attr('href');
        dataLayer.push({'event': 'outbound.click', 'url': url});
    });

    // carousel - homepage
    $('.featured-carousel').carousel({
        loader: true,
        timeout: 3000,
        random: true,
        log: false
    });
    $('.trainer-carousel').carousel({
        paused: true,
        log: false
    });

    //////////////////////////////////////////////////
    // Expiration elements
    //////////////////////////////////////////////////
    var now = new Date();
    $('.expire').each(function(idx, elem) {
        var e = $(elem).data('expiration');
        var expiration = new Date(e);
        if (now.getTime() < expiration.getTime()) {
            $(elem).fadeIn(800);
        }
    })

});
//////////////////////////////////////////////////
// Mobile Schedule - Resizes window
//////////////////////////////////////////////////

function mobileSchedule() {
    var containerWidth = $('.container').outerWidth();

    if (containerWidth <= 480) {
        var hourcolumn = $('.hour-day').outerWidth(true);
        $('.day-and-date').css({width: containerWidth-hourcolumn-14});
        $('.availability-column').css({width: containerWidth-hourcolumn-6});
        $('.availability-column ul li').css({width: containerWidth-hourcolumn-13});
        var columnWidth = $('.day-and-date').outerWidth(true);
        var dayDate = $('.day-and-date').length;
        $('.day-date-column, .availability-data').css({width: columnWidth*dayDate+8});
        //price manager
        $('#price-manager .day-and-date, .container-data').css({width: (containerWidth-hourcolumn)/2 - 8});
        $('#price-manager .day-date-column, .price-data').css({width: columnWidth/2*dayDate+hourcolumn+22});

        $('.header-day-date').css({width: containerWidth});
        //fix header at scrolltop
        $(function () {
            var $dateHeader = $('.header-day-date');
            $window = $(window);
            offset = $dateHeader.offset();
            topPadding = 0;

            $window.scroll(function () {
                if ($window.scrollTop() > offset.top ) {
                    $dateHeader.css({
                        'top': '0',
                        'position': 'fixed',
                        'z-index': '2'
                    });
                } else {
                    $dateHeader.css({
                        'top': '170',
                        'position': ''
                    });
                }
            });
        });

    } else if (containerWidth <= 724) {
        $('.header-day-date').css({width: containerWidth});
        $('.day-and-date, .availability li').css({width: 80});
        //$('.fyt-booked, .reserved, .unavailable').css({width: 64});
        $('.availability-column').css({width: 88});
    } else if (containerWidth <= 940) {
        $('.header-day-date').css({width: containerWidth});
        $('.day-and-date, .availability li').css({width: 110});
        //$('.fyt-booked, .reserved, .unavailable').css({width: 94});
        $('.availability-column').css({width: 118});
    } else if (containerWidth <= 1200) {
        $('.header-day-date').css({width: containerWidth});
        $('.day-and-date, .availability li').css({width: 143});
        //$('.fyt-booked, .reserved, .unavailable').css({width: 127});
        $('.availability-column').css({width: 151});
    }
}

var memberID = $('body').data('memberID');
function gMap(latitude, longitude){
    var mapOptions = {
        center: new google.maps.LatLng(latitude, longitude),
    };

    this._map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);
}

function search_by_click_in_marker(location_index){
    var form = $("#refine-search-form"),
        location = $("#id_location").attr("value", location_index);
    form.find("input:checkbox").attr('checked', false);
    form.submit();
}

function get_style_marker(loc_type){

    /*
    Return The style for a given Location Type.

    name:a health club , color: FF776B, , css_class:health_club , database_id: 1
    name: a private studio or boutique , color: 00CED1 , css_class: private_studio, database_id: 2
    name: franchise, color:1F5C7A, , css_class: franchise , database_id: 3
    name: my home or building gym, color:44B7EA , css_class:my_home, database_id: 4

    */

    var styles = {
        // 1: {
        //     color: 'FFFF6B',
        //     css_class: 'health_club'
        // },
        1: {
            color: 'FF776B',
            css_class: 'private_studio'
        },
        2: {
            color: 'FF776B',
            css_class: 'private_studio'
        },
        3: {
            color: 'CCCCCC',
            css_class: 'franchise'
        },
        4: {
            color: '44B7EA',
            css_class: 'my_home'
        }
    }

    if (loc_type in styles){
        return styles[loc_type];
    }

    return {
        color: '1F5C7A',
        css_class: ''
    };
}

gMap.prototype.addLocation = function (name, latitude, longitude, markernumber, loc_index, loc_type){

    var marker_style = get_style_marker(loc_type);

    var icon = {
        url: '/static/images/pink-marker.png',
        scaledSize: new google.maps.Size(22, 31),
        labelOrigin: new google.maps.Point(11, 12)
    };

    var marker = new google.maps.Marker({
        map: this._map,
        position: new google.maps.LatLng(latitude, longitude),
        title: name,
        icon: icon,
        label: {
            text: markernumber <= 9 ? markernumber.toString() : ' ',
            fontFamily: 'Open Sans',
            fontSize: '13px',
            fontWeight: '800',
            color: 'white'
        },
        //customs atributes
        location_index: loc_index
    });

    google.maps.event.addListener(marker, "click", function (mouseEvent) {
        //alert(marker.location_index);
        search_by_click_in_marker(marker.location_index);
    });
}


function look_redo_search(map){

    var bounds = map.getBounds(),
        urlResult = window.URLS.search,
        market_slug = $('.search_result_table').attr("data-market-slug"),
        center = map._map.getCenter(),
        params = {
            lat: center.lat(),
            lng: center.lng(),
            e: bounds.getNorthEast().lng(),
            n: bounds.getNorthEast().lat(),
            w: bounds.getSouthWest().lng(),
            s: bounds.getSouthWest().lat(),
            last_zoom: map._map.getZoom(),
            loctypes: $('.search_result_table').attr("data-location-types"),
            q: $('.search_result_table').attr("data-query"),
            from_redo_search: true
        };

    urlResult = urlResult.replace('999999', market_slug);

    if (params.loctypes === null || params.loctypes === ''){
        params.loctypes = JSON.stringify([null]);
    }

    // reverse search zipcodes in FT from map boundaries
    FusionTables.zipcodesByRectangle(params.e, params.n, params.w, params.s, function(zipcodes) {
        $.extend(params, {
            'zipcodes': '['+zipcodes.toString()+']'
        })
        window.location.href = urlResult + '?' + $.param(params);
    })
}

function get_default_zoom(search_type){

    //locality == city

    var zoom = {
        locality: 14,
        postal_code: 10,
        not_result: 14
    }

    if (search_type in zoom){
        return zoom[search_type];
    }
    return zoom.not_result;
}

function InitSearchResults(loctype, query, center_lng, center_lat, e, n, w, s, last_zoom, all_market) {
    var geocoder = new google.maps.Geocoder(),
        total_trainers = $("#trainers_n").data("total-trainers"),
        zipcodes = $('.search_result_table').data('zipcodes'),
        latlng = new google.maps.LatLng(center_lat, center_lng)

    // this geocoder have never been really necessary
    // it could still work to double check the location searched
    geocoder.geocode({location: latlng, region: "us"}, function (georesults, geostatus) {
      if (geostatus === google.maps.GeocoderStatus.OK) {

        var geo = georesults[0].geometry;
        var type_search = georesults[0].types[0];

        if (last_zoom !== null){
            var zoom = parseInt(last_zoom);
        } else {
            var zoom = get_default_zoom(type_search);
        }

        map = new gMap(geo.location.lat(), geo.location.lng());
        map._map.setZoom(zoom);

        // layer zipcode polygons
        // if search is in-home, and there are zipcodes
        if ($.inArray(IN_HOME, loctype) !== -1) {
            if (zipcodes.length) {
                var ziplayers = new google.maps.FusionTablesLayer({
                    query: {
                        select: 'geometry',
                        from: FusionTables.US_ZIPCODES_ID,
                        where: 'ZIP IN (' + zipcodes.join(',') + ')'
                    },
                    options: {
                        styleId: 2,
                        templateId: 2
                    }
                });
                ziplayers.setMap(map._map);
            }
        }

        $("#redo-search").click(function (){
            look_redo_search(map);
        });

        $('.search_result_table').search_trainers(loctype, geo, new Date());

        $('#slide-back, #slide-forth').click(function () {
          $('.search_result_table').search_trainers(loctype, geo, new Date(parseInt($(this).attr('time'))));
        });

      } else {
        // search view for some reason doesn't return lat/lng always
        // (i.e. if there's no results). in that case search by query...
        geocoder.geocode({address: query, region: "us"}, function (georesults, geostatus) {
            if (geostatus === google.maps.GeocoderStatus.OK) {
                var geo = georesults[0].geometry;
                map = new gMap(geo.location.lat(), geo.location.lng());
                map._map.setZoom(12);
            } else {
                console.log("Can't find any place. " + geostatus)
            }
        })
      }

    });
}

function to_index(){
    window.location.href = urlHome;
}

$('.text-trainer').on('click', 'a', function (e){
    e.preventDefault();
    var tarinerId = $(this).parents('.schedule_result').data('trainer');
    window.open('/trainer-profile?trainer='+tarinerId);

});

function multi_popups(){
    $('.popup_body').hide();
    $('.session_info').empty().append($('.confirm_sum').clone());
    $('.price_info').empty().append($('.booking_price').clone());
    $('.popup_body').hide();
    $('.popups-container > .popup_header').text($('.booking_confirm').attr('data-header'))
    $('.booking_confirm').show();
    $('.popups-container').show();
}

function popups_display(btn){

    var pop_to_show = $(btn).attr('data-link'), //classname of new pop up content
        pop_body = $('.'+pop_to_show);

    if (pop_to_show === 'booking_congrats'){
        $.getJSON('/calendar/booksession?trainer='+$('.booking_summary')
        .find('.book_time')
        .attr('trainer')+'&start='+$('.booking_summary')
        .find('.book_time')
        .attr('start')+'&end='+$('.booking_summary')
        .find('.book_time')
        .attr('end')+'&type=2&status=1&member='+memberID, function () {

        });
    }
    $('.popup_body').hide();
    $('.popups-container > .popup_header').text(pop_body.attr('data-header'));
    pop_body.show();
}

function inputboxOnclick(obj){
    var dfstr = obj.defaultValue;
    var str = $(obj).val();
    var color = $(obj).css('color');
    if (color === 'rgb(102, 102, 102)' && dfstr === str){
        $(obj).val('');
        $(obj).css('color', '#000');
        if (dfstr === 'Password' || dfstr === 'Confirm Password'){
            obj.type = 'password';
        }
    }
}

function inputboxOnblur(obj){
    var dfstr = obj.defaultValue;
    var input = $(obj).val();
    if (input === ''){
        $(obj).val(dfstr);
        $(obj).css('color', '#666');
        if (dfstr === 'Password' || dfstr === 'Confirm Password'){
            obj.type = 'text';
        }
    }
}