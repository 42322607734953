/* globals $ */
var HomeCarousels = (function () {
    "use strict";

    var leftButtonImageSrc,
        rightButtonImageSrc;

    function buildOptions(opts) {
        var options = {
            loop: true,
            autoplay: true,
            autoplayTimeout: 2000,
            autoplayHoverPause: true,
            autoplaySpeed: 500,
            nav: true,
            touchDrag: false,
            responsive: {
                0: {
                    items: 1
                }
            },
            navText: [
                '<img src="' + leftButtonImageSrc + '">',
                '<img src="' + rightButtonImageSrc + '">',
            ]
        };
        $.extend(options, opts || {});
        return options;
    }

    return {
        init: function (params) {
            leftButtonImageSrc = params.leftButtonImageSrc;
            rightButtonImageSrc = params.rightButtonImageSrc;
            $('.home-items .owl-carousel').owlCarousel(buildOptions());
            $('.why-a-trainer .owl-carousel').owlCarousel(buildOptions({
                autoplay: false,
            }));
        }
    };

}());