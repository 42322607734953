var Payment = (function() {
  const addressData = JSON.parse(localStorage.getItem("addressData"));
  const objectState = JSON.parse(localStorage.getItem("objectState"));
  var locations = [];
  var services = [];
  var plans = [];

  var introPricePayment = false;

  if (JSON.parse(localStorage.getItem("booking_data"))) {
    booking_data = JSON.parse(localStorage.getItem("booking_data"));
  };

  function buildOptions(array, selectWrapper, type) {
    for (var i = 0; i < array.length; i++) {
      const element = array[i];
      if (
        (type === "service" && element.id !== booking_data.service_id) ||
        (type === "location" && element.id !== booking_data.location_id) ||
        (type === "plan" && element.id !== booking_data.plan_id)
      ) {
        $(selectWrapper).append(
          $("<div class='item'></div>")
            .attr("data-option-id", element.id)
            .text(element.title)
        );
      }
    }
  }

  function calculateAmounts(trainer_pk, introPriceAvailable) {
    if (trainer_pk) getPlans(trainer_pk, true);
    if (introPriceAvailable) {
      var standartPrice = 0;
      var doubleStandartPrice = 0;
      var regularIntroDifference = 1;
      var savings = 0;
      var introPrice = 0;
      for (var i = 0; i < plans.length; i++) {
        const plan = plans[i];
        if(plan.id == "intro_offer"){
          introPrice = plan.price;
        } else if(plan.sessions == 4){
          standartPrice = plan.price;

        }
      }
      doubleStandartPrice = standartPrice * 2;
      regularIntroDifference = doubleStandartPrice - introPrice;
      savings = Math.round((regularIntroDifference*100)/doubleStandartPrice)
      $("#intro_price_on_payment").text("$" + introPrice);
      $("#standard_price").text(standartPrice);
      $("#double_standard_price").text("$" + doubleStandartPrice);
      $("#regular_intro_difference").text("$" + regularIntroDifference);
      $("#saving").text("Save " + savings + "%");

    } else {
      for (var i = 0; i < plans.length; i++) {
        const plan = plans[i];
        if (plan.id === booking_data.plan_id) {
          $("#session_rate_price").text("$" + plan.price);
          $("#n_sessions").text("x" + plan.sessions);
          var total = plan.price * plan.sessions;
          $("#total_price").text("$" + total);
        }
      }
    }
  }

  function buildDataPlans(data) {
    var planList = [];
    var subscriptionPrices = data.subscription_prices;
    for (var i=0; i<subscriptionPrices.length; i++) {
      if (subscriptionPrices[i].type != "intro_offer"){
        const subscription = subscriptionPrices[i].type;
        const subscriptionPrice = subscriptionPrices[i].calculated_price;
        var discount = "";
        const sessions = subscription.sessions + " sessions ";
        if (subscription.discount) discount = "(" + subscription.discount + "% Discount)";
        const title = sessions + discount;
        planList.push({title: title, id: subscription.id, price: subscriptionPrice, sessions: subscription.sessions, surcharge: subscription.surcharge});
      } else {
        subscriptionPrice = subscriptionPrices[i].trainer_intro_price + (2*subscriptionPrices[i].surcharge);
        planList.push({title: title, id: "intro_offer", price: subscriptionPrice, sessions: 2});
      }
      
    }
    return planList;
  }

  function getPlans(trainer_pk, flag) {
    var planList = [];
    var loc_id = booking_data.location_id;
    if (booking_data.location_id === 'VIRTUAL') {
      loc_id = null;
    }

    if (flag) {
      $.ajax({
        url: "/trainer/"+ trainer_pk +"/subscriptions/price/",
        async: false,
        data: {
          'service_pk': booking_data.service_id,
          'addl_location_pk': loc_id,
          'time_slot': booking_data.time_slot_id ? booking_data.time_slot_id : null
        },
        success: function(data) {
          objectState.plans = data;
          localStorage.setItem("objectState", JSON.stringify(objectState));
          planList = buildDataPlans(data);
        }
      });
    } else {
      const data = objectState.plans;
      planList = buildDataPlans(data);
    }

    plans = planList;
    return plans;
  }

  function buildArray(data) {
    var locationList = [];
    data.location["pk"] = 0;
    locationList.push(data.location);
    if (data.virtual_location) {
      locationList.push(data.virtual_location);
    };
    for (var i = 0; i < data.locations.length; i++) {
      const element = data.locations[i];
      element.location["surcharge"] = element.surcharge;
      element.location["pk"] = element.pk;
      locationList.push(element.location);
    }
    return locationList;
  }

  function getLocations() {
    var locationsList = [];
    var data = objectState.locations;
    var array = buildArray(data);
    for (var i = 0; i < array.length; i++) {
      const location = array[i];
      var loc_id = location.pk;
      if (location.id === 'VIRTUAL') {
        loc_id = location.id;
      }
      locationsList.push({
        title: location.chain_name,
        id: loc_id,
        address: location.address1,
        city: location.city,
        state: location.state,
        zipcode: location.zipcode,
        in_home: location.in_home
      });
    }
    locations = locationsList;
    return locations;
  }

  function getServices() {
    var servicesList = [];
    var data = objectState.services;
    var array = data.training_services_offered;
        for (var i = 0; i < array.length; i++) {
          const service = array[i];
          servicesList.push({title: service.title, id: service.pk});
        }
    services = servicesList;
    return services;
  }

  function handleCustomSelect(trainer_pk) {
    // Open or close the different Custom-Selects
    $(".select-custom .change").click(function() {
      var id = $(this).data("change");
      var elem = $(".select-wrapper[id="+id+"]");
      $(elem).find(".custom-dropdown").toggleClass("active");
      $(elem).find(".select-custom").toggleClass("no-border-radius-bottom");
      switch (id) {
        case 1:
          $(elem).find(".item").css("margin-left", "89px");
          selectOptions(elem, trainer_pk);
          break;
        case 2:
          $(elem).find(".item").css("margin-left", "77px");
          selectOptions(elem, trainer_pk);
          break;
        case 3:
          $(elem).find(".item").css("margin-left", "118px");
          selectOptions(elem, trainer_pk);
          break;
        default:
          return null;
      }
    })
  }
  
  function handleLocationAddress() {
    for (var i = 0; i < locations.length; i++) {
      const location = locations[i];
      if (location.id === booking_data.location_id && !location.in_home) {
        $(".location-div").find("a").css('display', 'none');
        $(".location-div .normal").text(location.address + " " + location.city + ", " + location.state + " " + location.zipcode);
      } else if (location.id === booking_data.location_id && location.in_home) {
        $(".location-div").find("a").css('display', 'block');
        if (addressData.street) $(".location-div .normal").text(addressData.street + " " + addressData.city + ", " + addressData.stateName + " " + addressData.zip_code);
        else $(".location-div .normal").text(" ");
      }
    }
  }

  function renderCustomSelectOptions() {
    buildOptions(locations, $(".select-wrapper[id='1'] .custom-dropdown"), 'location');
    buildOptions(services, $(".select-wrapper[id='2'] .custom-dropdown"), 'service');
    buildOptions(plans, $(".select-wrapper[id='3'] .custom-dropdown"), 'plan');
  }

  function renderCustomSelectValues() {
    for (var i=0; i<locations.length; i++) {
      if (locations[i].id === booking_data.location_id) {
        $(".select-wrapper[id='1']").find(".normal").text(locations[i].title);
      }
    }
    for (var i=0; i<services.length; i++) {
      if (services[i].id === booking_data.service_id) {
        $(".select-wrapper[id='2']").find(".normal").text(services[i].title);
      }
    }
    for (var i=0; i<plans.length; i++) {
      if (plans[i].id === booking_data.plan_id) {
        $(".select-wrapper[id='3']").find(".normal").text(plans[i].title);
      }
    }
  }

  function updateState(trainer_pk, flag) {
    calculateAmounts(trainer_pk, introPricePayment);
    localStorage.setItem("booking_data", JSON.stringify(booking_data));
    if (!flag) {
      if (JSON.parse(localStorage.getItem("codeData"))) {
      const codeToReview = JSON.parse(localStorage.getItem("codeData")).code;
      CouponsHandler.revalidateCoupon(codeToReview, introPricePayment);
      }
    }
  }

  function selectOptions(elem, trainer_pk) {
    // Trigger actions to handle options selection
    var option = $(elem).find(".item");
    $(option).click(function() {
      var customDropdown = $(elem).find(".custom-dropdown");
      $(elem).find(".normal").text($(this).text());
      $(customDropdown).removeClass("active").empty();
      if ($(elem).find(".bolder").text() === "Location") {
        booking_data.location_id = $(this).data("option-id");
        buildOptions(locations, customDropdown, 'location');
        handleLocationAddress();
        updateState(trainer_pk, true);
      } else if ($(elem).find(".bolder").text() === "Service") {
        booking_data.service_id = $(this).data("option-id");
        buildOptions(services, customDropdown, 'service');
        updateState(trainer_pk);
      } else if ($(elem).find(".bolder").text() === "Subscription") {
        booking_data.plan_id = $(this).data("option-id");
        buildOptions(plans, customDropdown, 'plan');
        updateState(trainer_pk);
      }
    })
  }

  function getNSessions(introPrice) {
    var nSessions = null;
    for (var i = 0; i < plans.length; i++) {
      const plan = plans[i];
      if (plan.id == booking_data.plan_id) {
        nSessions = plan.sessions;
      }
    }
    if (introPrice) {
      return 4;
    }
    return nSessions;
  }

  function clearLocalStorage() {
    var codeData = JSON.parse(localStorage.getItem("codeData"));
    var booking_data = JSON.parse(localStorage.getItem("booking_data"));
    var addressData = JSON.parse(localStorage.getItem("addressData"));
    var objectState = JSON.parse(localStorage.getItem("objectState"));
    if (codeData) localStorage.removeItem("codeData");
    if (booking_data && booking_data.service_id) localStorage.setItem("service_pk", booking_data.service_id);
    if (booking_data) localStorage.removeItem("booking_data");
    if (addressData) localStorage.removeItem("addressData");
    if (objectState) localStorage.removeItem("objectState");
  }

  function handlePayment(trainer_pk, trainer_slug, time_slot_pk, csrf_token, introPrice) {
    var timeslot_pk_tosend = time_slot_pk;
    var codeToSend = null;
    if (!time_slot_pk) timeslot_pk_tosend = null;
    if (JSON.parse(localStorage.getItem("codeData"))) codeToSend = JSON.parse(localStorage.getItem("codeData")).code;
    
    $("#booking-spinner").show();
    BookingSpinner(document.getElementById("booking-spinner"));

    var loc_id = booking_data.location_id;
    var is_virtual = false;
    if (booking_data.location_id === 'VIRTUAL') {
      loc_id = 0;
      is_virtual = true;
    }

    $.ajax({
      url: "/fyt/book/purchase/subscription/",
      method: "POST",
      headers:{"X-CSRFToken": csrf_token},
      data: {
        'trainer': trainer_pk,
        'service': booking_data.service_id,
        'sessions': getNSessions(introPrice),
        'addl_location_pk': loc_id,
        'time_slot': timeslot_pk_tosend,
        'code': codeToSend,
        'virtual': is_virtual,
        'intro_price': introPrice,
      },
      beforeSend: function() {
        $(".btn-payment").attr("disabled", true);
        BookingSpinner.show();
      },
      success: function (data) {
        if (time_slot_pk) {
          $.ajax({
            url: '/fyt/book/get_revenue/',
            data: {
              'timeslot_pk': time_slot_pk,
              'booking_type': 'subscription',
            },
            success: function(data) {
              var revenue = data.revenue;
              localStorage.setItem("revenueGA", revenue);
            }
          }).done(function() {
            var baseUrl = window.location.origin + "/fyt/book/booking_step3/finish/" + trainer_slug;
            if (time_slot_pk) window.location.href = baseUrl + "/?time_slot=" + time_slot_pk;
            else window.location.href = baseUrl;
          });
        } else {
          var baseUrl = window.location.origin + "/fyt/book/booking_step3/finish/" + trainer_slug;
          if (time_slot_pk) window.location.href = baseUrl + "/?time_slot=" + time_slot_pk;
          else window.location.href = baseUrl;
        };
        BookingSpinner.hide();
        clearLocalStorage();
      },
      error: function (error) {
        setTimeout(function() {
          BookingSpinner.hide();
          $("#booking-spinner").hide();
          $(".btn-payment").attr("disabled", false);
          const err = error.responseJSON;
          console.log("ERR", err);
          const key = Object.keys(err)[0];
          $(".second-error-box").addClass("active").text(err[key]);
        }, 2000);
      },
    });

  }

  return {
    init: function(trainer_pk, virtual_training_only, introPrice) {
      introPricePayment = introPrice;
      if (!virtual_training_only) {
        getLocations(trainer_pk);
      };
      getServices(trainer_pk);
      getPlans(trainer_pk);
      getNSessions();
      renderCustomSelectValues();
      renderCustomSelectOptions();
      calculateAmounts(null, introPrice);
      handleLocationAddress();
      handleCustomSelect(trainer_pk);
    },
    handlePayment: function(trainer_pk, trainer_slug, time_slot_pk, csrf_token, introPrice) {
      handlePayment(trainer_pk, trainer_slug, time_slot_pk, csrf_token, introPrice);
    }
  }
})();