var TrainerProfile = (function() {

    var trainer_slug,
        latitude,
        longitude,
        gym_name,
        is_in_home,
        zipcodes,
        map;

    var mapWaitingForLoad = true;

    function initializeMap() {
        mapWaitingForLoad = false;

        var primaryLatLng = new google.maps.LatLng(latitude, longitude),
            mapOptions = {
                zoom: 14,
                center: primaryLatLng,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            },
            styles = [{
                    featureType: "road.arterial",
                    elementType: "all",
                    stylers: [
                        { visibility: "simplified" }
                    ]
                },
                {
                    featureType: "road",
                    elementType: "labels",
                    stylers: [
                        { visibility: "simplified" }
                    ]
                }
            ],
            styledMap = new google.maps.StyledMapType(styles, { name: "Styled Map" });

        map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);
        
        map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');

        if (!is_in_home) {
            var icon = {
                url: '/static/images/pink-marker.png',
                scaledSize: new google.maps.Size(22, 31),
                labelOrigin: new google.maps.Point(11, 12)
            };
            var marker = new google.maps.Marker({
                position: primaryLatLng,
                icon: icon,
                map: map,
                title: gym_name
            });
        }

        if (zipcodes.length) {

            src = window.location.origin + '/zipcodes.kml?trainer_slug=' + trainer_slug;

            var kmlLayer = new google.maps.KmlLayer({
                suppressInfoWindows: true,
                preserveViewport: true,
                map: map,
                url: src
            });

            google.maps.event.addListener(kmlLayer, 'defaultviewport_changed', function() {
                var bounds = kmlLayer.getDefaultViewport();
                map.setCenter(bounds.getCenter());
                map.setZoom(11)
            });

        } else {
            map.setCenter(primaryLatLng);
        }

        for (var i = points.length - 1; i >= 0; i--) {

            var myLatlng = new google.maps.LatLng(points[i]['lat'], points[i]['lng']);

            var marker = new google.maps.Marker({
                position: myLatlng,
                icon: icon,
                map: map,
                title: gym_name
            });

        }
    }

    return {

        init: function(params) {
            trainer_slug = params.trainer_slug;
            latitude = params.latitude;
            longitude = params.longitude;
            gym_name = params.gym_name;
            zipcodes = params.zipcodes;
            points = params.points;
            is_in_home = params.is_in_home;
            score = params.score;
            has_future_availability = params.has_future_availability;
            
            var $mapCanvas = $('#map_canvas');

            // lazy load Google Map
            $(window).on('resize scroll', function() {
                if (mapWaitingForLoad && $mapCanvas.isInViewport()) {
                    console.log('map init');
                    initializeMap();
                }
            })

            var image_carouser = $("#carousel-image");

            $(".review-avg").raty({
                score: score,
                readOnly: true,
                path: window.URLS.raty_image_path,
                numberMax: 5,
                halfShow: true,
            });

            $(".single-review-stars").raty({
                score: function() {
                    return $(this).attr('data-number');
                },
                readOnly: true,
                path: window.URLS.raty_image_path,
                numberMax: 5,
            });

            image_carouser.carousel({
                interval: false
            });

            $("span.glyphicon-chevron-right").click(function(evt) {
                evt.preventDefault;
                image_carouser.carousel('next');
            });

            $("span.glyphicon-chevron-left").click(function(evt) {
                evt.preventDefault;
                image_carouser.carousel('prev');
            });


            $(".trainer-bio").readmore({
                collapsedHeight: 80,
                speed: 200,
                moreLink: '<a class="trainer-bio-link">+Read More</a>',
                lessLink: '<a class="trainer-bio-link">Close</a>'
            });

            /*if(has_future_availability=="True"){
                $(".trainer-bio").readmore({
                    collapsedHeight: 80,
                    speed: 200,
                    moreLink: '<a class="trainer-bio-link">+Read More</a>',
                    lessLink: '<a class="trainer-bio-link">Close</a>'
                });
            }*/

            $(".price-widget").click(function() {
                $("#subscription-modal").modal();
            });

            $('.show-more-specialties').click(function() {
                $(this).addClass('hidden');
                $('.additional-specialties').removeClass('hidden');
                $('.show-less-specialties').removeClass('hidden');
            });

            $('.show-less-specialties').click(function() {
                $(this).addClass('hidden');
                $('.additional-specialties').addClass('hidden');
                $('.show-more-specialties').removeClass('hidden');
            });

            $('.profile-faq').click(function() {
                console.log($(this));
                var question = $(this).find('.question');
                console.log(question.attr('aria-expanded'));
                if (question.attr('aria-expanded') == "false") {
                    question.addClass('expanded');
                    question.find('.glyphicon').removeClass('glyphicon-plus').addClass('glyphicon-minus');
                } else {
                    question.removeClass('expanded');
                    question.find('.glyphicon').removeClass('glyphicon-minus').addClass('glyphicon-plus');
                }
            })
        }
    }

})();