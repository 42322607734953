var Finish = (function () {

  var inputs = $("#finish-inputs").find(".form-control");

  function buildDataToSend(service_pk, trainer_pk, time_slot_pk) {
    var dataToSend = {
      'trainer': trainer_pk,
      'time_slot': time_slot_pk ? time_slot_pk : null,
      'service_pk': service_pk
    }
    for (var i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      dataToSend[input.name] = $(input).val();
    }
    return dataToSend;
  }

  function getSurveyData(service_pk) {
    $.ajax({
      url: '/fyt/book/member/survey/',
      data: {
          'service_pk': service_pk
      },
      success: function(data) {
        console.log(data);
        for (var i = 0; i < inputs.length; i++) {
          const input = inputs[i];
          $(input).val(data[input.name]);
        }
      },
      error: function (error) {console.error(error['responseText'])}
    })
  }

  function handleInputsLength() {
    for (var i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if ($(input).attr("maxlength")) {
        var $limitNum = $(input).attr("maxlength");
        $(input).keydown(function() {
          if ($(input).val().length > $limitNum) {
            $(input).val($(input).val().substring(0, $limitNum));
          };
        });
      };
    };
  };

  function handleFinish(service_pk, trainer_pk, time_slot_pk) {
    $('.btn-finish').click(function() {
      var dataToSend = buildDataToSend(service_pk, trainer_pk, time_slot_pk);
      $.ajax({
          url: '/fyt/book/member/survey/',
          method: 'POST',
          data: dataToSend,
          success: function(data) {
            for (var i = 0; i < inputs.length; i++) {
              const input = inputs[i];
              $(input).val(dataToSend[input.name]);
            }
            $(".btn-finish").attr("disabled", true);
            $(".btn-finish").text("");
            $(".btn-finish").append('<i class="far fa-check-circle" style="color: #fff;font-size: 25px;"></i>')
            setTimeout(function() {
              location.href="/fyt/account/";
            }, 1000)
          },
          error: function (error) {console.error(error['responseText'])}
      })
    })
  }

  return {
    init: function(trainer_pk, time_slot_pk) {
      var service_pk = null;
      if (JSON.parse(localStorage.getItem("service_pk"))) {
        service_pk = JSON.parse(localStorage.getItem("service_pk"));
      }
      const booking_data = JSON.parse(localStorage.getItem("booking_data"));
      if (!booking_data || booking_data.suscriptionData.has_suscription) getSurveyData(service_pk);
      handleInputsLength();
      handleFinish(service_pk, trainer_pk, time_slot_pk);
    }
  }
})();