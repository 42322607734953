var Messaging = (function($){

	var listContent = $('#content');

	var headOfMsg = $('#msghead');

	function addCurrentClasses(a){
	    var containerli =  a.closest('div.container-li-msg');
	    var text = containerli.find('div.line-clamp');
	    var timespan = containerli.find('span.time');
	    var asteriskspan = containerli.find('span.asterisk');
	    var restoreicon = containerli.find('a.recover-msg');
	    var currents = [ a, containerli, text, timespan, asteriskspan, restoreicon ];
	    
	    for (var i = currents.length - 1; i >= 0; i--) {
	        currents[i].addClass('current');
	    };
	}

	function checkWindowSize(height, width){
	    if(width>766){
	        // show all content 
	        $('#content').show();
	        $('#content-right').show();
	        $('#listhead').show();
	        $('#msghead').show();
	    }
	}

	function onSuccessPost(action, data){
	    var $a = $('a[data-message='+ data['hashid'] + ']');
	    var listId = $a.parents('.msgs-container').attr('id');
	    var listName = listId.split('-')[0];
	    $.ajax({
	        type: "GET",
	        url: "/inbox/reload",
	        data : { list: listName },
	    }).done(function(data) {
	    	$('#content').html(data);
	    	setFirstAsCurrent();
	    }).fail(function (jqXHR, textStatus, errorThrown) { 
	        console.log('fail'); 
	        console.log(textStatus);
	    });
	}

	// remove current class for the last current msg
	function removeCurrentClasses(){
	    $("a.current").each(function() {
	        $(this).removeClass("current");
	    });
	    $("div.current").each(function() {
	        $(this).removeClass("current");
	    });
	    $("span.current").each(function() {
	        $(this).removeClass("current");
	    });
	}

	// remove unread class in list when click msg
	function removeUnreadClasses(a){
	    var containerli =  a.closest('div.container-li-msg');
	    var text = containerli.find('div.line-clamp');
	    text.removeClass('unreadmsg');
	    containerli.removeClass('unreadmsg');
	}

	function setComposeBoxHeight(){
    	//$('#message-chat').height($('#hangout').height()-$('#inboxhead').height()-$('#ulchat').height());
	}

	function setCurrentMessage(a, prev){
	    var url = $(a).data('href');
	    var mark_as_read = true;
	    if(prev=="setFirst"){
	        var mark_as_read = false;
	    }
	    $.ajax({
	        type: "GET",
	        url: url,
	        data : { mark_as_read: mark_as_read },
	    }).done(function(data) {
	        // appends to html msg content div the current msg
	        $('#content-right').html(data['content']);
	        $('#msghead').html(data['head']);

	        removeCurrentClasses();
	        
	        // add current classes for the current msg in left list
	        addCurrentClasses(a);
	        
	        //if is an unread msg mark as read
	        if(prev=="click"){
	            removeUnreadClasses(a);
	        }

	        if(window.innerWidth<=766){
	            switch(prev){
	                case "click":
	                    toggleToMessageContent();
	                break;
	                case "setFirst":
	                    toggleToMessagesListContent();
	                break;
	                case "setCurrent":
	                    toggleToMessageContent();
	                break;
	            }
	        }
	    
	    }).fail(function (jqXHR, textStatus, errorThrown) { 
	        console.log('fail'); 
	        console.log(textStatus);
	    });
	}

	function setFirstAsCurrent(){
	    setCurrentMessage($("div.container-li-msg li a.msg").first(), 'setFirst');
	}

	function toggleToMessageContent(){
	    /*$('#content').hide();
	    $('#content-right').show();*/
	    //setComposeBoxHeight();
	    /*$('#listhead').hide();
		$('#msghead').show();*/
		$('.chat-left-column').hide();
		$('.chat-right-column').show();
		$('ul.chat').scrollTop($('ul.chat')[0].scrollHeight);
	}

	function toggleToMessagesListContent(){
	    // $('#content').show();
	    // $('#content-right').hide();
	    // $('#listhead').show();
		// $('#msghead').hide();
		$('.chat-right-column').hide();
		$('.chat-left-column').show();	
	}

	function toggle_status_message(url) {
        $.ajax({
            type: "POST",
            url: url
        }).done(function(data) {
            if(data['status']==200){
                $("#ul-dropwdown-head-menu").hide();
                $('.mark-as-unread').toggleClass('hidden');
                $('.mark-as-read').toggleClass('hidden');
                // Styling msg, toggle unreadmsg class
                $a = $("a[data-message='"+data['hashid']+"']");
                $a.closest('div.container-li-msg').toggleClass('unreadmsg');
                $a.find("div.line-clamp").toggleClass('unreadmsg');
            }
        }).fail(function (jqXHR, textStatus, errorThrown) { 
            console.log('fail'); 
            console.log(textStatus);
        });
	};

	return {
		init: function(){

			/// ** LIST OF MSG

			// if comes from reply-by-email set this msg in current message
			var currentMessage = "{{ rootMessage }}";
			if(currentMessage!=='None'){
			    var message;
			    if(window.location.search!==''){
			        var params = window.location.search.split('?')[1].split('&');
			        for (var i = params.length - 1; i >= 0; i--) {
			            var param = params[i].split('=');
			            if(param[0]=="hashid"){
			                var hashid = param[1];
			                message = $("#inbox-list").find("[data-message='" + hashid + "']");
			            }
			        };
			    }
			}

			if(message!==undefined){
			    setCurrentMessage(message, 'setCurrent');
			} else {
			    // if there is no current msg print the first on the list
			    setFirstAsCurrent();
			}

			$(window).resize(function(){
			    checkWindowSize(window.innerHeight, window.innerWidth);
			});

			// Change the current msg view on the right, and change background-color on the left

			listContent.on('click', 'a.msg',function(e){
				e.preventDefault();
				setCurrentMessage($(this), 'click');
			});

			listContent.on('click', 'a.remove-msg', function(){
				var url = $(this).data('href');
			    $.ajax({
			        dataType:'json',
			        type: "POST",
			        url: url
			    }).done(function(data) {
			        if (data['status'] == 200){
			            onSuccessPost("remove", data);
			        };
			    });
			});

			listContent.on('click', 'a.recover-msg', function(){
				var url = $(this).data('href');
			    $.ajax({
			        type: "POST",
			        url: url
			    }).done(function(data) {
			        if (data['status'] == 200){
			            onSuccessPost("recover", data);
			        };
			    });
			});

			///  ** END LIST OF MSG

			/// ** HEAD OF MSG

			function showLeftColumn(){
				$('.chat-right-column').hide();
				$('.chat-left-column').show();		
			}
			
			function showRightColumn(){
				$('.chat-left-column').hide();
				$('.chat-right-column').show();		
			}

			headOfMsg.on('click', 'a.back-to-msgs', function(e){
	            e.preventDefault();
	            var width = window.innerWidth;
	            if(width<=766){
	                // $('#content').show();
	                // $('#listhead').show();
	                // $('#content-right').hide();
					// $('#msghead').hide(); 
					showLeftColumn(); 
	            } 
	        });

	        headOfMsg.on('click', '#dropdownMsgHead', function(e){
	            // $("#ul-dropwdown-head-menu").toggle();
	            if($('#ul-dropwdown-head-menu').css('display') == 'none')
	            {
	                $("#ul-dropwdown-head-menu").show();
	            } else {
	                $("#ul-dropwdown-head-menu").hide();
	            }
	        });

			headOfMsg.on('click', 'button.recover-msg-from-head', function(){
				var url = $(this).data('href');
	            $.ajax({
	                type: "POST",
	                url: url
	            }).done(function(data) {
	                if (data['status'] == 200){
	                    onSuccessPost("recover", data);
	                };
	            });
			});

			headOfMsg.on('click', 'button.remove-msg-from-head', function(){
				var url = $(this).data('href');
	            $.ajax({
	                dataType:'json',
	                type: "POST",
	                url: url
	            }).done(function(data) {
	                if (data['status'] == 200){
	                    onSuccessPost("remove", data);
	                };
	            });
			});

	        headOfMsg.on('click', 'a.read-unread', function(e){
	            e.preventDefault();
	            toggle_status_message($(this).attr('data-href'));
	        });

	        /// ** END HEAD OF MSG

		}
	}

}(jQuery));