var PriceManager = (function(){

	var trainerIndex = $('.trainer-price-table').data('trainerindex'),
    	defaultDiscount = $('.trainer-price-table').data('discount'),
    	fullPrice = $('.trainer-price-table').data('price'),
    	maxDiscount = $('#maxdiscount').text(),
		minPrice = parseInt($("#minprice").text());

	return {
		init: function(){

			$("#week_day_columns").setuppricegrid(trainerIndex);

		    $("#trainer-data [contenteditable='true']").on('input keyup', function() {
		        $(this).addClass("changed-price");
		        $("#savechanges,#cancelchanges").show();
		    });

		    $("#session-data [contenteditable='true']").on('click', function(){
		        var current = $(this);
		        var oldPrice, oldDiscount;
		        if(current.attr('class')=='sessiondiscount'){
		        	oldDiscount = $(this).text();
		        	oldPrice = current.closest('#session-data').find('.sessionprice').text();
		        } else if(current.attr('class')=='sessionprice'){
		        	oldPrice = $(this).text();
		        	oldDiscount = current.closest('#session-data').find('.sessiondiscount').text();
		        }
		        $("#session-data [contenteditable='true']").on('input keyup', function() {
		            $(this).closest("#session-data").addClass("changed-price");
		            $("#savechanges,#cancelchanges").show();
		        }).blur(function() {
		            if($(this).closest("#session-data").hasClass("changed-price")) {
		                var price = parseInt($(this).text());
		                var fullprice = parseInt($("#fullprice").text());
		                var discount = parseInt($(this).text());
		                if ($(this).hasClass("sessiondiscount")) {
		                    $(this).closest("#session-data").find(".sessionprice").text(Math.ceil(fullprice-(fullprice*discount)/100));
		                }
		                if ($(this).hasClass("sessionprice")) {
		                    $(this).closest("#session-data").find(".sessiondiscount").text(Math.ceil(100-price*100/fullprice));
		                }
		            }
		        });
		        $("#cancelchanges").click(function() {
		            current.closest('#session-data').find('.sessiondiscount').text(oldDiscount);
		            current.closest('#session-data').find('.sessionprice').text(oldPrice);
		        });
		    });

		   	// respose indicators
		    $('.sessiondiscount').blur(function(){
		        var discount = parseInt($(this).text());
		        if (discount > maxDiscount) {
		            //$(this).closest("#session-data").removeClass().addClass("alert-price");
		            $(this).closest("#session-data").removeClass().addClass("changed-price");
		        }
		        if (discount < maxDiscount) {
		            $(this).closest("#session-data").removeClass().addClass("changed-price");
		        }
		    });

		    $('.sessionprice').blur(function(){
		        var price = parseInt($(this).text());
		        if (price < minPrice) {
		            //$(this).closest("#session-data").removeClass().addClass("alert-price");
		            $(this).closest("#session-data").removeClass().addClass("changed-price");
		        }
		        if (price > minPrice) {
		            $(this).closest("#session-data").removeClass().addClass("changed-price");
		        }
		    });

		    $("#trainerlist").change(function() {
		        if ($(this).val()!="") window.location.href=$(this).val();
		    });

		    $(".sessiondiscount").each(function(){
				$(this).attr('oldvalue', $(this).text());
			});

			$('#change-price').submit(function(event){
				event.preventDefault();
				$('#error_price_select').hide();
				if(($('#priceslist').val()=='0')&&($('.changed-price').length==0)){
					$('#error_price_select').fadeIn('slow');
				} else {
					if($('#priceslist').val()=='0'){
						var full_price = fullprice;
					} else {
						var full_price = Math.round(parseInt($('#priceslist').val())/0.9);
					}
					var data = {
		                trainer: trainerIndex,
		                fullprice: Number(full_price),
		                defaultdiscount: Number($("#defaultdiscount").text()),
		            };
		            $.get("/gym/updatetrainer/", data, function() {
		                $('#trainer-data.changed-price').find('span').each(function() {
		                    $(this).attr('oldvalue', $(this).text())
		                });
		                changedPriceData = "";
		                $("#session-data.changed-price").each(function() {
		                    var trainerId = trainerIndex;
		                    var timeInSec = $(this).parent().attr('id');
		                    var priceChange = $(this).find(".sessionprice").text();
		                    var discountChange = $(this).find(".sessiondiscount").text();
		                    changedPriceData += trainerId+","+timeInSec+","+Math.ceil(priceChange)+","+Math.ceil(discountChange)+"|";
		                });
		                $.ajax({
		                    url: "/calendar/setprices/",
		                    type: "POST",
		                    data: { 'trainer': trainerIndex, 'changes': changedPriceData },
		                    dataType: "json",
		                    success: function (data) {
		                    	$('#fullprice').text(full_price);
		                        $("#week_day_columns").updatepricegrid(data.objects);
		                        $("#session-data.changed-price").find("span").each(function() {
		                            $(this).attr('oldvalue',$(this).text())
		                        });
		                        $("#session-data.changed-price").removeClass("changed-price");

		                        $('#success_price_select').fadeIn('slow');
		                        setTimeout(function(){
		                        	$('#success_price_select').fadeOut('slow');
		                        }, 2000);
		                        $.get('/price_manager/prices_table/?trainer=' + trainerIndex, function(data, status){
		                        	$('#prices-table').html(data);
		                        });
		                    },
		                    error: function (xhr, ajaxOptions, thrownError) {
		                        //alert(xhr.status);
		                        //alert(thrownError);
		                   }
		               });
		            });
				}
			});
		}
	}
}());