/* globals bootbox, popupFormBuilder */

var sendMessagePopupForm = (function (bootbox, popupFormBuilder) {
  var popupForm = popupFormBuilder();

  return {
    init: function (params) {
      var postSuccessCallback =
          params.postSuccessCallback ||
          function () {
            return;
          },
        modal_selector = params.modal_selector || "#new-message-modal";

      return popupForm.init({
        openModalBtnSelector: params.openModalBtnSelector,
        modalSelector: modal_selector,
        templateSelector: "#new-message-form-template",
        url: params.url,
        validators: [
          popupForm.buildNotEmptyValidator("title", ".title-is-empty"),
          popupForm.buildNotEmptyValidator("content", ".body-is-empty"),
        ],
        postSuccessCallback: function (context) {
          bootbox.alert(
            "Your message has been sent to " +
              context.$openModalBtn.data("recipient_name") +
              ". As a reminder, if you included contact information in your message, it has been removed for your security. <a href='/faqs/general/message-contact-info-removed'>Learn why ></a>",
            function () {
              postSuccessCallback(context);
            }
          );
        },
      });
    },
  };
})(bootbox, popupFormBuilder);
