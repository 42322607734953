/*

*/
'use strict';
function BookingSpinner(divLoading){
	BookingSpinner.element=document.createElementNS('http://www.w3.org/2000/svg', 'svg');
	var c=document.createElementNS('http://www.w3.org/2000/svg', 'circle');
	BookingSpinner.element.setAttribute('width','100');
	BookingSpinner.element.setAttribute('height','100');
	c.setAttribute('viewBox','0 0 100 100');
	c.setAttribute('cx','50');
	c.setAttribute('cy','50');
	c.setAttribute('r','30');
	c.setAttribute('stroke-width','10');
	c.setAttribute('stroke','#5340ff');
	c.setAttribute('fill','transparent');
	BookingSpinner.element.appendChild(c);
	BookingSpinner.element.style.cssText='position:absolute;left:calc(50% - 50px);top:calc(25% - 80px)';
	divLoading.appendChild(BookingSpinner.element)
}
BookingSpinner.id=null;
BookingSpinner.element=null;
BookingSpinner.show=function(){
	const c=264,m=15;
	BookingSpinner.element.style.display='block';
	move1();
	function move1(){
		var i=0,o=0;
		move();
		function move(){
			if(i==c)move2();
			else{
				i+=4;o+=8;
				BookingSpinner.element.setAttribute('stroke-dasharray',i+' '+(c-i));
				BookingSpinner.element.setAttribute('stroke-dashoffset',o)
				BookingSpinner.id=setTimeout(move,m)
			}
		}
	}
	function move2(){
		var i=c,o=c*2;
		move();
		function move(){
			if(i==0)move1();
			else{
				i-=4;o+=4;
				BookingSpinner.element.setAttribute('stroke-dasharray',i+' '+(c-i));
				BookingSpinner.element.setAttribute('stroke-dashoffset',o)
				BookingSpinner.id=setTimeout(move,m)
			}
		}
	}
};
BookingSpinner.hide=function(){
	BookingSpinner.element.style.display='none';
	if(BookingSpinner.id){
		clearTimeout(BookingSpinner.id);
		BookingSpinner.id=null
	}
	BookingSpinner.element.setAttribute('stroke-dasharray','0 264');
	BookingSpinner.element.setAttribute('stroke-dashoffset','0')
};
