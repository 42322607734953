var scrolls = (function () {

    return {
        init: function () {
            $('[href=#home-search]').click(function () {
                $('html, body').animate({
                    scrollTop: $($.attr(this, 'href')).offset().top
                }, 500);
                $('input.zipcode').focus();
            });

            $('[href=#our-promise]').click(function () {
                $('html, body').animate({
                    scrollTop: $($.attr(this, 'href')).offset().top
                }, 500);
            });
        }
    };
}());
