var TrainerSubmit = (function (){

    $questions = $('.question');
    $answers = $('.answer');
    $expand_buttons = $('.expand');
    $expand_all = $('.expand-all');
    $collapse_all = $('.collapse-all');

    function all_expanded(){
        var all_expanded = true;
        $expand_buttons.each(function(i, elem){
            if($(elem).html()=='+'){
                all_expanded = false;
                return all_expanded;
            }
        });
        return all_expanded;
    }

    function all_collapsed(){
        var all_collapsed = true;
        $expand_buttons.each(function(i, elem){
            if($(elem).html()=='-'){
                all_collapsed = false;
                return all_collapsed;
            }
        });
        return all_collapsed;
    }

    return {

        init: function(params){
            $('.btn-green').click(function(event){
               window._fbq = window._fbq || [];
               window._fbq.push(['track', '6029356635401', {'value':'0.00','currency':'USD'}]);
               return true;
            });
            $questions.click(function(){
                $(this).next('.answer').toggleClass('hidden');
                var expand = $(this).children().children();
                if (expand.html()=='+'){
                    expand.html('-')
                } else {
                    expand.html('+')
                }
                if(all_expanded()==true){
                    console.log('allexpandedtrue');
                    $expand_all.addClass('hidden');
                    $collapse_all.removeClass('hidden');
                } else if (all_collapsed()==true){
                    $collapse_all.addClass('hidden');
                    $expand_all.removeClass('hidden');
                }
            });
            $expand_all.click(function(){
                $(this).addClass('hidden');
                $collapse_all.removeClass('hidden');
                $answers.removeClass('hidden');
                $expand_buttons.html('-');
            });
            $collapse_all.click(function(){
                $(this).addClass('hidden');
                $expand_all.removeClass('hidden');
                $('.answer').addClass('hidden');
                $expand_buttons.html('+');
            })
        }
    }

})();