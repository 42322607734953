/*globals
    bootbox
*/

var trainerSignup = (function (GoogleMap, bootbox) {

    var $dom,
        createTrainingStyleUrl,
        createCalendarAppUrl,
        createLanguageSpokenUrl,
        createSpecialtyPopulationUrl,
        createMedicalConditionExperienceUrl,
        createEventPreparationSpecialtyUrl,
        $trainAtGymField,
        certifications = [],
        education = [];

    function createTrainingStyle(input, callback) {
        $.ajax(createTrainingStyleUrl, {
            type: 'post',
            dataType: 'json',
            data: {
                training_style_name: input,
            },
            success: function (response) {
                if (!response.errors) {
                    callback({
                        value: response.training_style.id,
                        text: response.training_style.name
                    });
                } else {
                    bootbox.alert("Internal Error");
                    callback();
                }
            },
            error: function () {
                bootbox.alert("Internal Error");
                callback();
            }
        });
    }

    function createCalendarApp(input, callback) {
        $.ajax(createCalendarAppUrl, {
            type: 'post',
            dataType: 'json',
            data: {
                calendar_app_name: input,
            },
            success: function (response) {
                if (!response.errors) {
                    callback({
                        value: response.calendar_app.id,
                        text: response.calendar_app.name
                    });
                } else {
                    bootbox.alert("Internal Error");
                    callback();
                }
            },
            error: function () {
                bootbox.alert("Internal Error");
                callback();
            }
        });
    }

    function createTrainingType(input, callback) {
        $.ajax(createTrainingTypeUrl, {
            type: 'post',
            dataType: 'json',
            data: {
                training_type_name: input,
            },
            success: function (response) {
                if (!response.errors) {
                    callback({
                        value: response.training_type.id,
                        text: response.training_type.name
                    });
                } else {
                    bootbox.alert("Internal Error");
                    callback();
                }
            },
            error: function () {
                bootbox.alert("Internal Error");
                callback();
            }
        });
    }

    function createLanguageSpoken(input, callback){
        $.ajax(createLanguageSpokenUrl, {
            type: 'post',
            dataType: 'json',
            data: {
                language_spoken_name: input,
            },
            success: function (response) {
                if (!response.errors) {
                    callback({
                        value: response.language_spoken.name,
                        text: response.language_spoken.name,
                    });
                } else {
                    bootbox.alert("Internal Error");
                    callback();
                }
            },
            error: function () {
                bootbox.alert("Internal Error");
                callback();
            }
        });
    }

    function createSpecialtyPopulation(input, callback){
        $.ajax(createSpecialtyPopulationUrl, {
            type: 'post',
            dataType: 'json',
            data: {
                specialty_population_name: input,
            },
            success: function (response) {
                if (!response.errors) {
                    callback({
                        value: response.specialty_population.name,
                        text: response.specialty_population.name
                    });
                } else {
                    bootbox.alert("Internal Error");
                    callback();
                }
            },
            error: function () {
                bootbox.alert("Internal Error");
                callback();
            }
        });
    }

    function createMedicalConditionExperience(input, callback){
        $.ajax(createMedicalConditionExperienceUrl, {
            type: 'post',
            dataType: 'json',
            data: {
                medical_condition_experience_name: input,
            },
            success: function (response) {
                if (!response.errors) {
                    callback({
                        value: response.medical_condition_experience.name,
                        text: response.medical_condition_experience.name
                    });
                } else {
                    bootbox.alert("Internal Error");
                    callback();
                }
            },
            error: function () {
                bootbox.alert("Internal Error");
                callback();
            }
        });
    }

    function createEventPreparationSpecialty(input, callback){
        $.ajax(createEventPreparationSpecialtyUrl, {
            type: 'post',
            dataType: 'json',
            data: {
                event_preparation_specialty_name: input,
            },
            success: function (response) {
                if (!response.errors) {
                    callback({
                        value: response.event_preparation_specialty.name,
                        text: response.event_preparation_specialty.name
                    });
                } else {
                    bootbox.alert("Internal Error");
                    callback();
                }
            },
            error: function () {
                bootbox.alert("Internal Error");
                callback();
            }
        });
    }

    // fix to be able to do selectize inputs required
    function selectizeValidationFix($select) {

        var self = $select[0].selectize;

        self.$input.on('invalid', function (event) {
            event.preventDefault();
            self.focus(true);
            self.$wrapper.addClass('invalid');
        });

        self.$input.on('change', function (event) {
            if (event.target.validity && event.target.validity.valid) {
                self.$wrapper.removeClass('invalid');
            }
        });
    }

    return {
        init: function (params) {
            $dom = $.extend({
                input: {
                    create_specialties: $('select[name=specialties-and-services-specialties]'),
                    edit_specialties: $('select[name=specialties]'),
                    training_type: $('select[name$=training_type]'),
                    services_offered: $('select[name$=services_offered]'),
                    travel_type: $('select[name$=travel_type]'),
                    training_styles: $('select[name$=training_styles]'),
                    languages_spoken: $('select[name$=languages_spoken]'),
                    specialty_populations: $('select[name$=specialty_populations]'),
                    medical_conditions_experience: $('select[name$=medical_conditions_experience]'),
                    event_preparation_specialties: $('select[name$=event_preparation_specialties]'),
                    calendar_apps: $('select[name$=calendar_apps]'),
                    education: $('select[name$=education]'),
                    certifications: $('select[name$=certifications]').not('select[name*=accredited]'),
                    accredited_certifications: $('select[name$=accredited_certifications]'),
                    original_locations: $('select[name$=original_locations]'),
                    locations_pks: $('select[name$=locations_pks]'),
                    original_chains: $('select[name$=original_chains]'),
                    chains_pks: $('select[name$=chains_pks]'),
                },
                steps_dropdown: $('.dropdown.steps-menu'),
                steps_button: $('button.steps-menu'),
            }, params.$dom);

            createTrainingStyleUrl = params.createTrainingStyleUrl;
            createCalendarAppUrl = params.createCalendarAppUrl;
            createTrainingTypeUrl = params.createTrainingTypeUrl;
            createLanguageSpokenUrl = params.createLanguageSpokenUrl;
            createSpecialtyPopulationUrl = params.createSpecialtyPopulationUrl;
            createMedicalConditionExperienceUrl = params.createMedicalConditionExperienceUrl;
            createEventPreparationSpecialtyUrl = params.createEventPreparationSpecialtyUrl;

            var mobile = function() {
                if (screen.width <= 480) {
                    // Show/Hide
                    $dom.steps_dropdown.on("show.bs.dropdown", function(event){
                        $dom.steps_button.text('X');
                    });
                    $dom.steps_dropdown.on('hide.bs.dropdown', function(event){
                        $dom.steps_button.text('>');
                    })
                }
            }

            mobile();

            if ($dom.input.create_specialties.length > 0) {
                $dom.input.create_specialties.selectize();
            }

            if ($dom.input.edit_specialties.length > 0) {
                $dom.input.edit_specialties.selectize();
            }

            if ($dom.input.training_type.length > 0) {
                $dom.input.training_type.selectize({
                    create: createTrainingType
                });
            }

            if ($dom.input.services_offered.length > 0) {
                $dom.input.services_offered.selectize();
            }


            if ($dom.input.travel_type.length > 0) {
                $dom.input.travel_type.selectize();
            }

            if ($dom.input.training_styles.length > 0) {
                $dom.input.training_styles.selectize({
                    create: createTrainingStyle
                });
            }

            if ($dom.input.calendar_apps.length > 0) {
                $dom.input.calendar_apps.selectize({
                    create: createCalendarApp
                });
            }

            if ($dom.input.education.length > 0) {
                $dom.input.education.selectize({
                    create: true,
                    delimiter: ';'
                });
            }

            if ($dom.input.accredited_certifications.length > 0) {
                $dom.input.accredited_certifications.selectize();
            }

            if ($dom.input.languages_spoken.length > 0) {
                $dom.input.languages_spoken.selectize({
                    create: createLanguageSpoken
                });
            }

            if ($dom.input.specialty_populations.length > 0) {
                $dom.input.specialty_populations.selectize({
                    create: createSpecialtyPopulation
                });
            }

            if ($dom.input.medical_conditions_experience.length > 0) {
                $dom.input.medical_conditions_experience.selectize({
                    create: createMedicalConditionExperience
                });
            }

            if ($dom.input.event_preparation_specialties.length > 0) {
                $dom.input.event_preparation_specialties.selectize({
                    create: createEventPreparationSpecialty
                });
            }

            if ($dom.input.certifications.length > 0) {
                var $certifications = $dom.input.certifications.selectize({
                    create: true,
                    delimiter: ';',
                    openOnFocus: false,
                    maxOptions: 10
                })

                // Hiding dropdown when possible
                $certifications[0].selectize.on('item_add', function() {
                    this.close();
                })
                $certifications[0].selectize.on('type', function(str) {
                    if (str === "") this.close();
                })
            }

            // Disable Enter key.
            $(':input:not(textarea)').keypress(function(e) {
                if (e.which == 13) {
                    return false;
                }
            })

            // Task 3682
            if($('input[name=details-about-you-personal_webpage]').length){
                $('input[name=details-about-you-personal_webpage]').attr('type', 'text');
                $('input[name=details-about-you-personal_webpage]').attr('pattern', "^(https?://)?([a-zA-Z0-9]([a-zA-ZäöüÄÖÜ0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$");
            }
            // Hack to remove help_text hint
            $('.help-block').text(function() {
                return $(this).text().replace('Hold down "Control", or "Command" on a Mac, to select more than one.', '')
            })
        }
    };

}(GoogleMap, bootbox));
