/*globals
    jQuery_1_11,
*/

var carousels = (function () {

    var leftButtonImageSrc,
        rightButtonImageSrc;

    function buildOptions(opts) {

    var options = {
        items:1,
        autoHeight:true,
        nav: false,
        dots: true,
		    touchDrag: true,
		    responsive: {
                0: {
                    items: 1
                }
            }
        };
        $.extend(options, opts || {});
        return options;
    }

    return {
        init: function (params) {
            $('#pics').owlCarousel(buildOptions());
        }
    };

}());
