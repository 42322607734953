var LocationModal = (function() {
  var addressData = {};
  var inputs = $("#addressModal").find(".form-control");
  var readyToSubmit = false;
  var states = [];
  var userAddress = {};
  var trainingSettings = [];

  function formatPhoneNumber(phoneNumber) {
    var newPhoneNumber = "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10);
    return newPhoneNumber;
  }

  function dataToLocalStorage() {
    if (userAddress) {
      if (userAddress.phone) {
        userAddress.phone = formatPhoneNumber(userAddress.phone);
      }
      addressData = userAddress;
      for (var i = 0; i < states.length; i++) {
        const state = states[i];
        if (parseInt(userAddress.state) === state.pk) {
          addressData.stateName = state.abbr;
        }      
      }
      localStorage.setItem("addressData", JSON.stringify(addressData));
    }
  }

  function autoCompleteModal() {
    if (JSON.parse(localStorage.getItem("addressData"))) {
      addressData = JSON.parse(localStorage.getItem("addressData"));
      addressDataKeys = Object.keys(addressData);
      for (var i=0; i<addressDataKeys.length; i++) {
        const key = addressDataKeys[i];
        const elem = $(".form-control[name='"+key+"']");
        $(elem).val(addressData[key]);
      }
    };
  }

  function getData() {
    var stateList = [];
    var address = {};
    $.ajax({
      url: "/fyt/book/modal/address/",
      async: false,
      success: function(data) {
        stateList = data.states;
        address = data.address;
        phone = data.phone;
        training_settings = data.training_setting;
      }
    })
    states = stateList;
    userAddress = address;
    userAddress.phone = phone;
    trainingSettings = training_settings;
  }

  function renderStateOptions() {
    const selectState = $(".form-control[name='state']");
    for (var i = 0; i < states.length; i++) {
      var state = states[i];
      const opt = $("<option></option>").val(state.pk).text(state.abbr);
      $(selectState).append(opt);
    }
    if (addressData.state) {
      $(selectState).append($("<option selected disabled hidden></option>").val(addressData.state).text(addressData.stateName));
    } else {
      $(selectState).append($("<option value='' selected disabled hidden>State</option>"));
    }
  }

  function renderTrainingSettingOptions() {
    const selectTrainingSetting = $(".form-control[name='training_setting']");
    for (var i = 0; i < trainingSettings.length; i++) {
      const trainingSetting = trainingSettings[i];
      const opt = $("<option></option>").val(trainingSetting[0]).text(trainingSetting[1]);
      $(selectTrainingSetting).append(opt);
    }
    if (addressData.training_setting) {
      $(selectTrainingSetting).append($("<option selected disabled hidden></option>").val(addressData.training_setting).text(addressData.training_setting));
    } else {
      $(selectTrainingSetting).append($("<option value='' selected disabled hidden>Training Setting</option>"));
    }
  }

  function validate(input) {
    const name = $(input).attr('name');
    const value = $(input).val();
    if (!value && name !== 'special_instructions') {
      event.preventDefault();
      $(input).css('border-color', 'red');
      readyToSubmit = false;
    } else {
      $(input).css('border-color', '#ccc');
      readyToSubmit = true;
    }
  }

  function buildObject() {
    for (var i=0; i<inputs.length; i++) {
      const name = $(inputs[i]).attr('name');
      const value = $(inputs[i]).val();
      addressData[name] = value;
    }
    for (var i = 0; i < states.length; i++) {
      const state = states[i];
      if (parseInt(addressData.state) === state.pk) {
        addressData.stateName = state.abbr;
      }      
    }
    localStorage.setItem("addressData", JSON.stringify(addressData));
  }

  function submitAddress(trainer_pk) {
    var dataToSubmit = {trainer_pk: trainer_pk};
    for (var i=0; i<inputs.length; i++) {
      var name = $(inputs[i]).attr('name');
      const value = $(inputs[i]).val();
      if (name === 'phone') name = 'phone_number';
      if (!value && name !== 'special_instructions') {
        event.preventDefault();
        $(inputs[i]).css('border-color', 'red');
      } else {
        dataToSubmit[name] = value;
      }
    }
    $.ajax({
      url: "/fyt/book/modal/address/",
      type: 'POST',
      data: dataToSubmit,
      success: function() {
        $("#location-modal-error").text("");
        $('#addressModal').modal('hide');
        var newLocation = $("[data-location-id=0]").find("h2");
        $(newLocation).text(addressData.street + " " + addressData.city + ", " + addressData.zip_code);
        console.log(newLocation);
      },
      error: function (error) {
        const err = error.responseJSON;
        const key = Object.keys(err)[0];
        $("#location-modal-error").text(err[key]);
      }
    })
  }

  function changePaymentLocationAddress() {
    if (addressData)
      $(".location-div .normal").text(
        addressData.street +
          " " +
          addressData.city +
          ", " +
          addressData.stateName +
          " " +
          addressData.zip_code
      );
  }

  function onSubmitAddressModal(trainer_pk) {
    $("#addressModal").find(".save-btn").click(function() {
      for (var i=0; i<inputs.length; i++) {
        const input = inputs[i];
        validate(input);
      }
      if (readyToSubmit) {
        buildObject();
        submitAddress(trainer_pk);
        changePaymentLocationAddress();
      }
    });
  }

  return {
    init: function(trainer_pk) {
      $(".form-control[name='phone']").mask("(999) 999-9999");
      getData();
      renderStateOptions();
      renderTrainingSettingOptions();
      dataToLocalStorage();
      autoCompleteModal();
      onSubmitAddressModal(trainer_pk);
    }
  }
})()